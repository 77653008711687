import React from 'react';
import styled from 'styled-components';
import VehicleTag from 'components/common/VehicleTag';
import { NEW, RETAIL_READY } from 'app-constants/reconPlanStatusTypes';
import VehicleInfo from 'components/common/VehicleInfo';
import ProfitTimeMetal from 'components/common/ProfitTimeMetal';

const VehicleTagInfoProfitContainer = ({ flags, anyNeedsApprovalTasks, vehicle, showDealerName = false }) => (
  <StyledContainer>
    <div>
      {(anyNeedsApprovalTasks ||
        vehicle?.recall?.toUpperCase() === 'OPEN' ||
        vehicle?.excludedOn ||
        vehicle.reconStatus === NEW ||
        vehicle.reconStatus === RETAIL_READY ||
        vehicle?.removedOn ||
        vehicle?.sold) && (
        <StyledTagContaier>
          <VehicleTag flags={flags} anyNeedsApprovalTasks={anyNeedsApprovalTasks} vehicle={vehicle} />
        </StyledTagContaier>
      )}
      <VehicleInfo vehicle={vehicle} showDealerName={showDealerName} />
    </div>
    <ProfitTimeMetal profitTimeScore={vehicle.profitTime} />
  </StyledContainer>
);

const StyledContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 15px;
`;

const StyledTagContaier = styled.div`
  display: flex;
  flex-flow: row;
  flex: 1 1 0px;
  align-items: center;
  padding-left: 7px;
  padding-bottom: 8px;
`;

export default VehicleTagInfoProfitContainer;
