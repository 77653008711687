import {
  faBackspace,
  faCamera,
  faCarCrash,
  faCogs,
  faCut,
  faKey,
  faPaintBrush,
  faPalette,
  faRing,
  faRunning,
  faSprayCan,
  faStore,
  faSwatchbook,
  faTasks,
  faTint,
  faWindowMaximize,
  faWrench
} from '@fortawesome/free-solid-svg-icons';

export const specialColumns = ['Vehicles Completed Recon', 'Avg Time Before Plan'];

export const taskTypeIcons = {
  Aftermarket: faStore,
  'Carpet Repair': faCut,
  Detail: faTint,
  'Ding Removal': faCarCrash,
  Inspection: faTasks,
  'Interior Repair': faSwatchbook,
  'Key Creation': faKey,
  'Lot Tech': faRunning,
  'Odor Removal': faSprayCan,
  'Paint Repair': faPaintBrush,
  Parts: faCogs,
  Photos: faCamera,
  Recall: faBackspace,
  Service: faWrench,
  'Touch Up': faPalette,
  'Wheel Repair': faRing,
  'Windshield Repair/Replace': faWindowMaximize
};

export const emDash = String.fromCharCode(8212);

export const profitTimeNonProd = 'https://ui.profittime-dev.vauto.app.coxautoinc.com/';
export const profitTimeProd = 'https://ui.profittime.vauto.app.coxautoinc.com/';

export const apiStatusConstants = {
  IS_FETCHING: 'isFetching',
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

export const getTaskStatusLabels = () => {
  return taskStatusLabels;
};

export const taggingTypes = {
  VEHICLE_NOTE: 'VehicleNote',
  TASK_MESSAGE: 'TaskMessage'
};

export const taskStatusLabels = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  DECLINED: 'Declined',
  APPROVAL_TIME: 'Approval Time'
  // CANCELED: "Canceled"           // commented out, they're not used at the moment
};

export const taskProgressLabels = {
  OnTrack: 'On Track',
  PastGoal: 'Past Goal',
  Overdue: 'Overdue',
  Declined: 'Declined',
  NeedsApproval: 'Needs Approval'
};

export const taskProgressColor = {
  'Past Goal': '#FE7520',
  Overdue: '#EB002E'
};

export const inventoryReconStatusLabels = {
  NEW: 'No Plan',
  // TRANSPORTING: "Transporting",  // Commented out because this will not be implemented at the time of GA
  IN_PROGRESS: 'In Progress',
  ON_TARGET: 'On Target',
  AT_RISK: 'At Risk',
  RETAIL_READY: 'Plan Complete',
  EXEMPT: 'Excluded'
};

export const profitTimeLabels = {
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
  PLATINUM: 'Platinum',
  NOSCORE: 'No Score'
};

export const inventorySortLabels = {
  AGE_DESC: 'Vehicle Age (oldest)',
  AGE: 'Vehicle Age (newest)',
  TIME_IN_TASK_DESC: 'Time in Task (longest)',
  TIME_IN_TASK: 'Time in Task (shortest)',
  PROFIT_TIME_DESC: 'ProfitTime Score (highest)',
  PROFIT_TIME: 'ProfitTime Score (lowest)'
};

export const inventoryStatusLabels = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
};

export const soldStatusLabels = {
  Sold: 'Sold',
  NotSold: 'Not Sold'
};

export const dispositionTypes = {
  RETAIL: 'Retail',
  WHOLESALE: 'Wholesale',
  SUBPRIME: 'Subprime'
};

export const hiddenStatusLabels = {
  IncludeHidden: 'Include Hidden'
};

export const detailContents = {
  TASK_DETAILS: 'taskDetails',
  COMMUNICATIONS: 'communications',
  RECON_PLAN: 'reconPlan',
  CREATE_RECON_PLAN: 'createReconPlan',
  VEHICLE_DETAILS: 'vehicleDetails',
  DECLINE_TASK: 'declineTask',
  COMPLETE_TASK: 'completeTask',
  EDIT_COMPLETED_TASK: 'editCompletedTask',
  VENDORS: 'vendors',
  CREATE_EDIT_VENDOR: 'createVendor',
  VIEW_VENDOR: 'viewVendor',
  INTERNAL_GROUPS: 'internalGroups',
  PLAN_TEMPLATES: 'planTemplates',
  VIEW_DEALER_DETAILS: 'viewDealerDetails'
};

export const assigneeTypes = {
  INTERNAL_USERS: 'InternalUsers',
  INTERNAL_GROUPS: 'InternalGroups',
  VENDORS: 'Vendors'
};

export const entityTypes = {
  TASK: 'TASK',
  VEHICLE: 'VEHICLE',
  LINE_ITEM: 'LINE_ITEM'
};

export const contentsSegmentTDP = ['taskDetails', 'editCompletedTask', 'declineTask', 'completeTask'];

export const formatOdometer = ({ odometer, odometerUom }) => {
  const unit = odometerUom === 'KILOMETERS' ? 'km' : 'mi';
  return typeof odometer === 'number' ? `${odometer.toLocaleString()} ${unit}` : '-';
};

export const formatVehicleTitle = (vehicle) => {
  if (!vehicle) {
    return '';
  }
  const vehicleProps = [vehicle.modelYear, vehicle.make, vehicle.model, vehicle.series];
  return vehicleProps.filter(Boolean).join(' ');
};

export const taskTypeRow = {
  TASK_TYPE_CHILD_ROW: 'taskTypeChildRow',
  CATEGORY_CHILD_ROW: 'categoryChildRow',
  DEALER_CHILD_ROW: 'dealerChildRow',
  INTERNAL_GROUP_CHILD_ROW: 'internalGroupChildRow',
  TASK_TYPE_SUMMARY_ROW: 'taskTypeSummaryRow'
};

export const arraysAreEquivalent = (arr1, arr2) => {
  // If both arrays are undefined/null/empty, mark as equal
  if ((!Array.isArray(arr1) || !arr1.length) && (!Array.isArray(arr2) || !arr2.length)) {
    return true;
  }
  // If one but not both are undefined/null/empty, mark as unequal
  if (!Array.isArray(arr1) || !arr1.length || !Array.isArray(arr2) || !arr2.length) {
    return false;
  }
  // Both are defined and non-empty, do an actual comparison
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();
  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2);
};

const hashString = (s) => {
  let h;
  for (let i = 0; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;

  return h;
};

const avatarColors = [
  '#320e3b',
  '#e56399',
  '#854d27',
  '#a6cfd5',
  '#2ec4c4',
  '#7f96ff',
  '#dd7230',
  '#f4c95d',
  '#e7e393',
  '#5fad41',
  '#3a0842',
  '#391463',
  '#bfae48'
];

const hashCache = {};

export const getAvatarColor = (userId) => {
  if (typeof userId !== 'string') {
    devLogger.log('Generating avatar color for undefined userId');
    return '#7bff3f'; //bright lime green for easy identification
  }
  if (hashCache[userId] === undefined) {
    const m = avatarColors.length;
    // % in JS is the remainder operator, rather than normal modulo. This produces the correct behavior for negative numbers.
    const index = ((hashString(userId) % m) + m) % m;
    hashCache[userId] = avatarColors[index];
  }
  return hashCache[userId];
};

export const getEmptyLineItemDescreption = (hasApproverRole) => {
  return {
    text: hasApproverRole ? 'Add line items' : 'Request line items',
    descriptions: hasApproverRole
      ? 'Add line items to send to assignees for cost estimates. All line items with a total cost will be displayed here for approval.'
      : 'Add line items with cost estimates to request dealer review.'
  };
};

export const iReconLogoutUrl = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}/logout`;
export const vAutoLogoutUrl = `${process.env.REACT_APP_PROVISION_BASE_URL}/Va/Share/LoginHandler.ashx?logout=1&returnUrl=${iReconLogoutUrl}`;
export const vAutoLogoutUrlNewDomain = `${process.env.REACT_APP_PROVISION_BASE_URL_NEW_DOMAIN}/Va/Share/LoginHandler.ashx?logout=1&returnUrl=${iReconLogoutUrl}`;
export const tempLogoutUrl = `https://signin.coxautoinc.com/logout?next=${encodeURIComponent(vAutoLogoutUrl)}`;
export const tempLogoutUrlNewDomain = `https://signin.coxautoinc.com/logout?next=${encodeURIComponent(
  vAutoLogoutUrlNewDomain
)}`;

export const CoxAutoLogoutUrl = `https://signin.coxautoinc.com/logout?bridge_solution=CAI&next=${iReconLogoutUrl}`;
export const IS_NEW_SIGNIN = 'is-new-sign-in';
export const VENDOR_USER_INFO = 'vendor-user-info';
export const TECHNICIAN = 'Technician';
export const CATEGORY = 'Category';
export const TASKTYPE = 'Task Type';
export const DEALERSHIP = 'Dealership';
export const GROUP = 'Group';
export const ASSIGNED_TO = 'Assigned To';
export const VENDOR = 'Vendor';
export const vautoDefaultEntityId = '11111111-1111-1111-1111-111111111111';
export const VENDORS = 'Vendors';
export const DEALERS = 'Dealers';
export const ROOT_ENTITY_PICKER = 'ROOT_ENTITY_PICKER';
export const MOMENT_FORMATTING = 'MM-DD-YYYY';
export const EXPORT_FAILURE_MESSAGE = 'Export failed. Please try again.';

export const WIDTH_RECON_PLAN_DRAWERS = 580;
export const WIDTH_MESSAGE_TAGGING_LIST = 580;
export const MAXIMUM_TAGGING_TEXT_COUNT = 80; //Maximum of characters count with WIDTH_MESSAGE_TAGGING_LIST = 580px
export const MINIMUM_VENDOR_TEXT_COUNT = 8; //Minimum of vendorship name's characters count

export { default as communicationContexts } from './communicationContexts';
export { default as contactInfo } from './contactInfo';
export { default as documentationContexts } from './documentationContexts';
export { default as downloadAppUrl } from './downloadAppUrl';
export { default as features } from './features';
export { default as journalEventTypes } from './journalEventTypes';
export { default as lineItemStatusTypes } from './lineItemStatusTypes';
export { default as locationActions } from './locationActions';

export const maxEstAndTotalCostReconApprovalWorkflow = 200000;
export const allUnassignedTasksLabel = 'All Unassigned Tasks';

export const dashboardPreferencesTypes = {
  INVENTORY_SNAPSHOT_FILTERS: 'inventorySnapshotFilters',
  HISTORICAL_PERFORMANCE_FILTERS: 'historicalPerformanceFilters'
};

export const statisticTagTypes = {
  ACTIVE_METRICS: 'activeMetrics',
  HISTORICAL_METRICS: 'historicalMetrics'
};

export const onboardingStatusTypes = {
  REGISTERED: 'REGISTERED',
  ADDED: 'ADDED',
  VERIFIED: 'VERIFIED'
};

export const vendorUserOnboardingStatusTypes = {
  ADDED: 'Pending',
  REGISTERED: 'Active',
  DEACTIVATED: 'Deactivated'
};

export const sortOptionDefault = {
  TASK: 'TIME_IN_TASK_DESC',
  VEHICLE: 'AGE'
};

export const RECON_ALERTS = 'reconAlerts';

export const vendorDealerStatusTypes = {
  PENDING: { Name: 'Pending', Rank: 1 },
  ACTIVE: { Name: 'Active', Rank: 2 },
  DECLINED: { Name: 'Declined', Rank: 3 }
};

export const HISTORICAL_TASK_BREAK_DOWN_HEADERS = {
  TASK_TYPE: 'taskTypeName',
  DISPLAY_TEXT: 'displayText',
  COMPLETED: 'completedTotalCount',
  REASSIGNED: 'reassignmentCount',
  ON_TRACK: 'onTrackCount',
  PAST_GOAL: 'pastGoalCount',
  OVERDUE: 'outOfThresholdCount',
  AVG_TOTAL_TIME: 'averageTotalTime',
  PREV_AVG_TIME: 'previousAverageTotalTime'
};

export const ACTIVE_INVENTORY_TASK_BREAK_DOWN_HEADERS = {
  TASK_TYPE: 'taskTypeName',
  DISPLAY_TEXT: 'displayText',
  IN_PROGRESS: 'inProgressTotal',
  ON_TRACK: 'inProgressOnTrack',
  PAST_GOAL: 'inProgressPastGoal',
  OVERDUE: 'inProgressOutOfThreshold',
  AVG_TIME_IN_TASK: 'averageTotalTime'
};

export const breakdownTypes = {
  CATEGORY: 'Category',
  DEALER: 'Dealer',
  TASK_TYPE: 'TaskType',
  USER: 'Assignee'
};

export const ewsEntityTypes = {
  CONTAINER: 'Container',
  DEALER: 'Dealer',
  WHOLESALER: 'Wholesaler',
  ENTERPRISE: 'Enterprise'
};

export const keyOrderTLPFilters = {
  'Task Status': 0,
  'Task Type': 2,
  Category: 3,
  'Task Progress': 4,
  Dealership: 5,
  Vendor: 6,
  Group: 7,
  Technician: 8,
  ProfitTime: 9,
  More: 10
};
