import { Affix, Card, List } from 'antd';
import {
  apiStatusConstants,
  dispositionTypes,
  inventoryReconStatusLabels,
  inventoryStatusLabels,
  soldStatusLabels,
  taskProgressLabels,
  sortOptionDefault,
  ewsEntityTypes,
  profitTimeLabels,
  hiddenStatusLabels
} from 'app-constants';
import { BorderlessSelect, Error } from 'components';
import { CommonListPageContainer, CommonSortRow, GridArea } from 'components/styledComponents';
import { useLocalStorage, useUpdateEffect } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { authSelector } from 'store/authStore';
import { vehiclesActions } from 'store/vehiclesStore';
import styled from 'styled-components';
import { ArrayParam, BooleanParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VehicleCard, VehicleFilters } from '..';
import { VehiclesPresentation } from './VehiclesPresentation';
import { GlobalAlertContext } from '../../../App';
import { Footer } from 'components/layout/nav';
import { analyticsTagConstants, useComponentViewedGoogleTag } from 'google-analytics';

export const createReverseLookup = (dict) =>
  Object.entries(dict).reduce((obj, r) => {
    obj[r[1]] = r[0];
    return obj;
  }, {});

export const vehiclesPropsSelector = createSelector(
  authSelector,
  (state) => state.dealers.current.data,
  (state) => state.vendors.vendor,
  (state) => state.taskTypes.fetchStatus,
  (state) => state.users.fetchStatus,
  (state) => state.taskCategories.categoriesFromTaskTypes.fetchStatus,
  (
    authStore,
    currentDealerStore,
    currentVendorStore,
    taskTypesFetchStatus,
    usersFetchStatus,
    taskCategoriesFetchStatus
  ) => ({
    userId: authStore.user.id,
    isBridgeUser: authStore.isBridgeUser,
    currentDealer: currentDealerStore,
    currentVendorId: currentVendorStore.id,
    taskTypesFetchStatus,
    usersFetchStatus,
    taskCategoriesFetchStatus
  })
);

const Vehicles = ({
  selectedVehicleId,
  scrollcontainer,
  forceLoadPageQueryFromStorage,
  setForceLoadPageQueryFromStorage,
  selectedNav,
  flags
}) => {
  const dispatch = useDispatch();
  const globalAlertWrapper = useContext(GlobalAlertContext);
  const {
    userId,
    isBridgeUser,
    currentDealer,
    currentVendorId,
    taskTypesFetchStatus,
    usersFetchStatus,
    taskCategoriesFetchStatus
  } = useSelector(vehiclesPropsSelector);

  const [dataFetched, setDataFetched] = useState(false);
  const [usersFetched, setUsersFetched] = useState(false);
  const [reconStatusReverseLookup, setReconStatusReverseLookup] = useState({});
  const [taskProgressReverseLookup, setTaskProgressReverseLookup] = useState({});
  const [inventoryStatusReverseLookup, setInventoryStatusReverseLookup] = useState({});
  const [profitTimeReverseLookup, setProfitTimeReverseLookup] = useState({});
  const [dispositionReverseLookup, setDispositionReverseLookup] = useState({});
  const [soldStatusReverseLookup, setSoldStatusReverseLookup] = useState({});
  const [hiddenStatusReverseLookup, setHiddenStatusReverseLookup] = useState({});
  const [forceLoadQueryFromStore, setForceLoadQueryFromStore] = useState(false);

  const [queryInStorage, setQueryInStorage] = useLocalStorage('vehicleQuery', {
    vehicleStatus: ['Active'],
    sort: sortOptionDefault.VEHICLE
  });

  const getCacheOrDefaultSortValue = () => {
    return queryInStorage && queryInStorage.sort && queryInStorage.sort !== ''
      ? queryInStorage.sort
      : sortOptionDefault.VEHICLE;
  };

  useComponentViewedGoogleTag(analyticsTagConstants.componentViewed.INVENTORY_LIST_VIEWED);

  const [query, setQuery] = useQueryParams({
    fromDashboard: BooleanParam,
    showDateFilter: BooleanParam,
    planStatus: withDefault(ArrayParam, []),
    completionDate: StringParam,
    completedOnStart: StringParam,
    completedOnEnd: StringParam,
    taskType: withDefault(ArrayParam, []),
    taskCategories: withDefault(ArrayParam, []),
    taskProgress: withDefault(ArrayParam, []),
    profitTime: withDefault(ArrayParam, []),
    assignedTo: withDefault(ArrayParam, []),
    recallStatus: withDefault(ArrayParam, []),
    soldStatus: withDefault(ArrayParam, []),
    vehicleStatus: withDefault(ArrayParam, []), // setting default like this doesn't work - withDefault(ArrayParam, ['Active']), the useEffect won't get triggered when you deselect, might be a bug and I posted and issue on their github
    disposition: withDefault(ArrayParam, []),
    sort: withDefault(StringParam, getCacheOrDefaultSortValue()),
    search: StringParam,
    taskId: StringParam,
    display: StringParam,
    assignedToTechnicianFilter: StringParam,
    assignedToGroupFilter: StringParam,
    assignedToVendor: withDefault(ArrayParam, []),
    entitiesFilter: withDefault(ArrayParam, []),
    hiddenStatus: withDefault(ArrayParam, [])
  });

  const [assignedToTechnician, setAssignedToTechnician] = useState([]);
  const [assignedToGroup, setAssignedToGroup] = useState([]);

  const isUnfiltered = () => {
    const {
      fromDashboard,
      planStatus,
      taskType,
      taskCategories,
      taskProgress,
      recallStatus,
      soldStatus,
      vehicleStatus,
      disposition,
      search,
      taskId,
      display,
      hiddenStatus
    } = query;
    return (
      !planStatus.length &&
      !taskType.length &&
      !taskCategories?.length &&
      !taskProgress.length &&
      !recallStatus.length &&
      !soldStatus.length &&
      !vehicleStatus.length &&
      !disposition.length &&
      !search &&
      !fromDashboard &&
      !taskId &&
      !display &&
      (!flags.reconHiddenFilter || !hiddenStatus?.length)
    );
  };

  const refetchVehicleData = (isFetchDependencyData = true) => {
    if (isUnfiltered() || forceLoadPageQueryFromStorage) {
      setForceLoadQueryFromStore(true);
    }
    dispatch(vehiclesActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
    setReconStatusReverseLookup(createReverseLookup(inventoryReconStatusLabels));
    setTaskProgressReverseLookup(createReverseLookup(taskProgressLabels));
    setProfitTimeReverseLookup(createReverseLookup(profitTimeLabels));
    setInventoryStatusReverseLookup(createReverseLookup(inventoryStatusLabels));
    setDispositionReverseLookup(createReverseLookup(dispositionTypes));
    setSoldStatusReverseLookup(createReverseLookup(soldStatusLabels));
    if (flags.reconHiddenFilter) {
      setHiddenStatusReverseLookup(createReverseLookup(hiddenStatusLabels));
    }
    if (isFetchDependencyData) {
      dispatch(vehiclesActions.getDependencyData(currentDealer?.id));
    }
    setDataFetched(true); // depending on the scenario, the taskTypesFetchStatus and usersFetchStatus may start at apiStatusConstants.SUCCEEDED, and would render the TasksPresentation before issuing these data fetches, so using this flag to allow the fetch to be called before rendering
    setForceLoadPageQueryFromStorage(false);
  };

  useUpdateEffect(() => {
    /**
     * setQueryInStorage updates the local storage object vehicleQuery with whatever the user has selected as filters
     * we know that the inventory page has been filtered by navigation from a metric on the dashboard page when the page has a parameter 'fromDashboard' with a value of 1 (true)
     * we do not want to update the vehicleQuery if the user has navigated from the dashboard to the inventory page from clicking into metrics
     * that's the reason for the !query['fromDashboard'] check
     * We only want to set query in storage if we are on the filter page of inventory tab, otherwise it could be set from another tab's query
     **/
    if (
      forceLoadPageQueryFromStorage === false &&
      selectedNav === 'inventory' &&
      window.location.pathname === '/inventory' &&
      !query['taskId']
    ) {
      if (!query['fromDashboard']) {
        setQueryInStorage(query);
      } else {
        // remember sort update even if user navigate from dashboard
        if (query.sort && query.sort !== queryInStorage.sort) {
          setQueryInStorage({ ...queryInStorage, sort: query.sort });
        }
      }
    }
  }, [JSON.stringify(query)]);

  useEffect(() => {
    if (query) {
      if (currentDealer) {
        if (currentDealer.entityType === ewsEntityTypes.DEALER || !flags.ireconHqView) {
          delete query.entitiesFilter;
        }
      }

      if (!flags.reconVendorManagement) {
        delete query.assignedToVendor;
      }

      if (!flags.reconHiddenFilter) {
        delete query.hiddenStatus;
      }
    }
    refetchVehicleData();
  }, [userId]); // when user changes (impersonating), refetch data

  //The navbar will set forceLoadPageQueryFromStorage to indicate that we need to pull the query from storage. This is needed when we navigate to the same tab we were already on,
  //since the component will not remount and set the query from storage on its own
  useEffect(() => {
    if (forceLoadPageQueryFromStorage && selectedNav === 'inventory') {
      // only reload the query
      refetchVehicleData(false);
    }
  }, [forceLoadPageQueryFromStorage]);

  useEffect(() => {
    if (isUnfiltered() && !selectedVehicleId && !forceLoadQueryFromStore) {
      setForceLoadQueryFromStore(true);
      setQuery(queryInStorage, 'replace');
    }
  }, [selectedVehicleId]);

  useEffect(() => {
    if (forceLoadQueryFromStore) {
      setForceLoadQueryFromStore(false);
    }
  }, [forceLoadQueryFromStore]);

  useUpdateEffect(() => {
    if (usersFetchStatus === apiStatusConstants.SUCCEEDED) setUsersFetched(true);
  }, [usersFetchStatus]);

  useUpdateEffect(() => {
    dispatch(vehiclesActions.getDependencyData(currentDealer?.id));
  }, [currentDealer]);

  useUpdateEffect(() => {
    dispatch(vehiclesActions.getDependencyData(currentVendorId));
  }, [currentVendorId]);

  const memoizedSetQuery = useCallback((val) => setQuery(val), []);
  const memoizedSetAssignedToTechnician = useCallback((val) => setAssignedToTechnician(val), []);
  const memoizedSetAssignedToGroup = useCallback((val) => setAssignedToGroup(val), []);
  const vehiclePresentationProps = useMemo(
    () => ({
      selectedVehicleId,
      scrollcontainer,
      query,
      setQuery: memoizedSetQuery,
      reconStatusReverseLookup,
      taskProgressReverseLookup,
      inventoryStatusReverseLookup,
      dispositionReverseLookup,
      soldStatusReverseLookup,
      profitTimeReverseLookup,
      assignedToTechnician,
      setAssignedToTechnician: memoizedSetAssignedToTechnician,
      assignedToGroup,
      setAssignedToGroup: memoizedSetAssignedToGroup,
      userId,
      forceLoadQueryFromStore: forceLoadQueryFromStore,
      hiddenStatusReverseLookup
    }),
    [query, selectedVehicleId]
  );
  const [hasNoFilters, setHasNoFilters] = useState(true);

  if (
    taskTypesFetchStatus === apiStatusConstants.SUCCEEDED &&
    usersFetched && // the users are refetched every time the RPB drawer opens, which causes the VehiclesPresentation to rendered and closes the drawer, so adding this to ignore the subsequent fetches
    dataFetched &&
    taskCategoriesFetchStatus === apiStatusConstants.SUCCEEDED
  ) {
    return <VehiclesPresentation {...vehiclePresentationProps} />;
  } else if (
    taskTypesFetchStatus === apiStatusConstants.FAILED ||
    usersFetchStatus === apiStatusConstants.FAILED ||
    taskCategoriesFetchStatus === apiStatusConstants.FAILED
  ) {
    return (
      <>
        <Error />
        {flags?.reconFooter && (
          <StyledFooterContainer>
            <Footer />
          </StyledFooterContainer>
        )}
      </>
    );
  } else {
    return (
      <CommonListPageContainer>
        <Affix offsetTop={0} target={() => scrollcontainer.current}>
          <VehicleFilters isLoading={true} setHasNoFilters={setHasNoFilters} />
        </Affix>
        {
          <CommonSortRow>
            <div>
              <span>
                Showing&nbsp;<strong>0-0&nbsp;</strong>of&nbsp;<strong>0&nbsp;</strong>vehicles
              </span>
            </div>
            <BorderlessSelect label="Sort By" loading />
          </CommonSortRow>
        }

        <StyledListContainer
          is-bridge-user={isBridgeUser}
          hasNoFilters={hasNoFilters}
          global-alert={flags?.reconAlerts && globalAlertWrapper}
          reconFooter={flags?.reconFooter}
        >
          <VehicleList
            isLoading={true}
            selectedCard={{ contentType: null }}
            items={Array.from(Array(10)).map((_, i) => ({ id: i }))}
          />
        </StyledListContainer>
      </CommonListPageContainer>
    );
  }
};

const selectVehicleIds = (state) => state.vehicles.data?.items?.map((vehicle) => vehicle.id);

const RenderedVehicleRow = React.memo(({ index, style, data }) => {
  const heightNotContainingList = CalculatedContentHeight(
    data.isBridgeUser,
    data.globalAlert,
    data.hasNoFilters,
    data.reconFooter,
    true
  );
  const remainingHeight = window.innerHeight - heightNotContainingList - data.vehicleIds?.length * style.height;
  return data.reconFooter && data.vehicleIds?.length === index ? (
    <div
      style={{
        ...style,
        bottom: 0
      }}
    >
      {data.pagination}
      <div style={{ paddingTop: remainingHeight <= style.height ? style.height - 132 : remainingHeight - 132 }}>
        <StyledFooterContainer>
          <Footer />
        </StyledFooterContainer>
      </div>
    </div>
  ) : (
    <div style={style}>
      <VehicleCard
        isLoading={data.fetchStatus !== apiStatusConstants.SUCCEEDED}
        vehicleId={data.vehicleIds[index]}
        onTemplatePlanChosen={data.onTemplatePlanChosen}
        setTemplatePlanCreatedFrom={(val) =>
          data.vehiclePresentationDispatch({ type: 'templatePlanCreatedFrom', payload: val })
        }
        setFormProps={data.setFormProps}
      />
    </div>
  );
});

export const VehicleList = ({
  vehiclePresentationDispatch,
  onTemplatePlanChosen,
  setFormProps,
  reconProfitTime,
  showDealerName,
  pagination,
  hasNoFilters,
  isBridgeUser,
  globalAlert,
  reconFooter
}) => {
  const skeletonData = Array.from(Array(10)).map((_, i) => ({ id: i }));
  const vehicleIds = useSelector(selectVehicleIds, shallowEqual);
  const fetchStatus = useSelector((state) => state.vehicles.fetchStatus);
  const heightNotContainingList = CalculatedContentHeight(isBridgeUser, globalAlert, hasNoFilters, reconFooter, true);
  const remainingHeight = window.innerHeight - heightNotContainingList;

  const renderedListItems = (vehicleId) => {
    return (
      <VehicleCard
        isLoading={fetchStatus !== apiStatusConstants.SUCCEEDED}
        vehicleId={vehicleId}
        onTemplatePlanChosen={onTemplatePlanChosen}
        setTemplatePlanCreatedFrom={(val) =>
          vehiclePresentationDispatch({ type: 'templatePlanCreatedFrom', payload: val })
        }
        setFormProps={setFormProps}
        showDealerName={showDealerName}
      />
    );
  };

  return {
    [apiStatusConstants.IS_FETCHING]: (
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            className="List"
            height={height}
            itemCount={reconFooter ? skeletonData.length + 1 : skeletonData.length}
            itemSize={172}
            width={width}
            itemData={{
              vehicleIds: skeletonData,
              fetchStatus,
              onTemplatePlanChosen,
              vehiclePresentationDispatch,
              setFormProps,
              pagination,
              reconFooter,
              hasNoFilters,
              isBridgeUser,
              globalAlert
            }}
          >
            {RenderedVehicleRow}
          </FixedSizeList>
        )}
      </AutoSizer>
    ),
    [apiStatusConstants.SUCCEEDED]:
      vehicleIds && vehicleIds.length ? (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className="List"
              height={height}
              itemCount={reconFooter ? vehicleIds.length + 1 : vehicleIds.length}
              itemSize={reconProfitTime ? (showDealerName ? 225 : 205) : showDealerName ? 192 : 172}
              // 172px due to inner component's 160px height, 10px bottom margin, 1px top and bottom borders
              // profit time widget increases the height by 33px
              // dealer name increases the height by 20px
              width={width}
              itemData={{
                vehicleIds,
                fetchStatus,
                onTemplatePlanChosen,
                vehiclePresentationDispatch,
                setFormProps,
                pagination,
                reconFooter,
                hasNoFilters,
                isBridgeUser,
                globalAlert
              }}
            >
              {RenderedVehicleRow}
            </FixedSizeList>
          )}
        </AutoSizer>
      ) : (
        <>
          <StyledList dataSource={vehicleIds} renderItem={renderedListItems} />
          {reconFooter && (
            <div style={{ paddingTop: remainingHeight - 138 }}>
              {/* 138px is the combined height of footer and empty text for No Inventory */}
              <StyledFooterContainer>
                <Footer />
              </StyledFooterContainer>
            </div>
          )}
        </>
      ),
    [apiStatusConstants.FAILED]: (
      <>
        <Error />
        <StyledFooterContainer>
          <Footer />
        </StyledFooterContainer>
      </>
    )
  }[fetchStatus];
};

//#region Styled Components
export const StyledList = styled(List).attrs(() => ({
  split: false,
  locale: { emptyText: 'No Inventory' }
}))``;
export const StyledCard = styled(Card).attrs(() => ({
  size: 'small',
  bordered: true,
  bodyStyle: { padding: '0px' }
}))`
  margin: 0 10px 0 10px;
  height: 47px;
`;
export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 47px;
  margin-right: 10px;
`;

export const CalculatedContentHeight = (isBridgeUser, hasGlobalAlert, hasNoFilters, reconFooter, valueOnly = false) => {
  let height = 338;
  if (isBridgeUser) {
    height += 35;
  }
  if (hasGlobalAlert) {
    height += 42;
  }
  if (hasNoFilters) {
    height -= 40;
  }
  if (reconFooter) {
    height -= 132;
  }
  return valueOnly ? height : `min-height: calc(100vh - ${height}px);`;
};

export const StyledListContainer = styled.div`
  ${(props) =>
    CalculatedContentHeight(
      props['is-bridge-user'],
      props['global-alert'],
      props['hasNoFilters'],
      props['reconFooter']
    )}
`;
const StyledFooterContainer = GridArea('footer', 2, 1);
//#endregion

export default withLDConsumer()(Vehicles);
