import React, { useState, useEffect, useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import { Menu, Checkbox, Radio, DatePicker, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { MultiSelect, FilterTags } from 'components';
import {
  CommonControlsContainer,
  CommonFiltersRow,
  CommonSelectorContainer,
  CommonCheckboxMenu,
  CommonGroupCheckboxMenu,
  CommonSearch
} from 'components/styledComponents';
import { stripReferences } from 'utils/arrayUtils';
import { useSetAvailableDateRange, useDebouncedEffect, useUpdateEffect, useFeatures } from 'hooks';
import {
  taskStatusLabels,
  allUnassignedTasksLabel,
  features,
  sortOptionDefault,
  TECHNICIAN,
  TASKTYPE,
  CATEGORY,
  DEALERSHIP,
  GROUP,
  VENDOR,
  ASSIGNED_TO,
  ewsEntityTypes,
  keyOrderTLPFilters
} from 'app-constants';
import { assignedToAndGroupTagConverter, groupTagRemover, assignedToTagRemover } from 'utils/filterUtils';
import useVendorRole from 'hooks/useVendorRole';
import { rootEntitySwitcherSelector } from 'store/dealersStore';
import { HQAndProfitTimeContext } from 'utils/contexts';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const MOMENT_FORMATTING = 'MM-DD-YYYY';

const taskFiltersPropsSelector = createSelector(
  (state) => state.tasks,
  (state) => state.dealers.current.data,
  (tasksStore, currentDealerStore) => ({
    tasksFetchStatus: tasksStore.fetchStatus,
    currentDealerId: currentDealerStore.id,
    currentDealer: currentDealerStore,
    dealerCreatedOn: currentDealerStore.createdOn
  })
);

const TaskFilters = ({
  isLoading = false,
  menuState = {
    'Task Status': null,
    'Task Type': null,
    Category: null,
    'Task Progress': null,
    Dealership: null,
    Vendor: null,
    Group: null,
    Technician: null,
    ProfitTime: null,
    More: null
  },
  setMenuState = () => {},
  query = {},
  setQuery = () => {},
  hasViewAnyTask = false,
  hasWorkOwnTasks = false,
  authState,
  toggleFiltersChangedByUser = () => {},
  setHasNoFilters = () => {},
  flags = {}
}) => {
  const { tasksFetchStatus, currentDealerId, currentDealer, dealerCreatedOn } = useSelector(taskFiltersPropsSelector);
  const { isRootUser, vendorShipSelected } = useSelector(rootEntitySwitcherSelector);
  const { search, showDateFilter, showApprovalTime } = query;
  const [searchBoxIsFocused, setSearchBoxIsFocused] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const availableDateRange = useSetAvailableDateRange(dealerCreatedOn, currentDealer);
  const [filterTags, setFilterTags] = useState([]);
  const [filterTagsPresentation, setFilterTagsPresentation] = useState([]); //separating updates to the filter tags from updates to the UI due to the changes in the filter tags. want to only have the updates show up once other stuff has completed
  const [removedTag, setRemovedTag] = useState([]);
  const [checkedCount, setCheckedCount] = useState({
    'Task Status': 0,
    'Task Type': 0,
    Category: 0,
    'Task Progress': 0,
    Vendor: 0,
    Group: 0,
    Technician: 0,
    ProfitTime: 0,
    Dealership: 0
  });
  const menuToQueryMap = {
    'Task Status': 'taskStatus',
    'Completion Date': 'completionDate',
    'Task Type': 'taskType',
    Category: 'taskCategories',
    'Task Progress': 'taskProgress',
    Dealership: 'entitiesFilter',
    'Assigned To': 'assignedTo',
    ProfitTime: 'profitTime',
    Disposition: 'disposition',
    'Hidden Status': 'hiddenStatus',
    assignedToTechnicianFilter: 'assignedToTechnicianFilter',
    assignedToGroupFilter: 'assignedToGroupFilter',
    // param filter of dealership
    dealerAndTaskTypeFilter: 'dealerAndTaskTypeFilter',
    reassigned: 'reassigned',
    Vendor: 'assignedToVendor'
  };
  const labelAssigneeToThemself = ' (you)';
  const labelGroupToThemself = ' (your group)';
  const [isExternalTech, isAdminGeneralRole] = useFeatures(features.EXTERNAL_TECH_ONLY, features.ADMINGENERAL);
  const isExternalTechnicianRole = isExternalTech && !isAdminGeneralRole;
  const { isVendorAdmin, isVendorTech, isVendor } = useVendorRole();
  const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);

  //On TLP, the position of Dealership filter is different for VENDOR and HQ entities
  //memoize this function to avoid sorting this array too often
  const sortedKeys = useMemo(() => {
    let updatedKeyOrderTLPFilters = { ...keyOrderTLPFilters };
    if (isVendor) {
      updatedKeyOrderTLPFilters[DEALERSHIP] = 1;
    } else {
      updatedKeyOrderTLPFilters[DEALERSHIP] = 5;
    }
    return Object.keys(menuState).sort((a, b) => updatedKeyOrderTLPFilters[a] - updatedKeyOrderTLPFilters[b]);
  }, [Object.keys(menuState).length, isVendor]);

  // Delete property base on toggles
  useEffect(() => {
    delete menuToQueryMap[TECHNICIAN];

    if (!flags.reconVendorManagement) {
      delete menuState.Vendor;
      delete menuToQueryMap.assignedToVendor;
      delete menuToQueryMap.dealerAndTaskTypeFilter;
    }
    if (!flags.ireconHqView || currentDealer.entityType === ewsEntityTypes.DEALER) {
      delete menuState.entitiesFilter;
      delete menuToQueryMap.entitiesFilter;
    }
    if (!vendorShipSelected && (!hasReconProfitTime || isVendor)) {
      delete menuState['ProfitTime'];
      delete menuToQueryMap['ProfitTime'];
    }
    if (isVendor) {
      delete menuState[CATEGORY];
      delete menuToQueryMap[CATEGORY];
    }
    if (!flags.reconHiddenFilter) {
      delete menuState.More?.['Hidden Status'];
      delete menuToQueryMap['Hidden Status'];
    }
  }, []);

  useEffect(() => {
    // sync up query search with valueSearch in TLP
    if (window.location.pathname === '/tasks' && query.search && query.search !== valueSearch) {
      setValueSearch(query.search);
    }
  }, [query.search]);

  useEffect(() => {
    if (removedTag.length) {
      var tag = removedTag.shift();
      let newMenu = stripReferences(menuState);

      const menuComponentToChange = tag.subMenu ? newMenu[tag.menu][tag.subMenu] : newMenu[tag.menu];

      if (tag.type === 'checkbox') {
        if (tag.menu === TECHNICIAN || tag.menu === GROUP || isVendorAndTagIsDealership(tag.menu)) {
          if (tag.menu === GROUP || isVendorAndTagIsDealership(tag.menu)) {
            groupTagRemover(tag, menuComponentToChange);
          } else {
            assignedToTagRemover(tag, menuComponentToChange);
          }
          if (tag.key === allUnassignedTasksLabel) {
            // Update all Unassigned member to unchecked when filter chip ALL_UNASSIGNED_TASKS removed
            if (menuComponentToChange.values?.length > 0) {
              menuComponentToChange.values.forEach((menuToChange) => {
                const hasMemberUnassignedIsChecked = menuToChange?.internalGroupUsers?.some(
                  (groupMember) => groupMember.fullName === 'Unassigned' && groupMember.isChecked
                );
                if (hasMemberUnassignedIsChecked) {
                  const groupMembers = menuToChange?.internalGroupUsers?.map((groupMember) => {
                    if (groupMember.fullName === 'Unassigned') {
                      groupMember.isChecked = false;
                    }
                    return groupMember;
                  });
                  menuToChange.internalGroupUsers = groupMembers;

                  const isAllMemberUnchecked = menuToChange.internalGroupUsers.every(
                    (groupMember) => !groupMember.isChecked
                  );
                  if (isAllMemberUnchecked) {
                    menuToChange.isChecked = false;
                    menuToChange.isShowMinusOutlinedIcon = false;
                  }
                }
              });
            }
          }
        } else {
          menuComponentToChange.values[tag.key] = false;
        }
      } else if (tag.type === 'radio') menuComponentToChange.values = null;
      else if (tag.type === 'datepicker') {
        const menuCompletionDate = newMenu[tag.menu]['Completion Date'];
        menuCompletionDate.values = null;
        menuComponentToChange.values = {};
      } else if (tag.type === 'search') {
        let newFilterTags = stripReferences(filterTags);
        newFilterTags = newFilterTags.filter((tag) => tag.type !== 'search');
        setFilterTags(newFilterTags);
        setValueSearch('');
        setQuery({ search: undefined }, 'pushIn');
      } else if (tag.type === 'reassigned') {
        let newFilterTags = stripReferences(filterTags);
        newFilterTags = newFilterTags.filter((tag) => tag.type !== 'reassigned');
        setFilterTags(newFilterTags);
        setQuery({ reassigned: undefined }, 'pushIn');
      }
      setMenuState(newMenu);
      toggleFiltersChangedByUser(true);
    }
  }, [removedTag]);

  useUpdateEffect(() => {
    if (!isLoading) {
      onMenuChange();
    }
  }, [currentDealerId, JSON.stringify(menuState)]);

  useDebouncedEffect(
    () => {
      if (tasksFetchStatus === 'succeeded') {
        //because there's a lot that goes on when changing filters on the page, there is a potential for filter tag controls to show up misplaced so only showing the tag controls until after page changes have returned data
        setFilterTagsPresentation(filterTags); //filterTagsPresentation is what is actually rendered, but tracking of the current filters is done in filterTags
        setHasNoFilters(!filterTags?.length);
      }
    },
    500,
    [tasksFetchStatus, filterTags]
  );

  const isVendorAndTagIsDealership = (toCheck) => {
    return toCheck === DEALERSHIP && isVendor;
  };

  const onMenuChange = () => {
    if (menuState['Task Status'] !== null) {
      let newMenu = stripReferences(menuState);
      if (showApprovalTime) {
        if (newMenu['Task Status']['Task Status'].values[taskStatusLabels.APPROVAL_TIME] === undefined) {
          newMenu['Task Status']['Task Status'].values[taskStatusLabels.APPROVAL_TIME] = true;

          if (newMenu.hasOwnProperty(GROUP) && newMenu.hasOwnProperty(TECHNICIAN)) {
            newMenu[GROUP].values.forEach((assigned) => (assigned.isChecked = false));
            newMenu[TECHNICIAN].values.forEach((assigned) => (assigned.isChecked = false));
          }
        }
      } else {
        delete newMenu['Task Status']['Task Status'].values[taskStatusLabels.APPROVAL_TIME];
      }

      if (showDateFilter) {
        newMenu['Task Status']['Completion Date'].visible = true;
        const selectedTaskStatuses = Object.entries(newMenu['Task Status']['Task Status'].values)
          .filter((x) => x[1])
          .map((x) => x[0]);

        const disableCompletionDateOptions = !selectedTaskStatuses.includes(taskStatusLabels.COMPLETED);

        newMenu['Task Status']['Completion Date'].disabled = newMenu['Task Status'].datepicker.disabled =
          disableCompletionDateOptions;
        disableCompletionDateOptions && (newMenu['Task Status']['Completion Date'].values = null);
        newMenu['Task Status'].datepicker.visible =
          !disableCompletionDateOptions && newMenu['Task Status']['Completion Date'].values === 'Custom Range';
      }

      Object.keys(newMenu).forEach((key) => updateCountsAndQueryStrings(key, newMenu));
      //This will only evaluate to true the first time this component is rendered, when filterTags will have a its length of 0
      let newTags = Object.keys(newMenu)
        .map((key) => getFilterTags(key, newMenu))
        .flat();
      //This will set a tag for searching when reload page.
      if (query.search && valueSearch !== '') {
        let tagSearch = newTags.find((tag) => tag.type === 'search');
        if (tagSearch) {
          tagSearch.label = `Search: ${query.search}`;
        } else {
          tagSearch = {
            key: 'search',
            label: `Search: ${query.search}`,
            menu: null,
            subMenu: null,
            type: 'search',
            id: null
          };
          newTags.push(tagSearch);
        }
      }
      //This will set a tag for Reassigned when reload page.
      if (query.reassigned) {
        let tagReassigned = newTags.find((tag) => tag.type === 'reassigned');
        tagReassigned = {
          key: 'reassigned',
          label: `Reassigned`,
          menu: null,
          subMenu: null,
          type: 'reassigned',
          id: null
        };
        newTags.push(tagReassigned);
      }
      setMenuState(newMenu);
      setFilterTags(newTags);
    }
  };

  const getCount = (menu, menuState) => {
    if (!menuState[menu]) return 0;

    if (menu === TECHNICIAN || menu === GROUP || isVendorAndTagIsDealership(menu)) {
      let assignedToTechnicians = menuState[menu].values?.filter((f) => f.isChecked);
      return assignedToTechnicians.length ?? 0;
    }

    return Object.entries(menuState[menu]).reduce((count, menuItem) => {
      if (typeof menuItem[1] === 'object' && !menuItem[1].disabled && menuItem[0] !== 'datepicker') {
        let obj = menuItem[1].values || (menuItem[0] === 'values' ? menuItem[1] : {});
        count += typeof obj === 'object' ? Object.values(obj).filter((x) => x).length : 1;
      }
      return count;
    }, 0);
  };

  // menu => Menu key
  const getFilterTags = (menu, currentMenuState) => {
    let tags = [];
    if (!currentMenuState[menu]) return tags;
    let isCustomRangeCompletionDate = false;
    Object.entries(currentMenuState[menu]).forEach((menuItem) => {
      if (typeof menuItem[1] !== 'object' || menuItem[1].disabled) return;

      let obj = menuItem[1].values || (menuItem[0] === 'values' ? menuItem[1] : null);
      if (!obj) return;
      let filterType = menuItem[1].type || currentMenuState[menu].type;
      if (filterType === 'checkbox') {
        if (menu === TECHNICIAN || menu === GROUP || isVendorAndTagIsDealership(menu)) {
          let values = menuItem[1];
          if (values?.length > 0) {
            assignedToAndGroupTagConverter(tags, menu, values, flags);
          }
        } else {
          let filters = Object.entries(obj).filter((x) => x[1]);
          tags.push(
            filters.map((x) => {
              return {
                menu,
                subMenu: menuItem[0] === 'values' ? null : menuItem[0],
                key: x[0],
                type: 'checkbox',
                label: `${menuItem[0] === 'values' ? menu : menuItem[0]}: ${x[0]}`,
                id: null
              };
            })
          );
        }
      } else if (filterType === 'radio') {
        if (menuItem[0] === 'Completion Date' && obj === 'Custom Range') isCustomRangeCompletionDate = true;
        if (!(menuItem[0] === 'Completion Date' && obj === 'Custom Range')) {
          tags.push({
            menu,
            subMenu: menuItem[0],
            key: 'values',
            type: 'radio',
            label: `${menuItem[0] === 'values' ? menu : menuItem[0]}: ${obj}`,
            id: null
          });
        }
      } else if (filterType === 'datepicker') {
        if (!obj.startDate || !obj.endDate || !isCustomRangeCompletionDate) return;
        tags.push({
          menu,
          subMenu: menuItem[0],
          key: 'datepicker',
          type: 'datepicker',
          label: `Completion Date: ${obj.startDate} - ${obj.endDate}`,
          id: null
        });
      }
    });

    return tags.flat();
  };

  const removeFilterTag = (tag) => {
    setRemovedTag((removedTag) => [...removedTag, tag]);
  };

  const updateCountsAndQueryStrings = (updatedMenuKey, updatedMenuVal) => {
    setCheckedCount((prev) => ({
      ...prev,
      [updatedMenuKey]: getCount(updatedMenuKey, updatedMenuVal)
    }));

    let queryValues;
    switch (updatedMenuKey) {
      case 'Task Status':
      case 'More':
        const newQuery = {};
        Object.entries(updatedMenuVal[updatedMenuKey])
          .filter((x) => !['type', 'datepicker'].includes(x[0]))
          .forEach((subMenu) => {
            if (subMenu[1].disabled || subMenu[1].values === null) {
              const queryKey = menuToQueryMap[subMenu[0]];
              queryKey && (newQuery[queryKey] = []);

              if (subMenu[0] === 'Completion Date') {
                queryKey && (newQuery[queryKey] = undefined);
                newQuery.completedOnStart = undefined;
                newQuery.completedOnEnd = undefined;
              }
              return;
            }

            // Handle completion date range
            if (subMenu[0] === 'Completion Date') {
              let startDate,
                endDate = moment().format(MOMENT_FORMATTING);
              if (subMenu[1].values !== 'Custom Range') {
                const daysAgo = Number(subMenu[1].values.match(/\d+/g));
                // Subtract 1 from daysAgo for startDate because we are counting each day in the range as a whole day (inclusive)
                startDate = moment(endDate, MOMENT_FORMATTING)
                  .subtract(daysAgo - 1, 'days')
                  .format(MOMENT_FORMATTING);
              } else {
                startDate = updatedMenuVal[updatedMenuKey].datepicker.values.startDate;
                endDate = updatedMenuVal[updatedMenuKey].datepicker.values.endDate;
              }
              startDate && (newQuery.completedOnStart = startDate);
              endDate && (newQuery.completedOnEnd = endDate);
              const queryKey = menuToQueryMap[subMenu[0]];
              queryKey && (newQuery[queryKey] = subMenu[1].values);
            } else {
              queryValues = Object.entries(subMenu[1].values || {})
                .filter((x) => x[1])
                .map((x) => x[0]);
              const queryKey = menuToQueryMap[subMenu[0]];
              queryKey && (newQuery[queryKey] = queryValues);
            }
          });
        setQuery(newQuery, 'pushIn');
        break;
      case CATEGORY:
      case 'Task Type':
      case 'Task Progress':
      case DEALERSHIP:
      case 'ProfitTime':
      case VENDOR:
      case TECHNICIAN:
        if (isVendorAndTagIsDealership(updatedMenuKey)) {
          let newDealerShips = updatedMenuVal[updatedMenuKey].values;
          if (newDealerShips?.length > 0) {
            queryValues = newDealerShips.filter((f) => f.isChecked);
            let assignedToDealerAndTaskTypes = queryValues.map((dealer) => {
              return {
                dealerId: dealer.id,
                taskTypes: dealer.taskTypes
                  ?.filter((taskType) => taskType.isChecked && taskType.id !== null)
                  .map((type) => type.id)
              };
            });
            setQuery(
              { [menuToQueryMap['dealerAndTaskTypeFilter']]: JSON.stringify(assignedToDealerAndTaskTypes) },
              'pushIn'
            );
          }
        } else if (updatedMenuKey === TECHNICIAN) {
          queryValues = updatedMenuVal[updatedMenuKey].values?.filter((f) => f.isChecked);
          let assignedToTechnicians = queryValues.map((member) => {
            return {
              assignedTo: member.id,
              assignedDirectly: member.isAssignedDirectly,
              assignedThruGroup: member.isAssignedThruGroup
            };
          });
          setQuery({ [menuToQueryMap['assignedToTechnicianFilter']]: JSON.stringify(assignedToTechnicians) }, 'pushIn');
        } else {
          queryValues = Object.entries(updatedMenuVal[updatedMenuKey].values)
            .filter((x) => x[1])
            .map((x) => x[0]);
          setQuery({ [menuToQueryMap[updatedMenuKey]]: queryValues }, 'pushIn');
        }

        break;
      case GROUP:
        queryValues = updatedMenuVal[updatedMenuKey].values?.filter((f) => f.isChecked);
        let assignedToGroups = queryValues.map((group) => {
          let indexUnassigned = group.internalGroupUsers?.findIndex((member) => member.userId === null);
          return {
            assignedToGroup: group.id,
            assignedToMembers: group.internalGroupUsers
              ?.filter((member) => member.isChecked && member.userId !== null)
              .map((member) => member.userId),
            unassigned: indexUnassigned !== -1 ? group.internalGroupUsers[indexUnassigned].isChecked : false
          };
        });
        setQuery({ [menuToQueryMap['assignedToGroupFilter']]: JSON.stringify(assignedToGroups) }, 'pushIn');
        break;
      default:
        break;
    }
  };

  const onCheckboxClick = (menu, subMenu) => (e) => {
    let newMenu = stripReferences(menuState);
    if (menu === TECHNICIAN || menu === GROUP || isVendorAndTagIsDealership(menu)) {
      let newDataWithMenuTypes = stripReferences(newMenu[menu]?.values);
      let indexMenu = newDataWithMenuTypes.findIndex((f) => f.fullName === e.target.fullName && f.id === e.target.id);
      if (indexMenu !== -1) {
        newDataWithMenuTypes[indexMenu].isChecked = e.target.checked;
        if (menu === TECHNICIAN) {
          if (isVendor && flags?.reconVendorManagement) {
            newDataWithMenuTypes[indexMenu].isAssignedDirectly = e.target.checked;
          } else {
            newDataWithMenuTypes[indexMenu].isAssignedDirectly = e.target.checked;
            if (newDataWithMenuTypes[indexMenu].isMemberOfAnyGroup) {
              newDataWithMenuTypes[indexMenu].isAssignedThruGroup = e.target.checked;
            }
            if (
              !newDataWithMenuTypes[indexMenu].isAssignedThruGroup ||
              !newDataWithMenuTypes[indexMenu].isAssignedDirectly
            ) {
              newDataWithMenuTypes[indexMenu].isShowMinusOutlinedIcon = true;
            } else {
              newDataWithMenuTypes[indexMenu].isShowMinusOutlinedIcon = false;
            }
          }
          newMenu[menu].values = newDataWithMenuTypes;
        }
        if (menu === GROUP) {
          // set isChecked for all user in internal group
          if (newDataWithMenuTypes[indexMenu].internalGroupUsers?.length > 0) {
            newDataWithMenuTypes[indexMenu].internalGroupUsers.forEach((member) => {
              member.isChecked = e.target.checked;
            });

            /*
             * when a any group member change status to unchecked. we will update status for group name to new icon.
             */
            let listGroupMemberNotSelected = newDataWithMenuTypes[indexMenu].internalGroupUsers.filter(
              (i) => !i.isChecked
            );
            if (listGroupMemberNotSelected?.length > 0) {
              newDataWithMenuTypes[indexMenu].isShowMinusOutlinedIcon = true;
            } else {
              newDataWithMenuTypes[indexMenu].isShowMinusOutlinedIcon = false;
            }
          }
          // Select option 'All Unassigned Tasks'
          if (newDataWithMenuTypes[indexMenu].fullName === allUnassignedTasksLabel && newDataWithMenuTypes.length > 0) {
            newDataWithMenuTypes.forEach((newMenuWithType) => {
              // Checked/Unchecked all member Unassigned of groups
              if (newMenuWithType.internalGroupUsers?.length > 0) {
                let indexMemberUnassigned = newMenuWithType.internalGroupUsers.findIndex(
                  (f) => f.fullName === 'Unassigned'
                );
                if (indexMemberUnassigned !== -1) {
                  newMenuWithType.internalGroupUsers[indexMemberUnassigned].isChecked = e.target.checked;
                  /*
                   * when a any group member change status to unchecked. we will update status for group name to new icon.
                   */
                  let listGroupMemberSelected = newMenuWithType.internalGroupUsers.filter((i) => i.isChecked);
                  if (listGroupMemberSelected?.length > 0) {
                    newMenuWithType.isChecked = true;
                    newMenuWithType.isShowMinusOutlinedIcon =
                      listGroupMemberSelected.length !== newMenuWithType.internalGroupUsers.length;
                  } else {
                    newMenuWithType.isChecked = false;
                    newMenuWithType.isShowMinusOutlinedIcon = false;
                  }
                }
              }
            });
          } else {
            /*
             * If any group has member Unassigned is uncheck, the All Unassigned Tasks should be update to Minus Icon
             */
            updateManualGroupCheckboxState(newDataWithMenuTypes);
          }
          newMenu[menu].values = newDataWithMenuTypes;
        }
        if (isVendorAndTagIsDealership(menu)) {
          // set isChecked for all taskTypes
          if (newDataWithMenuTypes[indexMenu].taskTypes?.length > 0) {
            newDataWithMenuTypes[indexMenu].taskTypes.forEach((taskType) => {
              taskType.isChecked = e.target.checked;
            });

            /*
             * when a any task type change status to unchecked. we will update status for task type to new icon.
             */
            let listTaskTypesNotSelected = newDataWithMenuTypes[indexMenu].taskTypes.filter((i) => !i.isChecked);
            if (listTaskTypesNotSelected?.length > 0) {
              newDataWithMenuTypes[indexMenu].isShowMinusOutlinedIcon = true;
            } else {
              newDataWithMenuTypes[indexMenu].isShowMinusOutlinedIcon = false;
            }
            newMenu[menu].values = newDataWithMenuTypes;
          }
        }
      }
    } else {
      if (subMenu) {
        newMenu[menu][subMenu].values[e.target.id] = e.target.checked;
      } else {
        newMenu[menu].values[e.target.id] = e.target.checked;
      }
    }

    setMenuState(newMenu);
    toggleFiltersChangedByUser(true);
  };

  const updateManualGroupCheckboxState = (newDataWithMenuTypes) => {
    let groupHasMemberUnassignedIsUnChecked = newDataWithMenuTypes.find((menuWithType) =>
      menuWithType.internalGroupUsers.some(
        (groupMember) => groupMember.fullName === 'Unassigned' && !groupMember.isChecked
      )
    );

    let groupHasMemberUnassignedIsChecked = newDataWithMenuTypes.find((menuWithType) =>
      menuWithType.internalGroupUsers.some(
        (groupMember) => groupMember.fullName === 'Unassigned' && groupMember.isChecked
      )
    );
    let indexMenuAllUnassignedTasks = newDataWithMenuTypes.findIndex((f) => f.fullName === allUnassignedTasksLabel);
    if (indexMenuAllUnassignedTasks !== -1) {
      if (groupHasMemberUnassignedIsUnChecked !== undefined) {
        if (groupHasMemberUnassignedIsChecked !== undefined) {
          newDataWithMenuTypes[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = true;
          newDataWithMenuTypes[indexMenuAllUnassignedTasks].isChecked = true;
        } else {
          newDataWithMenuTypes[indexMenuAllUnassignedTasks].isChecked = false;
          newDataWithMenuTypes[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = false;
        }
      } else {
        newDataWithMenuTypes[indexMenuAllUnassignedTasks].isChecked = true;
      }
    }
  };

  const onRadioButtonClick = (menu, subMenu) => (e) => {
    let newMenu = stripReferences(menuState);
    if (subMenu) {
      newMenu[menu][subMenu].values = e.target.value;
    } else {
      newMenu[menu].values = e.target.value;
    }
    setMenuState(newMenu);
    toggleFiltersChangedByUser(true);
  };
  const onChangeRangePicker = (momentsArray) => {
    let newMenu = stripReferences(menuState);

    if (momentsArray.length === 0) {
      newMenu['Task Status']['Completion Date'].values = null;
      newMenu['Task Status'].datepicker.values = null;
    } else {
      newMenu['Task Status'].datepicker.values = {
        startDate: momentsArray[0].format(MOMENT_FORMATTING),
        endDate: momentsArray[1].format(MOMENT_FORMATTING)
      };
    }
    setMenuState(newMenu);
    toggleFiltersChangedByUser(true);
  };

  const onSearch = (value) => {
    setQuery({ search: value }, 'pushIn'); // value in URL
    setValueSearch(value); // value in search input
    let newFilterTags = stripReferences(filterTags);
    let tagSearch = newFilterTags.find((tag) => tag.type === 'search');
    if (value.trim() !== '') {
      if (tagSearch) {
        tagSearch.label = `Search: ${value}`;
      } else {
        tagSearch = {
          key: 'search',
          label: `Search: ${value}`,
          menu: null,
          subMenu: null,
          type: 'search',
          id: null
        };
        newFilterTags.push(tagSearch);
      }
    } else {
      if (tagSearch) {
        newFilterTags = newFilterTags.filter((ele) => ele.type !== 'search');
      }
    }
    // set new Tags
    setFilterTags(newFilterTags);
  };

  const onCheckboxSubMenuClick = (menu) => (e) => {
    let newMenu = stripReferences(menuState);
    let newMenuWithType = newMenu[menu].values;
    let indexMenuWithType = newMenuWithType.findIndex((f) => f.fullName === e.target.id);
    if (menu === TECHNICIAN) {
      if (newMenuWithType[indexMenuWithType].isMemberOfAnyGroup) {
        if (e.target.subMenu === 'Assigned Directly') {
          newMenuWithType[indexMenuWithType].isAssignedDirectly = e.target.checked;
        }
        if (e.target.subMenu === 'Assigned Thru Group') {
          newMenuWithType[indexMenuWithType].isAssignedThruGroup = e.target.checked;
        }
        /*
         * when Assgined Directly, Assigned Thru Group are unchecked
         */
        if (
          !newMenuWithType[indexMenuWithType].isAssignedDirectly &&
          !newMenuWithType[indexMenuWithType].isAssignedThruGroup
        ) {
          newMenuWithType[indexMenuWithType].isChecked = e.target.checked;
        }
        /*
         * when Assgined Directly is checked or Assigned Thru Group is checked
         */
        if (
          (newMenuWithType[indexMenuWithType].isAssignedDirectly ||
            newMenuWithType[indexMenuWithType].isAssignedThruGroup) &&
          !newMenuWithType[indexMenuWithType].isChecked
        ) {
          newMenuWithType[indexMenuWithType].isChecked = !newMenuWithType[indexMenuWithType].isChecked;
        }
        /*
         * when Assgined Directly is unchecked and Assigned Thru Group is checked.
         * when Assgined Directly is checked and Assigned Thru Group is unchecked.
         */
        if (
          (!newMenuWithType[indexMenuWithType].isAssignedDirectly &&
            newMenuWithType[indexMenuWithType].isAssignedThruGroup) ||
          (newMenuWithType[indexMenuWithType].isAssignedDirectly &&
            !newMenuWithType[indexMenuWithType].isAssignedThruGroup)
        ) {
          newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = true;
        }
        /*
         * when Assgined Directly is checked or Assigned Thru Group is checked
         */
        if (
          newMenuWithType[indexMenuWithType].isAssignedDirectly &&
          newMenuWithType[indexMenuWithType].isAssignedThruGroup
        ) {
          newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = false;
        }
      }
    }
    if (menu === GROUP) {
      let indexInternalGroupUser = newMenuWithType[indexMenuWithType].internalGroupUsers.findIndex(
        (f) => f.fullName === e.target.subMenu
      );
      newMenuWithType[indexMenuWithType].internalGroupUsers[indexInternalGroupUser].isChecked = e.target.checked;
      /*
       * when Group member are unchecked or checker. we will update status for group name.
       */
      if (
        (newMenuWithType[indexMenuWithType].internalGroupUsers.some((member) => member.isChecked) &&
          !newMenuWithType[indexMenuWithType].isChecked) ||
        !newMenuWithType[indexMenuWithType].internalGroupUsers.some((member) => member.isChecked)
      ) {
        newMenuWithType[indexMenuWithType].isChecked = !newMenuWithType[indexMenuWithType].isChecked;
      }
      /*
       * when a any group member change status to unchecked. we will update status for group name to new icon.
       */
      let listGroupMemberNotSelected = newMenuWithType[indexMenuWithType].internalGroupUsers.filter(
        (i) => !i.isChecked
      );
      if (listGroupMemberNotSelected?.length > 0) {
        newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = true;
      } else {
        newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = false;
      }
      /*
       * If any group has member Unassigned is uncheck, the All Unassigned Tasks should be update to Minus Icon
       */
      updateManualGroupCheckboxState(newMenuWithType);
    }
    if (isVendorAndTagIsDealership(menu)) {
      let indexTaskType = newMenuWithType[indexMenuWithType].taskTypes?.findIndex(
        (f) => f.fullName === e.target.subMenu
      );
      newMenuWithType[indexMenuWithType].taskTypes[indexTaskType].isChecked = e.target.checked;
      /*
       * when task type are unchecked or checker. we will update status for task type.
       */
      if (
        (newMenuWithType[indexMenuWithType].taskTypes?.some((taskType) => taskType.isChecked) &&
          !newMenuWithType[indexMenuWithType].isChecked) ||
        !newMenuWithType[indexMenuWithType].taskTypes?.some((taskType) => taskType.isChecked)
      ) {
        newMenuWithType[indexMenuWithType].isChecked = !newMenuWithType[indexMenuWithType].isChecked;
      }
      /*
       * when a any task type change status to unchecked. we will update status for task type to new icon.
       */
      let listTaskTypesNotSelected = newMenuWithType[indexMenuWithType].taskTypes.filter((i) => !i.isChecked);
      if (listTaskTypesNotSelected?.length > 0) {
        newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = true;
      } else {
        newMenuWithType[indexMenuWithType].isShowMinusOutlinedIcon = false;
      }
    }
    setMenuState(newMenu);
    toggleFiltersChangedByUser(true);
  };

  const onIconShowSubMenuClick =
    (menu, fullName, id = '') =>
    (e) => {
      let newMenu = stripReferences(menuState);
      let newMenuWithType = newMenu[menu].values;
      let indexMenuWithType = newMenuWithType.findIndex((f) => {
        if (!!id) {
          return f.id === id;
        } else {
          return f.fullName === fullName;
        }
      });
      if (menu === TECHNICIAN) {
        newMenuWithType[indexMenuWithType].isShowSubMenu = !newMenuWithType[indexMenuWithType].isShowSubMenu;
      }
      if (menu === GROUP || isVendorAndTagIsDealership(menu)) {
        newMenuWithType[indexMenuWithType].isShowSubMenu = !newMenuWithType[indexMenuWithType].isShowSubMenu;
      }
      setMenuState(newMenu);
    };

  const createMenu = (menuKey, parentMenuKey, maxHeight = null) => {
    const menu = parentMenuKey ? menuState[parentMenuKey][menuKey] : menuState[menuKey];

    if (menu.type === 'group') {
      return (
        <CommonGroupCheckboxMenu data-max-height={maxHeight}>
          {Object.entries(menu)
            .filter((m) => !m[1].hasOwnProperty('visible') || m[1].visible)
            .filter((m) => !['type', 'disabled'].includes(m[0]))
            .map((m) => createMenu(m[0], menuKey))}
        </CommonGroupCheckboxMenu>
      );
    }

    const disabled = menu.disabled || false;

    if (menu.type === 'checkbox') {
      const onChange = onCheckboxClick(parentMenuKey || menuKey, parentMenuKey ? menuKey : null);

      if (menuKey === TECHNICIAN || menuKey === GROUP || isVendorAndTagIsDealership(menuKey)) {
        const onChangeSubMenu = onCheckboxSubMenuClick(menuKey);
        if (menuKey === TECHNICIAN) {
          return (
            <CommonCheckboxSubMenu>
              {menu?.values.length > 0 ? (
                menu?.values.map((value, index) =>
                  value.isMemberOfAnyGroup ? (
                    <Menu.ItemGroup key={`group-${menuKey}-${value.id}`} className="submenu-technician">
                      <ParentCheckboxMenuOverride dropdownIsOpen={value.isShowSubMenu}>
                        <Checkbox
                          className={value.isShowMinusOutlinedIcon ? 'group-submenu-main' : ''}
                          id={value.id}
                          fullName={value.fullName}
                          onChange={onChange}
                          disabled={disabled}
                          checked={value.isChecked}
                        >
                          {getFullName(value.id, value.fullName)}
                        </Checkbox>
                        <FontAwesomeIcon
                          id={value.fullName}
                          icon={value.isShowSubMenu ? faChevronUp : faChevronDown}
                          onClick={onIconShowSubMenuClick(menuKey, value.fullName, value.id)}
                        />
                      </ParentCheckboxMenuOverride>
                      <Menu.Item
                        className={value.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                        key={menuKey + value.fullName + value.id + 'Assigned Directly'}
                        style={NestedCheckboxOverride}
                      >
                        <Checkbox
                          id={value.fullName}
                          subMenu="Assigned Directly"
                          checked={value.isAssignedDirectly}
                          onChange={onChangeSubMenu}
                        >
                          Assigned Directly
                        </Checkbox>
                      </Menu.Item>
                      <Menu.Item
                        className={value.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                        key={menuKey + value.fullName + value.id + 'Assigned Thru Group'}
                        style={NestedCheckboxOverride}
                      >
                        <Checkbox
                          id={value.fullName}
                          subMenu="Assigned Thru Group"
                          checked={value.isAssignedThruGroup}
                          onChange={onChangeSubMenu}
                        >
                          Assigned Thru Group
                        </Checkbox>
                      </Menu.Item>
                    </Menu.ItemGroup>
                  ) : (
                    <Menu.Item key={`menu-item-${value.id}`}>
                      <Checkbox
                        id={value.id}
                        fullName={value.fullName}
                        disabled={disabled}
                        checked={value.isChecked}
                        onChange={onChange}
                        style={OptionsStyle}
                      >
                        {getFullName(value.id, value.fullName)}
                      </Checkbox>
                    </Menu.Item>
                  )
                )
              ) : (
                <div style={{ CommonCheckBoxSubMenuText }} className="ant-list ant-list-empty-text">
                  No Technician
                </div>
              )}
            </CommonCheckboxSubMenu>
          );
        }
        if (menuKey === GROUP) {
          return (
            <CommonCheckboxSubMenu>
              {menu?.values?.length > 0 &&
                menu?.values?.map((group, index) =>
                  group.isYourGroup || hasViewAnyTask ? (
                    <Menu.ItemGroup key={`list-${menuKey}-${group.id}`} className="submenu-technician">
                      <ParentCheckboxMenuOverride>
                        <Checkbox
                          id={group.id}
                          fullName={group.fullName}
                          onChange={onChange}
                          disabled={disabled}
                          checked={group.isChecked}
                          className={group.isShowMinusOutlinedIcon ? 'group-submenu-main' : ''}
                        >
                          {group.isYourGroup ? group.fullName + labelGroupToThemself : group.fullName}
                        </Checkbox>
                        {group?.internalGroupUsers?.length > 0 && (
                          <FontAwesomeIcon
                            id={group.fullName}
                            icon={group.isShowSubMenu ? faChevronUp : faChevronDown}
                            onClick={onIconShowSubMenuClick(menuKey, group.fullName)}
                          />
                        )}
                      </ParentCheckboxMenuOverride>
                      {group?.internalGroupUsers?.length > 0 &&
                        group?.internalGroupUsers?.map((member, idx) => (
                          <Menu.Item
                            className={group.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                            key={group.id + member.userId + 'Group'}
                            style={NestedCheckboxOverride}
                          >
                            <Checkbox
                              id={group.fullName}
                              subMenu={member.fullName}
                              checked={member.isChecked}
                              onChange={onChangeSubMenu}
                            >
                              {member.fullName}
                            </Checkbox>
                          </Menu.Item>
                        ))}
                    </Menu.ItemGroup>
                  ) : (
                    <></>
                  )
                )}
            </CommonCheckboxSubMenu>
          );
        }
        if (isVendorAndTagIsDealership(menuKey) && flags?.reconVendorManagement) {
          return (
            <CommonCheckboxSubMenu>
              {menu?.values.length > 0 &&
                menu?.values.map((dealer, index) => (
                  <Menu.ItemGroup key={`list-${menuKey}-${dealer.id}`} className="submenu-technician">
                    <ParentCheckboxMenuOverride>
                      <Checkbox
                        id={dealer.id}
                        fullName={dealer.fullName}
                        onChange={onChange}
                        disabled={disabled}
                        checked={dealer.isChecked}
                        className={dealer.isShowMinusOutlinedIcon ? 'group-submenu-main' : ''}
                      >
                        {dealer.fullName}
                      </Checkbox>
                      {dealer?.taskTypes?.length > 0 && (
                        <FontAwesomeIcon
                          id={dealer.fullName}
                          icon={dealer.isShowSubMenu ? faChevronUp : faChevronDown}
                          onClick={onIconShowSubMenuClick(menuKey, dealer.fullName)}
                        />
                      )}
                    </ParentCheckboxMenuOverride>
                    {dealer?.taskTypes?.length > 0 &&
                      dealer?.taskTypes?.map((taskType, idx) => (
                        <Menu.Item
                          className={dealer.isShowSubMenu ? '' : 'submenu-technician-hidden'}
                          key={taskType.fullName + dealer.id + taskType.id}
                          style={NestedCheckboxOverride}
                        >
                          <Checkbox
                            id={dealer.fullName}
                            subMenu={taskType.fullName}
                            checked={taskType.isChecked}
                            onChange={onChangeSubMenu}
                          >
                            {taskType.fullName}
                          </Checkbox>
                        </Menu.Item>
                      ))}
                  </Menu.ItemGroup>
                ))}
            </CommonCheckboxSubMenu>
          );
        }
      } else {
        if (
          (isVendor &&
            (menuKey === 'Task Status' ||
              menuKey === 'Task Progress' ||
              (flags.reconHiddenFilter && menuKey === 'Hidden Status'))) ||
          !(isVendor && !vendorShipSelected)
        ) {
          let renderedMenu = { ...menu.values };

          const checkboxes = Object.entries(renderedMenu).map((i) => (
            <Menu.Item key={i[0]} disabled={disabled}>
              <Checkbox id={i[0]} checked={i[1]} onChange={onChange} disabled={disabled} style={OptionsStyle}>
                {i[0] === 'Needs Approval' && flags.reconApproveButton ? 'Pending Approval' : i[0]}
              </Checkbox>
            </Menu.Item>
          ));

          return parentMenuKey ? (
            <StyledMenuItemGroupOverride key={`group-${menuKey}`} title={menuKey}>
              {checkboxes}
            </StyledMenuItemGroupOverride>
          ) : (
            <CommonCheckboxMenu data-max-height={maxHeight}>{checkboxes}</CommonCheckboxMenu>
          );
        }
      }
    }

    if (menu.type === 'radio') {
      return (
        <Menu.ItemGroup key={`group-${menuKey}`} title={menuKey}>
          <Radio.Group
            key={`radiogroup-${menuKey}`}
            onChange={onRadioButtonClick(parentMenuKey, menuKey)}
            value={menu.values}
            disabled={disabled}
            style={{ width: '100%' }}
          >
            {menu.options.map((o) => (
              <Radio key={o} value={o}>
                {o}
              </Radio>
            ))}
          </Radio.Group>
        </Menu.ItemGroup>
      );
    }

    if (menu.type === 'datepicker' && menu.visible) {
      let value = [null, null];
      if (menu.values?.startDate && menu.values?.endDate) {
        value = [moment(menu.values.startDate, MOMENT_FORMATTING), moment(menu.values.endDate, MOMENT_FORMATTING)];
      }
      return (
        <StyledRangePicker
          key={`group-${menuKey}`}
          placeholder="Select start date"
          disabled={disabled}
          // Why this `>= availableDateRange[1].startOf('day')` when we don't have to do that in Filter.js? It appears that antd has a bug with checking for disabled dates when you provide the `value` prop
          disabledDate={(current) =>
            current && (current < availableDateRange[0] || current >= availableDateRange[1].startOf('day'))
          }
          onChange={onChangeRangePicker}
          dropdownClassName="dashboardRangePicker"
          bordered={false}
          separator="-"
          format="MM-DD-YYYY"
          mode={['date']}
          value={value}
        />
      );
    }
  };

  const getDefaultFilterTaskListPage = () => {
    let defaultFilterAssignedTo = [];
    let defaultFilterGroup = [];
    let newMenuState = stripReferences(menuState);
    if (newMenuState[TECHNICIAN]?.values?.length > 0) {
      if (isRootUser && vendorShipSelected) {
        defaultFilterAssignedTo = [{ assignedTo: null, assignedDirectly: true, assignedThruGroup: false }];
      } else {
        defaultFilterAssignedTo = newMenuState[TECHNICIAN]?.values
          ?.filter((f) => f.id === authState.user.id)
          .map((member) => {
            return {
              assignedTo: member.id,
              assignedDirectly: true,
              assignedThruGroup: !isVendor
            };
          });
      }
    }
    if (!isVendorAdmin && newMenuState[GROUP]?.values?.length > 0) {
      let internalGroupsAssignToMe = newMenuState[GROUP]?.values?.filter((group) => {
        let indexGroupMember = group.internalGroupUsers
          ? group.internalGroupUsers.findIndex((member) => member.userId === authState.user.id)
          : -1;
        return indexGroupMember !== -1;
      });
      if (internalGroupsAssignToMe?.length > 0) {
        defaultFilterGroup = internalGroupsAssignToMe.map((m) => {
          return {
            assignedToGroup: m.id,
            assignedToMembers: m.internalGroupUsers
              ?.filter((member) => member.userId !== null)
              .map((member) => member.userId),
            unassigned: true
          };
        });
      }
    }
    if (isVendor) {
      let resetLink = `/tasks?taskStatus=In Progress&sort=${sortOptionDefault.TASK}`;
      if (isVendorAdmin && defaultFilterAssignedTo?.length > 0) {
        defaultFilterAssignedTo.push({ assignedTo: null, assignedDirectly: true, assignedThruGroup: false });
        return resetLink + `&assignedToTechnicianFilter=${JSON.stringify(defaultFilterAssignedTo)}`;
      }
      if (isVendorTech) {
        defaultFilterAssignedTo.push({
          assignedTo: authState.user.id,
          assignedDirectly: true,
          assignedThruGroup: false
        });
        return resetLink + `&assignedToTechnicianFilter=${JSON.stringify(defaultFilterAssignedTo)}`;
      }
    } else {
      if (defaultFilterGroup?.length > 0 && defaultFilterAssignedTo?.length > 0) {
        let resetLink = `/tasks?taskStatus=In Progress&sort=${sortOptionDefault.TASK}`;

        if (hasWorkOwnTasks) {
          return (
            resetLink +
            `&assignedToGroupFilter=${JSON.stringify(defaultFilterGroup)}&assignedToTechnicianFilter=${JSON.stringify(
              defaultFilterAssignedTo
            )}`
          );
        } else {
          return resetLink;
        }
      }
    }
  };

  const updateManualMenuState = (key, tag, menuState) => {
    if (key && tag && key === tag.menu) {
      if (menuState[key][tag.menu]?.values) {
        if (menuState[key][tag.menu].values[tag.key]) {
          menuState[key][tag.menu].values[tag.key] = false;
        }
      } else if (menuState[tag.menu]?.values) {
        menuState[key].values[tag.key] = false;
      } else if (menuState[tag.menu][tag.subMenu].values) {
        menuState[tag.menu][tag.subMenu].values[tag.key] = false;
      }
    }
  };

  const removedAllTag = () => {
    let newMenuState = stripReferences(menuState);
    if (newMenuState[TECHNICIAN]?.values?.length > 0) {
      newMenuState[TECHNICIAN].values.forEach((user) => {
        if (
          user.id === authState.user.id ||
          (user.id === null && user.fullName === allUnassignedTasksLabel && isVendor)
        ) {
          user.isAssignedDirectly = true;
          user.isAssignedThruGroup = user.isMemberOfAnyGroup;
          user.isChecked = true;
        } else {
          user.isAssignedDirectly = false;
          user.isAssignedThruGroup = false;
          user.isChecked = false;
        }
      });
    }
    if (newMenuState[GROUP]?.values?.length > 0) {
      newMenuState[GROUP].values.forEach((group) => {
        let indexGroupMember = group.internalGroupUsers?.findIndex((member) => member.userId === authState.user.id);
        if (indexGroupMember === -1) {
          group.isChecked = false;
          group.isShowMinusOutlinedIcon = false;
          group.isShowSubMenu = false;
          group.internalGroupUsers.map((member) => {
            member.isChecked = false;
            return member;
          });
          return group;
        }
        group.isChecked = true;
        group.isShowMinusOutlinedIcon = false;
        group.isShowSubMenu = false;
        group.internalGroupUsers.map((member) => {
          member.isChecked = true;
          return member;
        });
      });
    }

    if (isVendor && flags?.reconVendorManagement && newMenuState[DEALERSHIP]?.values?.length > 0) {
      newMenuState[DEALERSHIP].values.forEach((dealer) => {
        dealer.isChecked = false;
        dealer.isShowMinusOutlinedIcon = false;
        dealer.isShowSubMenu = false;
        dealer.taskTypes.map((type) => {
          type.isChecked = false;
          return type;
        });
      });
    }
    let oldFilterTags = filterTags.filter((tag) => tag.menu !== GROUP && tag.menu !== TECHNICIAN);
    if (oldFilterTags && oldFilterTags.length > 0) {
      let indexVehicleActive = oldFilterTags.findIndex((f) => f.menu === 'More' && f.key === 'Active');
      if (indexVehicleActive !== -1) {
        oldFilterTags.splice(indexVehicleActive, 1);
      }
      oldFilterTags.forEach((tag) => {
        Object.keys(newMenuState).forEach((key) => updateManualMenuState(key, tag, newMenuState));
      });
    }

    let newTags = Object.keys(menuState)
      .map((key) => getFilterTags(key, newMenuState))
      .flat();
    // add default filter tag
    newTags.push({
      id: null,
      key: 'In Progress',
      label: 'Task Status: In Progress',
      menu: 'Task Status',
      subMenu: 'Task Status',
      type: 'checkbox'
    });
    setFilterTags(newTags);
    // add default menu
    newMenuState['Task Status']['Task Status'].values[taskStatusLabels.IN_PROGRESS] = true;
    setMenuState(newMenuState);
  };

  const getLabelName = (menuName) => {
    if (menuName === TECHNICIAN && !flags.reconVendorManagement) {
      return ASSIGNED_TO;
    }
    return menuName;
  };

  const checkHQView = (menuName) => {
    return isHQ && (menuName === TASKTYPE || menuName === TECHNICIAN);
  };

  const showLoadingFilter = (menuName) => {
    // I need check off toogle.
    // Because Button Group will displayed.
    if (
      isVendor ||
      (isExternalTechnicianRole && (menuName === GROUP || menuName === VENDOR)) ||
      (!flags.reconVendorManagement && menuName === VENDOR) ||
      // menu named `Dealership` (above) is for vendors to filter by their associated dealerships
      // menu named `Dealership` is for HQs to filter by their child rooftops
      // TODO : iRecon  web's implicit dependency on filter labels is bound to cause issues at some point
      //  We should look into loosening this coupling at some point
      (menuName === DEALERSHIP &&
        !isVendor &&
        (currentDealer?.entityType === ewsEntityTypes.DEALER || !flags.ireconHqView)) ||
      (menuName === 'ProfitTime' && !hasReconProfitTime) ||
      checkHQView(menuName)
    ) {
      return <></>;
    }
    return <MultiSelect label={getLabelName(menuName)} menu={<CommonCheckboxMenu />} loading marginLeft={8} />;
  };

  const getFullName = (userId, fullName) => {
    if (userId && fullName && authState.user.id === userId) {
      return fullName + labelAssigneeToThemself;
    }
    return fullName;
  };

  const getLabelCount = (menuName) => {
    if (menuName) {
      let count = checkedCount[menuName];
      if (count > 0) {
        return ` (${count})`;
      }
      return '';
    }
  };
  return (
    <StyledCommonControlsContainer>
      <CommonFiltersRow>
        <Text className="label">Filters</Text>
        {sortedKeys.map((menuName) => (
          <CommonSelectorContainer key={`menu-${menuName}`}>
            {isLoading ? (
              showLoadingFilter(menuName)
            ) : checkHQView(menuName) ? (
              <></>
            ) : (
              ((menuName !== TECHNICIAN && menuName !== VENDOR) || hasViewAnyTask) && (
                <MultiSelect
                  label={`${getLabelName(menuName)}${getLabelCount(menuName)}`}
                  menu={createMenu(menuName)}
                  marginLeft={8}
                />
              )
            )}
          </CommonSelectorContainer>
        ))}

        <StyledSearchContainer>
          {isLoading ? (
            <CommonSearch placeholder="Search" prefix={<FontAwesomeIcon icon={faSearch} />} disabled />
          ) : (
            <CommonSearch
              data-is-focused={searchBoxIsFocused}
              placeholder={searchBoxIsFocused ? 'Type to begin searching...' : 'Search'}
              prefix={<FontAwesomeIcon icon={faSearch} />}
              onFocus={(e) => setSearchBoxIsFocused(true)}
              onBlur={(e) => setSearchBoxIsFocused(false)}
              defaultValue={search}
              onChange={(e) => setValueSearch(e.target?.value)}
              value={valueSearch}
              onSearch={(e) => {
                onSearch(e);
                toggleFiltersChangedByUser(true);
              }}
            />
          )}
        </StyledSearchContainer>
      </CommonFiltersRow>

      <FilterTags
        filterTags={filterTagsPresentation}
        removeFilterTag={removeFilterTag}
        hasWorkOwnTasks={hasWorkOwnTasks}
        authState={authState}
        resetLink={getDefaultFilterTaskListPage()}
        toggleFiltersChangedByUser={toggleFiltersChangedByUser}
        setValueSearch={onSearch}
        removedAllTag={removedAllTag}
        flags={flags}
      />
    </StyledCommonControlsContainer>
  );
};

//#region Styled Components
const StyledCommonControlsContainer = styled(CommonControlsContainer)`
  .ant-affix & {
    border-bottom: ${({ theme }) => `1px solid ${theme.borderColors.gray}`};
  }
`;

const StyledSearchContainer = styled.div`
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const StyledRangePicker = styled(RangePicker)`
  &.ant-calendar-picker {
    color: ${({ theme }) => theme.colors.navy};
    width: 220px;
  }

  .ant-calendar-range-picker-input {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.gray : theme.colors.red)};
    font-weight: ${({ disabled, theme }) => (disabled ? 'initial' : theme.fontWeights.bold)};
  }

  .ant-calendar-picker-input {
    border: none;
    &.ant-input.ant-input-disabled {
      background-color: initial;
    }
  }

  .anticon-close-circle,
  .ant-calendar-range-picker-input::placeholder,
  .ant-calendar-range-picker-separator {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.gray : theme.colors.red)};
  }
`;
const StyledDropdownSubMenuItem = css`
  .submenu-technician {
    .ant-dropdown-menu-item-group-list {
      margin: 0px;
      .ant-menu-vertical {
        border-right: none;
        i {
          font-size: 12px;
          margin-right: 8px;
        }
      }
      .submenu-technician-hidden {
        display: none;
      }
      .group-submenu-main {
        .ant-checkbox-checked {
          .ant-checkbox-inner::after {
            border-left: 2px solid #fff;
            border-right: 0;
            border-bottom: 0;
            transform: rotate(90deg) scale(1) translate(-50%, -10%);
          }
        }
      }
      .fa-chevron-down {
        margin-right: 8px;
        height: 12px;
        width: 12px;
        :hover {
          cursor: pointer;
        }
      }
      .fa-chevron-up {
        margin-right: 8px;
        height: 12px;
        width: 12px;
        :hover {
          cursor: pointer;
        }
      }
      .ant-dropdown-menu-item {
        label {
          padding: 0px;
          font-size: ${({ theme }) => theme.fontSizes.sm};
        }
      }
    }
    .ant-dropdown-menu-item-group-title {
      display: none;
    }
  }
`;

const CommonCheckboxSubMenu = styled(CommonCheckboxMenu)`
  ${StyledDropdownSubMenuItem}
`;

const ParentCheckboxMenuOverride = styled(Menu)`
  display: flex;
  align-items: center;
  padding: 0;
  height: 32px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: none;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      top: 50%;
      left: 50%;
      width: 8px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.white};
      border: 0;
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
      content: ' ';
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.colors.red};
      border-radius: 4px;
      border: unset;
    }
    .ant-checkbox-inner {
      border-radius: 4px;
    }
    .ant-checkbox-checked::after {
      border: unset;
    }
  }
  .ant-menu& .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-menu& .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.colors.borderGray};
  }
`;

const StyledMenuItemGroupOverride = styled(Menu.ItemGroup)`
  div.ant-dropdown-menu-item-group-title {
    font-size: ${({ theme }) => theme.fontSizes.sm} !important;
  }
`;

const NestedCheckboxOverride = {
  padding: '0px 0px 0px 38px'
};

const OptionsStyle = {
  fontSize: '14px'
};

const CommonCheckBoxSubMenuText = {
  padding: '8px',
  fontSize: '14px'
};
//#endregion

export default withLDConsumer()(TaskFilters);
