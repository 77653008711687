import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useContext } from 'react';
import { enterpriseMetricsActions } from '../../../../../store/enterpriseMetricsStore';
import { createSelector } from 'reselect';
import { SummaryHeader, SummaryTable } from '.';
import { generateDealershipsLink, getNeedAttentionLinks } from '../../commonFunctions';
import { useNavigate } from '@reach/router';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { useDebouncedEffect } from 'hooks';

const summaryTablePropsSelector = createSelector(
  (state) => state?.enterpriseMetrics?.needsAttention,
  (state) => state.dealers.current.data,
  (enterpriseMetrics, currentEntity) => {
    return {
      fetchStatus: enterpriseMetrics.fetchStatus,
      dealershipLevelCounts: enterpriseMetrics.data.dealershipLevelCounts,
      aggregateCounts: enterpriseMetrics.data.aggregateCounts,
      currentEntity: currentEntity
    };
  }
);

const SummaryBody = ({ flags }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [links, setLinks] = useState({});
  const { fetchStatus, dealershipLevelCounts, aggregateCounts, currentEntity } = useSelector(summaryTablePropsSelector);
  const [sort, setSort] = useState({ order: 'ascend', field: 'dealerName' });
  const { items } = useCommonDropdownContext('dealerships');
  const { isHQ } = useContext(HQAndProfitTimeContext);
  const mappedDealershipFilters = (items ?? []).filter((item) => item.checked).map((item) => item.name);
  const noDealershipFiltersSelectedHQ = flags.reconDealerFilter && isHQ && mappedDealershipFilters.length === 0;

  const fetchData = (dealerships) => {
    const dealershipIds =
      flags?.reconDealerFilter && isHQ
        ? (dealerships ?? [])
            .filter((item) => item.checked)
            .map((item) => item.key)
            .join(',')
        : undefined;
    dispatch(
      enterpriseMetricsActions.getEnterpriseNeedsAttentionMetrics(currentEntity.id, {
        dealershipIds
      })
    );
  };

  useEffect(() => {
    setLinks(getNeedAttentionLinks(currentEntity));
  }, [currentEntity?.id]);

  useEffect(() => {
    fetchData(items);
  }, []);

  useDebouncedEffect(
    () => {
      fetchData(items);
    },
    600,
    [items]
  );

  //Collect data
  const tableData = dealershipLevelCounts;
  const headerData = aggregateCounts;

  const onMetricClick = (metric, record = null) => {
    const url =
      links[metric] +
      (record
        ? `&entitiesFilter=${record.dealerName}`
        : generateDealershipsLink((items ?? []).filter((item) => item.checked).map((item) => item.name)));
    navigate(url);
  };

  const onChange = (...params) => {
    const sortHeader = params[2];
    if (sortHeader && sortHeader.field) {
      setSort({ order: sortHeader.order, field: sortHeader.field });
    }
  };

  return (
    <>
      <SummaryHeader
        data={headerData}
        fetchStatus={fetchStatus}
        onMetricClick={onMetricClick}
        currentEntity={currentEntity}
        sort={sort}
        noDealershipFiltersSelectedHQ={noDealershipFiltersSelectedHQ}
      />
      <SummaryTable
        data={tableData}
        fetchStatus={fetchStatus}
        refreshAction={fetchData}
        onMetricClick={onMetricClick}
        onChange={onChange}
        sort={sort}
      />
    </>
  );
};

export default withLDConsumer()(SummaryBody);
