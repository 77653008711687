import React from 'react';
import { ReconTag, reconTagTypes } from 'components/tags';
import { NEW, RETAIL_READY } from 'app-constants/reconPlanStatusTypes';

const VehicleTag = ({ flags, anyNeedsApprovalTasks, vehicle }) => (
  <>
    {anyNeedsApprovalTasks && (
      <ReconTag type={flags.reconApproveButton ? reconTagTypes.PENDING_APPROVAL : reconTagTypes.NEEDS_APPROVAL} />
    )}
    {vehicle.recall?.toUpperCase() === 'OPEN' && <ReconTag type={reconTagTypes.RECALL} />}
    {vehicle?.excludedOn ? (
      <ReconTag type={reconTagTypes.EXCLUDED} vehicle={vehicle} />
    ) : (
      vehicle.reconStatus === NEW && <ReconTag type={reconTagTypes.NO_PLAN} />
    )}
    {vehicle.reconStatus === RETAIL_READY ? <ReconTag type={reconTagTypes.PLAN_COMPLETE} /> : null}
    {vehicle?.removedOn && <ReconTag type={reconTagTypes.ARCHIVED} vehicle={vehicle} />}
    {vehicle?.sold && <ReconTag type={reconTagTypes.SOLD} vehicle={vehicle} />}
  </>
);

export default VehicleTag;
