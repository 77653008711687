import moment from 'moment';
import { difference } from 'utils/arrayUtils';
import { generateDateRangeFromDaysAgo } from 'utils/dateTimeUtils';
//app constants
import { MOMENT_FORMATTING, specialColumns } from 'app-constants';

// Convert excluded task category IDs included task categories for ILP/TLP links.
const getTaskCategoriesParamsFromExcludedIds = (excludedTaskCategoryIds, taskCategories) => {
  // Nothing is excluded, which is the same as not filtering by any task categories.
  if (!excludedTaskCategoryIds?.length) {
    return [];
  }

  // At least one task category is being excluded, create the list of included task categories for the ILP/TLP link.
  const allTaskCategoryIds = Object.keys(taskCategories).map((id) => Number(id));
  const includedTaskCategoryIds = difference(allTaskCategoryIds, excludedTaskCategoryIds);
  const includedTaskCategories = includedTaskCategoryIds.map((id) => taskCategories[id]);
  const includedTaskCategoriesParams =
    includedTaskCategories.length > 0
      ? includedTaskCategories.map((includedTaskCategory) => `taskCategories=${includedTaskCategory}`)
      : []; // We can only hit this case if we are excluding all task categories, which shouldn't be possible anyway.

  return includedTaskCategoriesParams;
};

const getStatisticLink = (
  isComparativeStat = false,
  title = '',
  metricsType = '',
  filters = null,
  extraFilters = [],
  disableLinkForNonSpecialColumns = false,
  noDealershipFiltersSelectedHQ = false,
  isPrev = false,
  reconHiddenFilter = false
) => {
  if (
    (metricsType === 'historicalMetrics' && filters === null) ||
    (disableLinkForNonSpecialColumns && !specialColumns.includes(title)) ||
    noDealershipFiltersSelectedHQ
  ) {
    return null;
  }

  let dispositionParam = '';
  if (filters !== null && filters.dispositions?.length > 0) {
    const dispo = filters.dispositions.charAt(0).toUpperCase() + filters.dispositions.slice(1).toLowerCase();
    dispositionParam = `disposition=${dispo}`;
  }

  let dateParameters = '';
  if (isComparativeStat) {
    // Since these date ranges cover 24-hour periods, we don't want the comparative date range to overlap with the current one
    let endTime = filters?.startTime
      ? moment(filters.startTime, MOMENT_FORMATTING).subtract(1, 'days')
      : moment().subtract(30, 'days');
    let dateRange = generateDateRangeFromDaysAgo(filters ? filters.daysAgo : '30', endTime);
    dateParameters = `completionDate=Custom Range&completedOnStart=${dateRange.startTime}&completedOnEnd=${dateRange.endTime}`;
  } else if (filters !== null) {
    let completionDate = 'Custom Range';
    if (
      [7, 30, 90].indexOf(parseInt(filters.daysAgo)) !== -1 &&
      filters.endTime === moment().format(MOMENT_FORMATTING) &&
      !isPrev
    ) {
      completionDate = `Last ${filters.daysAgo} days`;
    }

    let objDateFilter = getStartEndTimeFilter(filters.startTime, filters.endTime, filters.daysAgo, isPrev);
    dateParameters = `completionDate=${completionDate}&completedOnStart=${objDateFilter.startDate}&completedOnEnd=${objDateFilter.endDate}`;
  } else {
    let dateRange = generateDateRangeFromDaysAgo('30');
    let objDateFilter = getStartEndTimeFilter(dateRange.startTime, dateRange.endTime, 30, isPrev);
    dateParameters = `completionDate=${objDateFilter.completionDate}&completedOnStart=${objDateFilter.startDate}&completedOnEnd=${objDateFilter.endDate}`;
  }

  const hiddenStatusParam = reconHiddenFilter ? `hiddenStatus=${encodeURIComponent('Include Hidden')}` : '';
  let queryStrings = [
    'fromDashboard=1',
    'showDateFilter=1',
    dateParameters,
    title === 'Avg Task Approval Time'
      ? '&taskStatus=Approval Time&taskStatus=Completed&showApprovalTime=1'
      : '&planStatus=Plan Complete',
    dispositionParam,
    hiddenStatusParam
  ];

  queryStrings = queryStrings
    .concat(specialColumns.includes(title) ? extraFilters.filter((x) => !x.includes('taskCategories')) : extraFilters)
    .filter((x) => x);

  return title === 'Avg Task Approval Time'
    ? `/tasks?${queryStrings.join('&')}`
    : `/inventory?${queryStrings.join('&')}`;
};

const getStartEndTimeFilter = (start, end, daysAgo, isPrev) => {
  let startDate = start;
  let endDate = end;
  let completionDate = `Last ${daysAgo} days`;
  if (isPrev) {
    startDate = moment(start, MOMENT_FORMATTING).subtract(daysAgo, 'days').format(MOMENT_FORMATTING);
    endDate = moment(endDate, MOMENT_FORMATTING).subtract(daysAgo, 'days').format(MOMENT_FORMATTING);
    completionDate = 'Custom Range';
  }
  return { startDate, endDate, completionDate };
};

const insertDispositions = (vehicleDispositions) => {
  const vehicleDispositionList = vehicleDispositions
    ?.map((disposition) => {
      return `disposition=${disposition.charAt(0) + disposition.slice(1).toLowerCase()}`;
    })
    .join('&');

  return vehicleDispositionList + '&';
};

const generateDealershipsLink = (dealerships) => {
  let dealershipFilterLink = '';
  if (dealerships && dealerships.length > 0) {
    dealerships.forEach((dealership) => (dealershipFilterLink += `&entitiesFilter=${encodeURIComponent(dealership)}`));
  }
  return dealershipFilterLink;
};

const getNeedAttentionLinks = (currentDealer, dealerships = null, noDealershipFiltersSelectedHQ = false) => {
  if (noDealershipFiltersSelectedHQ) {
    return {
      'No Plan': null,
      'Pending Approval': null,
      'Declined Tasks': null,
      Overdue: null,
      Recall: null,
      Sold: null
    };
  }
  const dealershipFilterLink = generateDealershipsLink(dealerships);
  return {
    'No Plan': 'inventory?vehicleStatus=Active&planStatus=No Plan&fromDashboard=1' + dealershipFilterLink,
    'Pending Approval':
      'inventory?vehicleStatus=Active&taskProgress=Needs Approval&fromDashboard=1' + dealershipFilterLink,
    'Declined Tasks': 'inventory?vehicleStatus=Active&taskProgress=Declined&fromDashboard=1' + dealershipFilterLink,
    Overdue:
      'inventory?vehicleStatus=Active&planStatus=In Progress&taskProgress=Overdue&fromDashboard=1' +
      dealershipFilterLink,
    Recall: currentDealer?.vehicleDispositionFilter
      ? `inventory?${insertDispositions(
          currentDealer?.vehicleDispositionFilter
        )}vehicleStatus=Active&recallStatus=Open&fromDashboard=1` + dealershipFilterLink
      : `inventory?vehicleStatus=Active&recallStatus=Open&fromDashboard=1` + dealershipFilterLink,
    Sold: 'inventory?vehicleStatus=Active&soldStatus=Sold&fromDashboard=1' + dealershipFilterLink
  };
};

export { getTaskCategoriesParamsFromExcludedIds, getStatisticLink, getNeedAttentionLinks, generateDealershipsLink };
