import {
  dispositionTypes,
  inventoryReconStatusLabels,
  profitTimeLabels,
  inventoryStatusLabels,
  taskProgressLabels,
  allUnassignedTasksLabel,
  VENDOR,
  GROUP,
  CATEGORY,
  DEALERSHIP,
  hiddenStatusLabels
} from 'app-constants';
import { useDebouncedEffect } from 'hooks';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { stripReferences } from 'utils/arrayUtils';
import { HQAndProfitTimeContext } from 'utils/contexts';

export const useVehicleMenu = (query, flags) => {
  const {
    planStatus,
    completionDate,
    completedOnStart,
    completedOnEnd,
    taskType,
    taskCategories,
    taskProgress,
    assignedTo,
    recallStatus,
    soldStatus,
    vehicleStatus,
    profitTime,
    sort,
    disposition,
    search,
    fromDashboard,
    reconStatusReverseLookup,
    taskProgressReverseLookup,
    inventoryStatusReverseLookup,
    dispositionReverseLookup,
    soldStatusReverseLookup,
    profitTimeReverseLookup,
    hiddenStatus,
    hiddenStatusReverseLookup,
    dealerDispositions,
    taskTypesData,
    users,
    selectedVehicleId,
    isNotification,
    assigneeData,
    assignedToTechnicianFilter,
    entitiesFilter,
    assignedToVendor,
    assignedToGroupFilter,
    userId,
    forceLoadQueryFromStore,
    taskCategoriesData
  } = query;
  const { hasReconProfitTime } = useContext(HQAndProfitTimeContext);

  const getDealerDispositions = () => {
    return dealerDispositions.length > 0
      ? dealerDispositions.map((d) => dispositionTypes[d])
      : Object.values(dispositionTypes);
  };

  const getInternalUsers = () => {
    let newInternlUsers = stripReferences(assigneeData?.internalUsers ?? []);
    let internalUsers = [];

    if (newInternlUsers && newInternlUsers.length > 0) {
      newInternlUsers.forEach((item) => {
        let obj = {};
        obj['id'] = item.id;
        obj['fullName'] = item.fullName;
        obj['isChecked'] = userId === item.id;
        obj['isMemberOfAnyGroup'] = item.isMemberOfAnyGroup;
        obj['isAssignedDirectly'] = userId === item.id;
        obj['isAssignedThruGroup'] = userId === item.id;
        obj['isShowSubMenu'] = false;
        obj['isShowMinusOutlinedIcon'] = false;
        internalUsers.push(obj);
      });
      //isChecked is checking if the internalUsers list contains the current logged in user.
      //If that not the case, indexTopCurrentUser is -1 and splice(-1,1) will remove the last element in the array
      //This is not desirable and leads to the defect.
      let topCurrentUser = internalUsers.find((f) => f.isChecked);
      let indexTopCurrentUser = internalUsers.findIndex((f) => f.id === userId);
      if (indexTopCurrentUser > -1) {
        internalUsers.splice(indexTopCurrentUser, 1);
        if (topCurrentUser) {
          internalUsers.unshift(topCurrentUser);
        }
      }
    }
    return internalUsers;
  };

  const getInternalGroups = () => {
    let newInternalGroups = stripReferences(assigneeData?.internalGroups ?? []);
    let internalGroups = [];
    if (newInternalGroups && newInternalGroups.length > 0) {
      newInternalGroups.forEach((group) => {
        let obj = {};
        obj['id'] = group.id;
        let indexGroupMemberChecked = group.internalGroupUsers.findIndex((f) => f.userId === userId);
        let groupMembers = group.internalGroupUsers.map((user) => {
          let idxRawUser = assigneeData?.internalUsers?.findIndex((f) => f.id === user.userId);
          if (idxRawUser !== -1 && user.fullName !== assigneeData?.internalUsers[idxRawUser].fullName) {
            user.fullName = assigneeData?.internalUsers[idxRawUser].fullName;
          }
          user['isChecked'] = indexGroupMemberChecked !== -1;
          if (user.fullName === 'Unassigned') {
            user.userId = null;
          }
          return user;
        });
        obj['isChecked'] = indexGroupMemberChecked !== -1;
        obj['fullName'] = group.name;
        obj['internalGroupUsers'] = groupMembers;
        obj['isShowSubMenu'] = false;
        obj['isShowMinusOutlinedIcon'] = indexGroupMemberChecked !== -1 ? false : true;
        obj['isYourGroup'] = indexGroupMemberChecked !== -1;
        internalGroups.push(obj);
      });
    }
    // Add a option for group "All Unassigned Tasks"
    if (internalGroups.length > 0) {
      let optionGroupAllUnassignedTasks = {
        id: null,
        isChecked: false,
        fullName: allUnassignedTasksLabel,
        internalGroupUsers: [],
        isShowSubMenu: false,
        isShowMinusOutlinedIcon: false,
        isYourGroup: false
      };
      internalGroups.unshift(optionGroupAllUnassignedTasks);
    }
    return internalGroups;
  };

  const internalUsers = getInternalUsers();
  const internalGroups = getInternalGroups();

  const [menuState, setMenuState] = useState({
    // the initial state of all menu items is all false
    'Plan Status': {
      type: 'group',
      'Plan Status': {
        type: 'checkbox',
        values: Object.values(inventoryReconStatusLabels).reduce((obj, v) => {
          obj[v] = false;
          return obj;
        }, {})
      },
      'Completion Date': {
        type: 'radio',
        visible: false,
        disabled: true,
        values: null,
        options: ['Last 7 days', 'Last 30 days', 'Last 90 days', 'Custom Range']
      },
      datepicker: {
        type: 'datepicker',
        disabled: true,
        values: null,
        visible: false
      }
    },
    'Task Type': {
      type: 'checkbox',
      values: Object.values(taskTypesData).reduce((obj, v) => {
        obj[v.name] = false;
        return obj;
      }, {})
    },
    Category: {
      type: 'checkbox',
      values: Object.values(taskCategoriesData).reduce((obj, v) => {
        obj[v.name] = false;
        return obj;
      }, {})
    },
    'Task Progress': {
      type: 'checkbox',
      values: Object.values(taskProgressLabels).reduce((obj, v) => {
        obj[v] = false;
        return obj;
      }, {})
    },
    Dealership: {
      type: 'checkbox',
      values: assigneeData?.dealerships.reduce((obj, v) => {
        obj[v.name] = false;
        return obj;
      }, {})
    },
    Vendor: {
      type: 'checkbox',
      values: assigneeData?.vendors.reduce((obj, v) => {
        obj[v.name] = false;
        return obj;
      }, {})
    },
    Group: {
      type: 'checkbox',
      values: internalGroups
    },
    'Assigned To': {
      type: 'checkbox',
      values: internalUsers
    },
    ProfitTime: {
      type: 'checkbox',
      values: hasReconProfitTime
        ? Object.values(profitTimeLabels).reduce((obj, v) => {
            obj[v] = false;
            return obj;
          }, {})
        : {}
    },
    More: {
      type: 'group',
      'Recall Status': {
        type: 'checkbox',
        values: {
          Open: false,
          Closed: false,
          None: false
        }
      },
      'Sold Status': {
        type: 'checkbox',
        values: {
          Sold: false,
          'Not Sold': false
        }
      },
      'Vehicle Status': {
        type: 'checkbox',
        values: Object.values(inventoryStatusLabels).reduce((obj, v) => {
          obj[v] = false;
          return obj;
        }, {})
      },
      Disposition: {
        type: 'checkbox',
        values: getDealerDispositions().reduce((obj, v) => {
          obj[v] = false;
          return obj;
        }, {})
      },
      'Hidden Status': {
        type: 'checkbox',
        values: Object.values(hiddenStatusLabels).reduce((obj, v) => {
          obj[v] = false;
          return obj;
        }, {})
      }
    }
  });

  const previousState = useRef({ forceLoadQueryFromStore: false, query });

  // remove internal group filter when no groups in system (dealer)
  useEffect(() => {
    if (!assigneeData) {
      return;
    }

    if ((assigneeData.internalGroups?.length || 0) === 0) {
      delete menuState[GROUP];
    }

    if ((assigneeData.vendors?.length || 0) === 0) {
      delete menuState[VENDOR];
    }

    if ((assigneeData.dealerships?.length || 0) === 0) {
      delete menuState[DEALERSHIP];
    }
  }, [assigneeData]);

  // should only run initially
  useEffect(() => {
    if (!selectedVehicleId) {
      onQueryChange();
      previousState.current.query = query;
    }
  }, []);

  // when forceLoadQueryFromStore updated, query might not has value (unexpectedly) which will lead to clear all current filter
  // if forceLoadQueryFromStore updated so we will need to wait query for updated too
  useEffect(() => {
    if (forceLoadQueryFromStore) {
      previousState.current.forceLoadQueryFromStore = forceLoadQueryFromStore;
    }

    if (
      previousState.current.forceLoadQueryFromStore &&
      JSON.stringify(previousState.current.query) !== JSON.stringify(query)
    ) {
      onQueryChange();
      previousState.current = { forceLoadQueryFromStore: false, query };
    }
  }, [forceLoadQueryFromStore, query]);

  const updateTransientMenuState = (transientMenuState, menu, subMenu, queryItems) => {
    if (transientMenuState === undefined) {
      return transientMenuState;
    }
    if (transientMenuState[menu] === undefined) {
      return transientMenuState;
    }

    if (queryItems) {
      if (subMenu && transientMenuState[menu][subMenu].type === 'checkbox') {
        Object.keys(transientMenuState[menu][subMenu].values || {}).forEach(
          (key) => (transientMenuState[menu][subMenu].values[key] = queryItems.includes(key))
        );
      } else if (subMenu && transientMenuState[menu][subMenu].type === 'radio') {
        transientMenuState[menu][subMenu].values = queryItems;
      } else if (subMenu && transientMenuState[menu][subMenu].type === 'datepicker') {
        transientMenuState[menu][subMenu].values = {
          startDate: queryItems[0],
          endDate: queryItems[1]
        };
      } else {
        if (menu === 'Assigned To' || menu === GROUP) {
          let queryItemValues = Object.values(JSON.parse(queryItems));
          if (queryItemValues?.length > 0) {
            transientMenuState[menu].values.forEach((item, index) => {
              if (menu === 'Assigned To') {
                let indexQueryItem = queryItemValues.findIndex((f) => f.assignedTo === item.id);
                if (indexQueryItem !== -1) {
                  transientMenuState[menu].values[index].isChecked = true;
                  transientMenuState[menu].values[index].isAssignedDirectly =
                    queryItemValues[indexQueryItem].assignedDirectly;
                  if (transientMenuState[menu].values[index].isMemberOfAnyGroup) {
                    transientMenuState[menu].values[index].isAssignedThruGroup =
                      queryItemValues[indexQueryItem].assignedThruGroup;
                  }
                  if (
                    !transientMenuState[menu].values[index].isAssignedThruGroup ||
                    !transientMenuState[menu].values[index].isAssignedDirectly
                  ) {
                    transientMenuState[menu].values[index].isShowMinusOutlinedIcon = true;
                  } else {
                    transientMenuState[menu].values[index].isShowMinusOutlinedIcon = false;
                  }
                } else {
                  transientMenuState[menu].values[index].isChecked = false;
                  transientMenuState[menu].values[index].isAssignedDirectly = false;
                  if (transientMenuState[menu].values[index].isMemberOfAnyGroup) {
                    transientMenuState[menu].values[index].isAssignedThruGroup = false;
                  }
                }
              }
              if (menu === GROUP) {
                let queryItem = queryItemValues.find((f) => f.assignedToGroup === item.id);
                if (queryItem !== undefined) {
                  transientMenuState[menu].values[index].isChecked = true;
                  transientMenuState[menu].values[index].internalGroupUsers.forEach((member) => {
                    let indexMember = -1;
                    if (queryItem.assignedToMembers?.length > 0) {
                      indexMember = queryItem?.assignedToMembers.findIndex((userId) => userId === member.userId);
                    }
                    member.isChecked = indexMember !== -1;
                    if (member.userId === null) {
                      member.isChecked = queryItem.unassigned;
                    }
                  });

                  if (transientMenuState[menu].values[index].internalGroupUsers?.length > 0) {
                    let isShowMinusOutlinedIcon = transientMenuState[menu].values[index].internalGroupUsers.some(
                      (member) => !member.isChecked
                    );
                    transientMenuState[menu].values[index].isShowMinusOutlinedIcon = isShowMinusOutlinedIcon;
                  }
                  let groupHasMemberUnassignedIsUnChecked = transientMenuState[menu].values.find((newMenuWithType) =>
                    newMenuWithType.internalGroupUsers.some(
                      (groupMember) => groupMember.fullName === 'Unassigned' && !groupMember.isChecked
                    )
                  );
                  let groupHasMemberUnassignedIsChecked = transientMenuState[menu].values.find((menuWithType) =>
                    menuWithType.internalGroupUsers.some(
                      (groupMember) => groupMember.fullName === 'Unassigned' && groupMember.isChecked
                    )
                  );
                  let indexMenuAllUnassignedTasks = transientMenuState[menu].values.findIndex(
                    (f) => f.fullName === allUnassignedTasksLabel
                  );
                  if (indexMenuAllUnassignedTasks !== -1) {
                    if (groupHasMemberUnassignedIsUnChecked !== undefined) {
                      if (
                        groupHasMemberUnassignedIsChecked !== undefined &&
                        queryItemValues.find((f) => f.unassigned)
                      ) {
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = true;
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isChecked = true;
                      } else {
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isChecked = false;
                        transientMenuState[menu].values[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = false;
                      }
                    } else {
                      transientMenuState[menu].values[indexMenuAllUnassignedTasks].isChecked = true;
                      transientMenuState[menu].values[indexMenuAllUnassignedTasks].isShowMinusOutlinedIcon = false;
                    }
                  }
                } else {
                  transientMenuState[menu].values[index].isChecked = false;
                  if (transientMenuState[menu].values[index].internalGroupUsers?.length > 0) {
                    transientMenuState[menu].values[index].internalGroupUsers.forEach((member) => {
                      member.isChecked = false;
                    });
                  }
                }
              }
            });
          } else {
            transientMenuState[menu].values.forEach((item) => {
              if (item.isChecked) {
                item.isChecked = false;
                item.isShowSubMenu = false;
                item.isShowMinusOutlinedIcon = false;
                if (menu === 'Assigned To') {
                  item.isAssignedDirectly = false;
                  item.isAssignedThruGroup = false;
                }
                if (menu === GROUP) {
                  if (item.internalGroupUsers?.length > 0) {
                    item.internalGroupUsers.forEach((member) => {
                      if (member.isChecked) {
                        member.isChecked = false;
                      }
                    });
                  }
                }
              }
            });
          }
        } else {
          Object.keys(transientMenuState[menu].values || {}).forEach(
            (key) => (transientMenuState[menu].values[key] = queryItems.includes(key))
          );
        }
      }
    } else {
      if (menu === 'Assigned To' || menu === GROUP) {
        let menuStateIsChecked = transientMenuState[menu].values.filter((f) => f.isChecked);
        if (menuStateIsChecked?.length > 0) {
          menuStateIsChecked.forEach((item) => {
            if (item.isChecked) {
              item.isChecked = false;
              item.isShowSubMenu = false;
              item.isShowMinusOutlinedIcon = false;
              if (menu === 'Assigned To') {
                item.isAssignedDirectly = false;
                item.isAssignedThruGroup = false;
              }
              if (menu === GROUP) {
                if (item.internalGroupUsers?.length > 0) {
                  let groupMemberIsChecked = item.internalGroupUsers.filter((f) => f.isChecked);
                  if (groupMemberIsChecked?.length > 0) {
                    item.internalGroupUsers.forEach((member) => {
                      if (member.isChecked) {
                        member.isChecked = false;
                      }
                    });
                  }
                }
              }
            }
          });
        }
      }
    }
    if (!fromDashboard && ['Completion Date', 'datepicker'].includes(subMenu)) {
      transientMenuState[menu][subMenu].disabled = true;
      transientMenuState[menu][subMenu].visible = false;
    }
    return transientMenuState;
  };
  const getTaskTypeIds = (taskType) => taskTypesData.filter((t) => taskType.includes(t.name)).map((t) => t.id);
  const getTaskCategoryIds = (taskCategoryList) =>
    taskCategoriesData.filter((t) => taskCategoryList?.includes(t.name)).map((t) => t.id);
  const getAssignedToIds = (assignedTo) => {
    if (assignedTo.includes('All')) {
      return users.map((t) => t.id);
    } else {
      return users.filter((t) => assignedTo.includes(t.fullName)).map((t) => t.id);
    }
  };

  useDebouncedEffect(
    () => {
      if (!selectedVehicleId && !isNotification) {
        onQueryChange();
      }
    },
    300,
    [
      JSON.stringify(planStatus),
      JSON.stringify(completionDate),
      JSON.stringify(completedOnStart),
      JSON.stringify(completedOnEnd),
      JSON.stringify(taskType),
      JSON.stringify(taskCategories),
      JSON.stringify(taskProgress),
      JSON.stringify(assignedTo),
      JSON.stringify(recallStatus),
      JSON.stringify(soldStatus),
      JSON.stringify(vehicleStatus),
      JSON.stringify(disposition),
      JSON.stringify(entitiesFilter),
      JSON.stringify(assignedToVendor),
      JSON.stringify(profitTime),
      JSON.stringify(hiddenStatus),
      sort,
      search,
      fromDashboard,
      assignedToTechnicianFilter,
      assignedToGroupFilter
    ]
  );

  // onQueryChange handles when a user changes the filters on a page
  // it updates both menuState and refetches the page based on new filters
  const onQueryChange = () => {
    let transientMenuState = stripReferences(menuState);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Plan Status', 'Plan Status', planStatus);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Plan Status', 'Completion Date', completionDate);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Plan Status', 'datepicker', [
      completedOnStart,
      completedOnEnd
    ]);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Task Type', null, taskType);
    transientMenuState = updateTransientMenuState(transientMenuState, CATEGORY, null, taskCategories);
    transientMenuState = updateTransientMenuState(transientMenuState, 'Task Progress', null, taskProgress);

    if (menuState.Vendor && flags.reconVendorManagement) {
      transientMenuState = updateTransientMenuState(transientMenuState, VENDOR, null, assignedToVendor);
    }
    transientMenuState = updateTransientMenuState(transientMenuState, 'Assigned To', null, assignedToTechnicianFilter);
    if (assigneeData.internalGroups && !assigneeData.internalGroups.length) {
      delete menuState[GROUP];
    } else {
      transientMenuState = updateTransientMenuState(transientMenuState, GROUP, null, assignedToGroupFilter);
    }

    transientMenuState = updateTransientMenuState(transientMenuState, 'ProfitTime', null, profitTime);
    transientMenuState = updateTransientMenuState(transientMenuState, DEALERSHIP, null, entitiesFilter);
    transientMenuState = updateTransientMenuState(transientMenuState, 'More', 'Recall Status', recallStatus);
    transientMenuState = updateTransientMenuState(transientMenuState, 'More', 'Sold Status', soldStatus);
    transientMenuState = updateTransientMenuState(transientMenuState, 'More', 'Vehicle Status', vehicleStatus);
    transientMenuState = updateTransientMenuState(transientMenuState, 'More', 'Disposition', disposition);

    if (flags.reconHiddenFilter) {
      transientMenuState = updateTransientMenuState(transientMenuState, 'More', 'Hidden Status', hiddenStatus);
    }
    setMenuState(transientMenuState);
  };

  const newFilters = useMemo(() => {
    const filter = {
      reconStatus:
        planStatus.length > 0
          ? planStatus.map((r) => reconStatusReverseLookup[r]).filter((x) => x !== 'ON_TARGET' && x !== 'AT_RISK')
          : Object.keys(inventoryReconStatusLabels).filter((x) => x !== 'ON_TARGET' && x !== 'AT_RISK'), // when none are selected, it means show all, but since we're not exposing all possible enums options, not passing in any values will get everything on the api side, which will include TRANSPORTING

      //want to filter out On Target and At Risk because those should not be sent as reconStatus, but reconPlanProgress instead
      reconPlanProgress:
        planStatus.length > 0
          ? planStatus
              .map((r) => reconStatusReverseLookup[r])
              .filter((status) => ['ON_TARGET', 'AT_RISK'].includes(status))
          : [],
      // when none are selected, it means show all, but since we're not exposing all possible enums options, not passing in any values will get everything on the api side, which will include TRANSPORTING
      completedOnStart,
      completedOnEnd,
      currentTaskType: getTaskTypeIds(taskType),
      taskCategories: getTaskCategoryIds(taskCategories),
      currentTaskProgress: taskProgress.map((t) => taskProgressReverseLookup[t]),
      assignedTo: getAssignedToIds(assignedTo),
      recallStatus: recallStatus,
      soldStatus: soldStatus.map((s) => soldStatusReverseLookup[s]),
      profitTime: profitTime.map((s) => profitTimeReverseLookup[s]),
      inventoryStatus:
        vehicleStatus.length > 0
          ? vehicleStatus.map((v) => inventoryStatusReverseLookup[v])
          : Object.keys(inventoryStatusLabels), // when none are selected, it means show all, but since currently at the moment, the api defauls to 'Active' when null is passed in, we will need to be specific.  The reasoning for this currently is to not introduce any breaking changes for mobile, after GA, we'll look to migrating to null = show all
      disposition: disposition.map((d) => dispositionReverseLookup[d]),
      search,
      assignedToTechnicianFilter: assignedToTechnicianFilter || JSON.stringify([]),
      assignedToGroupFilter:
        assigneeData?.internalGroups && assigneeData.internalGroups.length
          ? assignedToGroupFilter || JSON.stringify([])
          : JSON.stringify([]),
      assignedToVendor: assigneeData?.vendors
        ?.filter((v) => (assignedToVendor || []).includes(v.name))
        .map((v) => v.id),
      entitiesFilter: assigneeData?.dealerships
        ?.filter((v) => (entitiesFilter || []).includes(v.name))
        .map((v) => v.id),
      hiddenStatus: hiddenStatus?.map((status) => hiddenStatusReverseLookup[status])
    };

    delete filter.assignedTo;
    if (!flags.reconHiddenFilter) {
      delete filter.hiddenStatus;
    }

    return filter;
  }, [
    planStatus,
    taskProgress,
    taskType,
    assignedTo,
    assignedToTechnicianFilter,
    assignedToGroupFilter,
    profitTime,
    entitiesFilter,
    taskCategories,
    search,
    hiddenStatus
  ]);

  return [newFilters, menuState, setMenuState];
};
