import { deleteWithToken, getWithToken, postFileWithToken, postWithToken, putWithToken, patchWithToken } from 'api';
import { apiStatusConstants, communicationContexts, EXPORT_FAILURE_MESSAGE, lineItemStatusTypes } from 'app-constants';
import { put, select, takeEvery, takeLatest, take, race, all } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { LINE_ITEMS_CHANGE_STATUS, OPERATION_LINE_ITEM } from 'app-constants/lineItemConstants';
import { vdpActions } from 'store/vdpStore';
import { currentDealerSelector, CURRENT_DEALER } from 'store/dealersStore';
import { executeWithMinimumTime } from 'store/helperFunctions/sagaHelpers';
import { messagesActions } from 'store/messagesStore';
import { usersActions, USERS } from 'store/usersStore';
import { vehiclesActions } from 'store/vehiclesStore';
import { createRequestTypes, makeActionCreator, convertDataToSend, convertDataToDisplay } from 'utils';
import { dateTimeWithFormat } from 'utils/dateTimeUtils';
import { stripReferences, updateObjectInArray, trimLineBreakBlocks } from 'utils/arrayUtils';
import { v4 as uuidv4 } from 'uuid';
import { taskTypesActions } from 'store/taskTypesStore';
import { taskCategoriesActions } from 'store/taskCategoriesStore';
import { rootEntitySwitcherSelector } from './dealersStore';

const MaxIntegerNumber = 123456789;

//#region Actions
export const TASKS = createRequestTypes('TASKS', [
  'GET_SINGLE',
  'GET_DATA',
  'GET_EXPORT_DATA',
  'SET_DATA',
  'SET_POPOUT_WIDTH',
  'SET_TASK_DETAILS_POPOUT_WIDTH',
  'SET_FILTERS',
  'SET_SORT',
  'SET_PAGER',
  'SET_FETCH_STATUS',
  'DECLINE_TASK',
  'COMPLETE_TASK',
  'UPDATE_TASK',
  'UPDATE_TASK_STATUS',
  'UPDATE_TASK_NEEDS_APPROVAL_STATUS',
  'UPDATE_TASK_FINAL_COST',
  'REFRESH_FINAL_COST_FOR_TASK',
  'UPDATE_RECON_PLAN',
  'UPDATE_RECON_PLAN_WITH_GROUPS',
  'SET_COMMENTS_FETCH_STATUS',
  'GET_COMMENTS_FOR_TASK_ENTITY',
  'SET_COMMENTS',
  'ADD_COMMENT_FROM_TASKS_STORE',
  'ADD_COMMENT',
  'UPDATE_COMMENT_FROM_TASKS_STORE',
  'UPDATE_COMMENT',
  'DELETE_COMMENT_FROM_TASKS_STORE',
  'DELETE_COMMENT',
  'SET_TASKS_LINE_ITEM_STORE',
  'SET_TASKS_LINE_ITEM_BY_VEHICLEID',
  'RESET_TASKS_LINE_ITEM_BY_VEHICLEID',
  'GET_TASKS_LINE_ITEM_BY_VEHICLEID',
  'SET_REFRESH_TASKS',
  'GET_TASK_TIME_IN_APPROVAL_LIST',
  'SET_TASK_TIME_IN_APPROVAL_LIST',
  'SET_TIME_IN_APPROVAL_STATUS',
  'RESET_TASK_TIME_IN_APPROVAL_LIST',
  'GET_DEPENDENCY_DATA',
  'GET_DEALERSHIP_FOR_VENDOR',
  'SET_DEALERSHIP_FOR_VENDOR',
  'SET_DEALERSHIP_FETCH_STATUS',
  'SET_TASKS_EXPORT_FETCH_STATUS'
]);
export const tasksActions = {
  getSingle: makeActionCreator(TASKS.GET_SINGLE, 'id', 'includeFullComments'), //no reducer, triggers getSingleTaskSaga
  getData: makeActionCreator(
    TASKS.GET_DATA,
    'dealerId',
    'start',
    'limit',
    'filters',
    'sort',
    'minimumExecutionTime',
    'flags'
  ), // this triggers getTasksSaga, no reducer
  getExportData: makeActionCreator(
    TASKS.GET_EXPORT_DATA,
    'dealerId',
    'start',
    'filters',
    'sort',
    'minimumExecutionTime'
  ),
  setData: makeActionCreator(TASKS.SET_DATA, 'data'),
  setPopoutWidth: makeActionCreator(TASKS.SET_POPOUT_WIDTH, 'width'),
  setTaskDetailsPopoutWidth: makeActionCreator(TASKS.SET_TASK_DETAILS_POPOUT_WIDTH, 'width'),
  setFilters: makeActionCreator(TASKS.SET_FILTERS, 'filters'),
  setSort: makeActionCreator(TASKS.SET_SORT, 'sort'),
  setPager: makeActionCreator(TASKS.SET_PAGER, 'pager'),
  setFetchStatus: makeActionCreator(TASKS.SET_FETCH_STATUS, 'fetchStatus'),
  setTimeInApprovalStatus: makeActionCreator(TASKS.SET_TIME_IN_APPROVAL_STATUS, 'fetchStatus'),
  declineTask: makeActionCreator(
    TASKS.DECLINE_TASK,
    'userId',
    'taskId',
    'declineReason',
    'assignedToGroupId',
    'assignedToGroupType'
  ),
  completeTask: makeActionCreator(
    TASKS.COMPLETE_TASK,
    'taskId',
    'completedCost',
    'completionNote',
    'assignedToGroupId',
    'assignedToGroupType'
  ),
  updateTask: makeActionCreator(TASKS.UPDATE_TASK, 'taskId', 'task'),
  updateTaskStatus: makeActionCreator(TASKS.UPDATE_TASK_STATUS, 'taskId', 'isSaving'),
  updateTaskNeedsApprovalStatus: makeActionCreator(
    TASKS.UPDATE_TASK_NEEDS_APPROVAL_STATUS,
    'taskId',
    'needsApprovalStatus'
  ),
  updateTaskFinalCost: makeActionCreator(
    TASKS.UPDATE_TASK_FINAL_COST,
    'task',
    'taskId',
    'totalCostApproved',
    'needsApproval'
  ),
  refreshFinalCostForTask: makeActionCreator(TASKS.REFRESH_FINAL_COST_FOR_TASK, 'taskId', 'completedCost'),
  updateReconPlan: makeActionCreator(
    TASKS.UPDATE_RECON_PLAN,
    'vehicleId',
    'reconPlan',
    'loadingMessage',
    'successMessage',
    'errorMessage'
  ), // no reducer, triggers updateReconPlan
  updateReconPlanWithGroups: makeActionCreator(
    TASKS.UPDATE_RECON_PLAN_WITH_GROUPS,
    'vehicleId',
    'reconPlan',
    'newlyCompletedTasks',
    'loadingMessage',
    'successMessage',
    'errorMessage',
    'vehiclesList',
    'reconTimestampFilterFlag',
    'reconHiddenFilterFlag',
    'hiddenStatusQuery'
  ), // no reducer, triggers updateReconPlan
  setCommentsFetchStatus: makeActionCreator(TASKS.SET_COMMENTS_FETCH_STATUS, 'fetchStatus'),
  // setCommentsFetchStatus: makeActionCreator(TASKS.SET_COMMENTS_FETCH_STATUS, 'reconTaskId', 'fetchStatus'),
  getCommentsForTaskEntity: makeActionCreator(TASKS.GET_COMMENTS_FOR_TASK_ENTITY, 'vehicleId', 'reconTaskId'),
  setComments: makeActionCreator(TASKS.SET_COMMENTS, 'reconTaskId', 'comments'),
  addCommentFromTasksStore: makeActionCreator(
    TASKS.ADD_COMMENT_FROM_TASKS_STORE,
    'reconTaskId',
    'comment',
    'images',
    'loadingMessage',
    'successMessage',
    'errorMessage',
    'locationAction'
  ), // no reducer, triggers addCommentFromTasksStoreSaga
  addComment: makeActionCreator(TASKS.ADD_COMMENT, 'reconTaskId', 'comment'),
  updateCommentFromTasksStore: makeActionCreator(
    TASKS.UPDATE_COMMENT_FROM_TASKS_STORE,
    'reconTaskId',
    'comment',
    'index'
  ), // no reducer, triggers updateCommentSaga
  updateComment: makeActionCreator(TASKS.UPDATE_COMMENT, 'reconTaskId', 'comment'),
  deleteCommentFromTasksStore: makeActionCreator(
    TASKS.DELETE_COMMENT_FROM_TASKS_STORE,
    'reconTaskId',
    'commentId',
    'successMessage',
    'loadingMessage'
  ), // no reducer, triggers deleteCommentSaga
  deleteComment: makeActionCreator(TASKS.DELETE_COMMENT, 'reconTaskId', 'commentId'),
  getTasksLineItemByVehicleId: makeActionCreator(
    TASKS.GET_TASKS_LINE_ITEM_BY_VEHICLEID,
    'vehicleId',
    'localTasksLineItems',
    'vehiclesList',
    'reconTimestampFilterFlag',
    'reconHiddenFilterFlag',
    'hiddenStatusQuery'
  ),
  setTasksLineItemByVehicleId: makeActionCreator(TASKS.SET_TASKS_LINE_ITEM_BY_VEHICLEID, 'lineItemTasks'),
  resetTasksLineItemByVehicleId: makeActionCreator(TASKS.RESET_TASKS_LINE_ITEM_BY_VEHICLEID),
  setTasksLineItemStore: makeActionCreator(TASKS.SET_TASKS_LINE_ITEM_STORE, 'taskToUpdate'),
  setRefreshTasks: makeActionCreator(TASKS.SET_REFRESH_TASKS, 'reconTaskId', 'lineItems'),
  getTaskTimeInApprovalList: makeActionCreator(
    TASKS.GET_TASK_TIME_IN_APPROVAL_LIST,
    'dealerId',
    'entityId',
    'entityType'
  ),
  setTaskTimeInApprovalList: makeActionCreator(TASKS.SET_TASK_TIME_IN_APPROVAL_LIST, 'taskTimeInApprovalList'),
  resetTaskTimeInApprovalList: makeActionCreator(TASKS.RESET_TASK_TIME_IN_APPROVAL_LIST, 'taskTimeInApprovalList'),
  getDependencyData: makeActionCreator(TASKS.GET_DEPENDENCY_DATA, 'dealerId', 'isVendorOrRoot'),
  getDealerships: makeActionCreator(TASKS.GET_DEALERSHIP_FOR_VENDOR, 'vendorId'),
  setDealerships: makeActionCreator(TASKS.SET_DEALERSHIP_FOR_VENDOR, 'dealerships'),
  setDealershipsFetchStatus: makeActionCreator(TASKS.SET_DEALERSHIP_FETCH_STATUS, 'fetchStatus'),
  setTasksExportFetchStatus: makeActionCreator(TASKS.SET_TASKS_EXPORT_FETCH_STATUS, 'fetchStatus')
};
//#endregion

//#region Reducer
const initialState = {
  data: {
    count: 0,
    items: []
  },
  fetchStatus: apiStatusConstants.IS_FETCHING,
  popoutWidth: 392,
  taskDetailsPopoutWidth: null,
  pager: {
    start: 1,
    limit: window.localStorage.getItem('tasksPageSize') || 10
  },
  locationAction: null,
  messageTaskStatus: apiStatusConstants.IS_FETCHING,
  lineItemTasks: {
    count: 0,
    items: []
  },
  lineItemByTasksId: [],
  refreshTasks: false,
  taskTimeInApprovalList: [],
  timeInApprovalStatus: apiStatusConstants.IS_FETCHING,
  dealerships: [],
  dealershipFetchStatus: apiStatusConstants.IS_FETCHING,
  tasksExportFetchStatus: apiStatusConstants.PENDING
};

export const tasksReducer = (state = initialState, action) => {
  let newData;
  let task;
  switch (action.type) {
    case TASKS.SET_DATA:
      return {
        ...state,
        data: { ...action.data }
      };
    case TASKS.SET_POPOUT_WIDTH:
      return {
        ...state,
        popoutWidth: state.popoutWidth + action.width // the action.width payload is just the change in the width
      };
    case TASKS.SET_TASK_DETAILS_POPOUT_WIDTH:
      return {
        ...state,
        taskDetailsPopoutWidth: action.width
      };
    case TASKS.SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case TASKS.SET_SORT:
      return {
        ...state,
        sort: action.sort
      };
    case TASKS.SET_PAGER:
      return {
        ...state,
        pager: { ...action.pager }
      };
    case TASKS.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.fetchStatus
      };
    case TASKS.SET_TIME_IN_APPROVAL_STATUS:
      return {
        ...state,
        timeInApprovalStatus: action.fetchStatus
      };
    case TASKS.SET_TASKS_EXPORT_FETCH_STATUS:
      return {
        ...state,
        tasksExportFetchStatus: action.fetchStatus
      };
    case TASKS.UPDATE_TASK_STATUS:
      const copyOfState = stripReferences(state);
      const taskToUpdateIndex = copyOfState?.data?.items?.findIndex?.(({ id }) => id === action?.taskId);
      if (copyOfState?.data?.items?.[taskToUpdateIndex]) {
        copyOfState.data.items[taskToUpdateIndex].saving = action?.isSaving;
      }
      return { ...copyOfState };
    case TASKS.UPDATE_TASK_NEEDS_APPROVAL_STATUS:
      const copyOfOldState = stripReferences(state);
      const IndexOfTaskToUpdate = copyOfOldState?.data?.items?.findIndex?.(({ id }) => id === action?.taskId);
      if (copyOfOldState?.data?.items?.[IndexOfTaskToUpdate]) {
        copyOfOldState.data.items[IndexOfTaskToUpdate].needsApproval = action?.needsApprovalStatus;
      }
      return { ...copyOfOldState };
    case TASKS.SET_COMMENTS_FETCH_STATUS:
      // newData = stripReferences(state.data);
      // newData.items.find((x) => x.id === action.reconTaskId).communicationsFetchStatus = action.fetchStatus;
      return {
        ...state,
        // data: newData
        messageTaskStatus: action.fetchStatus
      };
    case TASKS.SET_COMMENTS:
      newData = stripReferences(state.data);
      task = newData.items.find((x) => x.id === action.reconTaskId);
      task.comments = action.comments;
      // task.communicationsFetchStatus = apiStatusConstants.SUCCEEDED;
      return {
        ...state,
        data: newData
      };
    case TASKS.ADD_COMMENT:
      newData = stripReferences(state.data);
      task = newData.items.find((x) => x.id === action.reconTaskId);
      task.comments.items.unshift(action.comment); // insert it at the front, since it's sorted newest first
      task.comments.count += 1;
      return {
        ...state,
        data: newData
      };
    case TASKS.UPDATE_TASK_FINAL_COST:
      newData = stripReferences(state.data);
      task = newData.items.find((x) => x.id === action.taskId);
      task.completedCost = action.totalCostApproved;
      task.needsApproval = action.needsApproval;

      return {
        ...state,
        data: newData
      };
    case TASKS.UPDATE_COMMENT:
      newData = stripReferences(state.data);
      task = newData.items.find((x) => x.id === action.reconTaskId);
      task.comments.items = updateObjectInArray(task.comments.items, {
        index: task.comments.items.findIndex((x) => x.id === action.comment.id),
        item: action.comment
      });
      return {
        ...state,
        data: newData
      };
    case TASKS.DELETE_COMMENT:
      newData = stripReferences(state.data);
      task = newData.items.find((x) => x.id === action.reconTaskId);
      task.comments.items = task.comments.items.filter((x) => x.id !== action.commentId);
      task.comments.count = task.comments.items.length;
      return {
        ...state,
        data: newData
      };
    case TASKS.SET_TASKS_LINE_ITEM_BY_VEHICLEID:
      return {
        ...state,
        lineItemTasks: { ...action.lineItemTasks }
      };
    case TASKS.RESET_TASKS_LINE_ITEM_BY_VEHICLEID:
      return {
        ...state,
        lineItemTasks: {
          count: 0,
          items: []
        }
      };
    case TASKS.SET_TASKS_LINE_ITEM_STORE:
      const newLineItemTasks = stripReferences(state.lineItemTasks);
      const taskIndex = newLineItemTasks?.items?.findIndex?.((x) => x.id === action.taskToUpdate.id);
      if (newLineItemTasks?.items?.[taskIndex]) {
        newLineItemTasks.items[taskIndex] = action.taskToUpdate;
      } else {
        if (newLineItemTasks.items) newLineItemTasks.items.push(action.taskToUpdate);
      }
      newLineItemTasks.count = newLineItemTasks.items ? newLineItemTasks.items.length : 0;
      return {
        ...state,
        lineItemTasks: newLineItemTasks
      };
    case TASKS.SET_REFRESH_TASKS:
      newData = stripReferences(state.data);
      task = newData.items.find((x) => x.id === action.reconTaskId);
      if (!task.lineItems) {
        task.lineItems = {};
      }
      task.lineItems.items = action.lineItems;
      task.lineItems.count = action.lineItems.length;
      task.lineItems.limit = 999;
      return {
        ...state,
        data: newData
      };
    case CURRENT_DEALER.SWITCH:
      return {
        ...initialState
      };
    case TASKS.SET_TASK_TIME_IN_APPROVAL_LIST:
      return {
        ...state,
        taskTimeInApprovalList: action.taskTimeInApprovalList
      };
    case TASKS.RESET_TASK_TIME_IN_APPROVAL_LIST:
      return {
        ...state,
        taskTimeInApprovalList: []
      };
    case TASKS.SET_DEALERSHIP_FOR_VENDOR:
      return {
        ...state,
        dealerships: action.dealerships
      };
    case TASKS.SET_DEALERSHIP_FETCH_STATUS:
      return {
        ...state,
        dealershipFetchStatus: action.fetchStatus
      };
    default:
      return state;
  }
};

//#endregion

//#region Selectors
export const taskDataSelector = createSelector(
  (state) => state.tasks,
  (tasks) => tasks.data
);
//#endregion

//#region Sagas
export function* getSingleTaskSaga() {
  yield takeLatest(TASKS.GET_SINGLE, function* ({ id, includeFullComments = false }) {
    try {
      yield put(tasksActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const tasksData = yield getWithToken(`/api/ReconTasks/id/${id}`, {
        include: ['vehicle', 'location', 'Comments']
      });
      yield put(tasksActions.setData({ count: 1, items: [tasksData] }));

      if (includeFullComments) {
        yield put(tasksActions.getCommentsForTaskEntity(tasksData.vehicleId, id));
      }

      yield put(tasksActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(tasksActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getSingleTaskSaga', error);
    }
  });
}
export function* getTaskByVehicleIdSaga() {
  yield takeLatest(
    TASKS.GET_TASKS_LINE_ITEM_BY_VEHICLEID,
    function* ({
      vehicleId,
      localTasksLineItems,
      vehiclesList = {},
      reconTimestampFilterFlag,
      reconHiddenFilterFlag,
      hiddenStatusQuery
    }) {
      try {
        yield put(tasksActions.setFetchStatus(apiStatusConstants.IS_FETCHING));

        const params = {
          include: ['LineItems'],
          vehicleId,
          limit: 99999
        };

        if (reconTimestampFilterFlag) {
          params.clientTimezoneOffset = new Date().getTimezoneOffset();
        }

        if (reconHiddenFilterFlag && hiddenStatusQuery?.length > 0) {
          params.hiddenStatus = ['IncludeHidden'];
        }

        const tasksData = yield getWithToken(`/api/ReconTasks`, params);
        const mergedTaskslineItemDBAndLocal = {
          count: 0,
          items: []
        };

        if (!!localTasksLineItems && !!localTasksLineItems.count) {
          let listTasksChangeTaskType = [];
          let listTasksNeedToUpdate = [];
          let listTaskUpdated = [];

          if (!!localTasksLineItems && Array.isArray(localTasksLineItems.items)) {
            listTasksChangeTaskType = localTasksLineItems.items.filter(
              (item) => item.isChangeReconTaskType && !item.isAddingTask
            );
            listTasksNeedToUpdate = localTasksLineItems.items.filter(
              (item) => !item.isChangeReconTaskType && !item.isAddingTask
            );
          }

          if (!!tasksData && Array.isArray(tasksData.items)) {
            tasksData.items.forEach((taskUpdate) => {
              listTasksNeedToUpdate.forEach((taskNeedUpdate) => {
                if (taskUpdate.id === taskNeedUpdate.id) {
                  listTaskUpdated.push(taskUpdate);
                }
              });
            });
          }

          if (Object.keys(localTasksLineItems).length) {
            mergedTaskslineItemDBAndLocal.items = [
              ...listTaskUpdated,
              ...listTasksChangeTaskType,
              ...localTasksLineItems.items.filter((item) => item.isAddingTask)
            ];
            mergedTaskslineItemDBAndLocal.count = mergedTaskslineItemDBAndLocal.items.length;
            yield put(tasksActions.setTasksLineItemByVehicleId(mergedTaskslineItemDBAndLocal));
          } else {
            yield put(tasksActions.setTasksLineItemByVehicleId(tasksData));
          }
        } else {
          if (Object.keys(localTasksLineItems).length) {
            mergedTaskslineItemDBAndLocal.items = [
              ...tasksData.items,
              ...localTasksLineItems.items.filter((item) => item.isAddingTask)
            ];
            mergedTaskslineItemDBAndLocal.count = mergedTaskslineItemDBAndLocal.items.length;
            yield put(tasksActions.setTasksLineItemByVehicleId(mergedTaskslineItemDBAndLocal));
          } else {
            yield put(tasksActions.setTasksLineItemByVehicleId(tasksData));
          }
        }
        // Update reassignments for task when call ReconTask api
        // On UI when click vehicle detail. we need update reassignments to store for vehicle.
        yield updateReconTasksChangeInVehicle(vehicleId, vehiclesList, tasksData);

        yield put(tasksActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
      } catch (error) {
        yield put(tasksActions.setFetchStatus(apiStatusConstants.FAILED));
        devLogger.log('error in getTaskByVehicleIdSaga', error);
      }
    }
  );
}

export function* getExportTaskDataSaga() {
  const now = new Date();
  const clientTimezoneOffset = now.getTimezoneOffset();
  yield takeLatest(TASKS.GET_EXPORT_DATA, function* ({ dealerId, start, filters, sort, minimumExecutionTime }) {
    try {
      const { isRootUser, vendorShipSelected, currentId } = yield select(rootEntitySwitcherSelector);
      if (isRootUser && vendorShipSelected && !currentId) {
        return;
      }
      yield put(tasksActions.setTasksExportFetchStatus(apiStatusConstants.IS_FETCHING));
      const exportedTaskData = yield executeWithMinimumTime(function* () {
        return yield getWithToken(`/api/ReconTasks/Export`, {
          ...filters,
          dealerId,
          start,
          sort,
          include: ['vehicle', 'location', 'comments', 'lineitems'],
          clientTimezoneOffset,
          vendorIdSelected: isRootUser && vendorShipSelected ? currentId : undefined
        });
      }, minimumExecutionTime);

      require('downloadjs')(
        exportedTaskData,
        `Export_Task_Data_${dateTimeWithFormat(now, 'YYYY_MM_DD')}.csv`,
        'text/csv'
      );
      yield put(tasksActions.setTasksExportFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      devLogger.log('error in getExportTaskDataSaga', error);
      yield put(tasksActions.setTasksExportFetchStatus(apiStatusConstants.FAILED));
      yield put(messagesActions.notify('error', EXPORT_FAILURE_MESSAGE, { duration: 3.5 }));
    }
  });
}

export function* getTasksSaga() {
  yield takeLatest(TASKS.GET_DATA, function* ({ dealerId, start, limit, filters, sort, minimumExecutionTime, flags }) {
    try {
      const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
      if (!dealerId && isRootUser && vendorShipSelected) {
        return;
      }
      if ('assignedToTechnicianFilter' in filters) {
        if (!filters.assignedToTechnicianFilter) {
          return;
        }
      }

      const params = {
        ...filters,
        dealerId: !isRootUser || !vendorShipSelected ? dealerId : undefined,
        start,
        limit,
        sort,
        include: ['vehicle', 'location', 'comments', 'LineItems'],
        vendorIdSelected: isRootUser && vendorShipSelected ? dealerId : undefined,
        clientTimezoneOffset: flags?.reconTimestampFilter ? new Date().getTimezoneOffset() : 0
      };

      yield put(tasksActions.setFetchStatus(apiStatusConstants.IS_FETCHING));
      const tasksData = yield executeWithMinimumTime(function* () {
        return yield getWithToken(`/api/ReconTasks`, params);
      }, minimumExecutionTime);

      //-Convert user tagging data if existed to display correctly
      tasksData.items.forEach((v) => {
        if (v.comments && v.comments.items) {
          v.comments.items.forEach((c) => {
            c.contentData = convertDataToDisplay(c.contentData);
          });
        }
      });
      yield put(tasksActions.setData(tasksData));
      yield put(tasksActions.setFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(tasksActions.setFetchStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTasksSaga', error);
    }
  });
}

export function* getTaskTimeInApprovalSaga() {
  yield takeLatest(TASKS.GET_TASK_TIME_IN_APPROVAL_LIST, function* ({ dealerId, entityId, entityType }) {
    try {
      yield put(tasksActions.setTimeInApprovalStatus(apiStatusConstants.IS_FETCHING));
      const taskTimeInApprovalList = yield getWithToken(`/api/Metrics/time-in-approval`, {
        dealerId,
        entityId,
        entityType
      });
      yield put(tasksActions.setTaskTimeInApprovalList(taskTimeInApprovalList));
      yield put(tasksActions.setTimeInApprovalStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      yield put(tasksActions.setTimeInApprovalStatus(apiStatusConstants.FAILED));
      devLogger.log('error in getTaskTimeInApprovalSaga', error);
    }
  });
}

export function* declineTaskSaga() {
  yield takeLatest(
    TASKS.DECLINE_TASK,
    function* ({ userId, taskId, declineReason, assignedToGroupId, assignedToGroupType }) {
      try {
        devLogger.log(`User ${userId} declining task ${taskId} for reason '${declineReason}'`);
        const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);

        //show that the task is saving an update
        yield put(tasksActions.updateTaskStatus(taskId, true));

        // call to api to decline the task
        const requestBody = { declinedBy: userId, reason: declineReason };
        if (assignedToGroupId && assignedToGroupType && !(isRootUser && vendorShipSelected)) {
          requestBody.declinedByGroup = assignedToGroupId;
          requestBody.declinedByGroupType = assignedToGroupType;
        }
        const taskResult = yield postWithToken(`/api/ReconTasks/id/${taskId}/decline`, requestBody);
        // get the current set of tasks in the store

        const data = { ...(yield select(taskDataSelector)) };
        // reset task in store with new value from api return value
        const itemIndex = data.items.findIndex((x) => x.id === taskResult.id);
        data.items[itemIndex] = taskResult;
        yield put(tasksActions.setData(data));

        // The taskResult above only return 2 comments, when a task is completed in the TDP, it needs the full comments, but outside of the TDP it doesn't, but theres not real good way to opt in since the complete task is abstracted away in a HOC
        yield put(tasksActions.getCommentsForTaskEntity(taskResult.vehicleId, taskResult.id));

        // Update users to update user workload for assignee of this declined task
        yield refreshUsers();
      } catch (error) {
        // TODO: handle error
        if (error.response) {
          yield put(messagesActions.notify('error', error?.response?.data?.message, { duration: 3.5 }));
        } else {
          devLogger.log('error in declineTask', error);
        }
        yield put(tasksActions.updateTaskStatus(taskId, false));
      }
    }
  );
}

export function* completeTaskSaga() {
  yield takeLatest(
    TASKS.COMPLETE_TASK,
    function* ({ taskId, completedCost, completionNote, assignedToGroupId, assignedToGroupType }) {
      try {
        //show that the task is saving an update
        yield put(tasksActions.updateTaskStatus(taskId, true));
        const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);
        // complete
        const requestBody = { completedCost, completionNote };
        if (assignedToGroupId && assignedToGroupType && !(isRootUser && vendorShipSelected)) {
          requestBody.completedByGroupId = assignedToGroupId;
          requestBody.completedByGroupType = assignedToGroupType;
        }
        const taskResult = yield postWithToken(`/api/ReconTasks/id/${taskId}/complete`, requestBody);

        // get the current set of tasks in the store
        const data = { ...(yield select(taskDataSelector)) };

        // reset task in store with new value from api return value
        const itemIndex = data.items.findIndex((x) => x.id === taskResult.id);
        data.items[itemIndex] = taskResult;
        yield put(tasksActions.setData(data));

        // The taskResult above only return 2 comments, when a task is completed in the TDP, it needs the full comments, but outside of the TDP it doesn't, but theres not real good way to opt in since the complete task is abstracted away in a HOC
        yield put(tasksActions.getCommentsForTaskEntity(taskResult.vehicleId, taskResult.id));

        // Update users to update user workload for assignee of this completed task
        yield refreshUsers();
      } catch (error) {
        // TODO: handle error
        if (error.response) {
          yield put(messagesActions.notify('error', error?.response?.data?.message, { duration: 3.5 }));
        } else {
          devLogger.log('error in completeTask', error);
        }
        yield put(tasksActions.updateTaskStatus(taskId, false));
      }
    }
  );
}

export function* updateTaskSaga() {
  yield takeLatest(TASKS.UPDATE_TASK, function* ({ taskId, task }) {
    try {
      devLogger.log(`Updating task ${taskId} - ${JSON.stringify(task)}`);

      //show that the task is saving an update
      yield put(tasksActions.updateTaskStatus(taskId, true));

      // update
      const taskBody = { ...task };
      delete taskBody.lineItems;
      const taskResult = yield putWithToken(`/api/ReconTasks/id/${taskId}`, taskBody);

      // get the current set of tasks in the store
      const data = { ...(yield select(taskDataSelector)) };

      // reset task in store with new value from api return value
      const itemIndex = data.items.findIndex((x) => x.id === taskResult.id);
      data.items[itemIndex] = taskResult;
      yield put(tasksActions.setData(data));

      // The taskResult above only return 2 comments, when a task is completed in the TDP, it needs the full comments, but outside of the TDP it doesn't, but theres not real good way to opt in since the complete task is abstracted away in a HOC
      yield put(tasksActions.getCommentsForTaskEntity(taskResult.vehicleId, taskResult.id));

      // Update users to update user workload for assignee of this updated task
      yield refreshUsers();
    } catch (error) {
      if (error.response) {
        yield put(messagesActions.notify('error', error?.response?.data?.message, { duration: 3.5 }));
      } else if (error.request) {
        devLogger.log(error.request);
      } else {
        devLogger.log('error in updateTask', error?.response?.data?.message);
      }
      yield put(tasksActions.updateTaskStatus(taskId, false));
    }
  });
}

export function* updateReconPlanSaga() {
  yield takeEvery(
    TASKS.UPDATE_RECON_PLAN,
    function* ({ vehicleId, reconPlan, loadingMessage, successMessage, errorMessage }) {
      const loadingMessageId = uuidv4();
      try {
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0 })); // duration of 0 will show the message indefinitely

        const modifiedReconPlan = stripReferences(reconPlan);
        modifiedReconPlan.forEach((t) => {
          if (t.transientType === 'add') {
            t.id = null; // remove id from task that are being added
          }
        });
        yield putWithToken(`/api/ReconTasks/vehicleId/${vehicleId}`, modifiedReconPlan);

        // Now that all the tasks have been created if need be, complete the one that was just marked as completed
        // This is to handle the case where the user just added this task, and marked it as complete, without saving in between
        for (const task of modifiedReconPlan.filter((t) => t.transientType === 'complete')) {
          const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);

          const requestBody = {
            completedCost: task.completedCost,
            completionNote: task.completionNote
          };
          if (task.assignedToGroupId && task.assignedToGroupType && !(isRootUser && vendorShipSelected)) {
            requestBody.completedByGroupId = task.assignedToGroupId;
            requestBody.completedByGroupType = task.assignedToGroupType;
          }
          yield postWithToken(`/api/ReconTasks/id/${task.id}/complete`, requestBody);
        }

        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // passing in the same key and duration will make the message go away after that duration time
        yield put(messagesActions.notify('success', successMessage, { duration: 2 }));
        yield put(vehiclesActions.getVehicleById(vehicleId)); // refetch the vehicle to update the reconStatus);

        // Update users to update user workload for assignees in this recon plan
        yield refreshUsers();
      } catch (error) {
        devLogger.log('failed updateReconPlanSaga', error);
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // need to turn off loading before the error, because I'm latching on the messageType for isSaving prop in Vehicles.js
        yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
      }
    }
  );
}

export function* updateFinalCostForTaskSaga() {
  yield takeEvery(TASKS.REFRESH_FINAL_COST_FOR_TASK, function* ({ taskId, completedCost }) {
    try {
      yield putWithToken(`/api/ReconTasks/id/${taskId}/completedCost`, { completedCost: completedCost });
    } catch (error) {
      // TODO: handle error
      devLogger.log('error in updateFinalCostForTaskSaga', error);
    }
  });
}

export function* updateReconPlanSagaWithGroups() {
  yield takeEvery(
    TASKS.UPDATE_RECON_PLAN_WITH_GROUPS,
    function* ({
      vehicleId,
      reconPlan,
      newlyCompletedTasks,
      loadingMessage,
      successMessage,
      errorMessage,
      vehiclesList = {},
      reconTimestampFilterFlag,
      reconHiddenFilterFlag,
      hiddenStatusQuery
    }) {
      const loadingMessageId = uuidv4();
      try {
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0 })); // duration of 0 will show the message indefinitely

        // start calc data to patch line items vehicle
        let listRequestTasks = [];
        let listResponseTasks = [];

        reconPlan.taskGroups.forEach((taskGroup, index) => {
          taskGroup.sequence = taskGroup.sequence ? taskGroup.sequence : index + 1;

          taskGroup.tasks.forEach((task) => {
            if (task.lineItems && task.lineItems.items) {
              task.lineItems = [...task.lineItems.items];
            }
            listRequestTasks.push({ ...task });

            // remove line item on body request update vehicle
            delete task.lineItems;
          });
        });

        listRequestTasks.forEach((task, index) => {
          task.sequence = index + 1;
        });

        const newReconPlan = yield putWithToken(`/api/ReconPlans/vehicleId/${vehicleId}`, reconPlan);

        newReconPlan.reconTaskGroups.items.forEach((taskGroup) => {
          taskGroup.reconTasks.items.forEach((task) => {
            listResponseTasks.push(task);
          });
        });

        let bodyRequestPathLineItems = [];
        if (Array.isArray(listRequestTasks)) {
          listRequestTasks.forEach((reqTask) => {
            if (!reqTask.id) {
              const taskTemp = listResponseTasks.find((resTask) => resTask.sequence === reqTask.sequence);
              let sourceLineItems = reqTask.isAppliedLineItemsTemplate
                ? reqTask.lineItems
                : reqTask.lineItemTemplates.items;
              if (taskTemp && Array.isArray(sourceLineItems)) {
                sourceLineItems.forEach((item) => {
                  bodyRequestPathLineItems.push({
                    op: OPERATION_LINE_ITEM.ADD,
                    value: {
                      entityId: taskTemp.id,
                      entityType: item.entityType,
                      status: item.status,
                      description: item.description,
                      laborRate: item.laborRate || 0,
                      laborTime: item.laborTime || 0,
                      laborCost: item.laborCost,
                      partsCost: item.partsCost,
                      totalCost: item.totalCost
                    }
                  });
                });
              }
            } else if (reqTask.isChangeReconTaskType) {
              const taskTemp = listResponseTasks.find((resTask) => resTask.id === reqTask.id);
              if (taskTemp && Array.isArray(reqTask.lineItems)) {
                reqTask.lineItems.forEach((item) => {
                  bodyRequestPathLineItems.push({
                    op: OPERATION_LINE_ITEM.ADD,
                    value: {
                      entityId: taskTemp.id,
                      entityType: item.entityType,
                      status: item.status,
                      description: item.description,
                      laborRate: item.laborRate || 0,
                      laborTime: item.laborTime || 0,
                      laborCost: item.laborCost,
                      partsCost: item.partsCost,
                      totalCost: item.totalCost
                    }
                  });
                });
              }

              if (taskTemp && Array.isArray(reqTask.lineItemsBeforeChangeTaskType.items)) {
                reqTask.lineItemsBeforeChangeTaskType.items.forEach((item) => {
                  bodyRequestPathLineItems.push({
                    op: OPERATION_LINE_ITEM.REMOVE,
                    path: item.id,
                    value: {
                      entityId: taskTemp.id,
                      entityType: item.entityType,
                      id: item.id,
                      status: item.status,
                      description: item.description,
                      laborRate: item.laborRate || 0,
                      laborTime: item.laborTime || 0,
                      laborCost: item.laborCost,
                      partsCost: item.partsCost,
                      totalCost: item.totalCost
                    }
                  });
                });
              }
            }
          });
        }
        if (!!bodyRequestPathLineItems.length) {
          yield patchWithToken(`/api/LineItems`, bodyRequestPathLineItems);
        }

        // end calc data to patch line items vehicle

        yield put(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.INITIAL));

        // Now that all the tasks have been created if need be, complete the one that was just marked as completed
        // This is to handle the case where the user just added this task, and marked it as complete, without saving in between
        for (const task of newlyCompletedTasks) {
          const { isRootUser, vendorShipSelected } = yield select(rootEntitySwitcherSelector);

          const requestBody = {
            completedCost: task.completedCost,
            completionNote: task.completionNote,
            lineItems: task.lineItems
          };
          if (task.assignedToGroupId && task.assignedToGroupType && !(isRootUser && vendorShipSelected)) {
            requestBody.completedByGroupId = task.assignedToGroupId;
            requestBody.completedByGroupType = task.assignedToGroupType;
          }
          yield postWithToken(`/api/ReconTasks/id/${task.id}/complete`, requestBody);
        }

        const params = {
          include: ['LineItems'],
          vehicleId,
          limit: 99999
        };

        if (reconTimestampFilterFlag) {
          params.clientTimezoneOffset = new Date().getTimezoneOffset();
        }

        if (reconHiddenFilterFlag && hiddenStatusQuery?.length > 0) {
          params.hiddenStatus = ['IncludeHidden'];
        }

        const tasksData = yield getWithToken(`/api/ReconTasks`, params);

        yield put(tasksActions.setTasksLineItemByVehicleId(tasksData));

        // Update reassignments for task when call ReconTask api
        // On UI when update vehicle. we need update reassignments to store for vehicle.
        yield updateReconTasksChangeInVehicle(vehicleId, vehiclesList, tasksData);

        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // passing in the same key and duration will make the message go away after that duration time
        yield put(messagesActions.notify('success', successMessage, { duration: 2 }));
        yield put(vehiclesActions.getVehicleById(vehicleId, false, false, false, MaxIntegerNumber, null)); // refetch the vehicle to update the reconStatus

        // Update users to update user workload for assignees in this recon plan
        yield refreshUsers();

        const dealer = yield select(currentDealerSelector);
        const dealerId = dealer?.data?.id;
        // refetch assignee for newest workload after saving vehicle
        if (dealerId) {
          yield put(usersActions.getAssigneeData(dealerId));
        }
      } catch (error) {
        devLogger.log('failed updateReconPlanSaga', error);
        yield put(messagesActions.notify('loading', loadingMessage, { key: loadingMessageId, duration: 0.1 })); // need to turn off loading before the error, because I'm latching on the messageType for isSaving prop in Vehicles.js

        const errorData = error.response?.data;
        if (errorData && errorData.message) {
          yield put(messagesActions.notify('error', errorData.message, { duration: 3.5 }));
        } else {
          yield put(messagesActions.notify('error', errorMessage, { duration: 3.5 }));
        }
      }
    }
  );
}

export function* getCommentsForTaskEntitySaga() {
  yield takeEvery(TASKS.GET_COMMENTS_FOR_TASK_ENTITY, function* ({ vehicleId, reconTaskId }) {
    try {
      yield put(tasksActions.setCommentsFetchStatus(apiStatusConstants.IS_FETCHING));
      // yield put(tasksActions.setCommentsFetchStatus(reconTaskId, apiStatusConstants.IS_FETCHING));
      const comments = yield getWithToken(`/api/Comments`, {
        vehicleId,
        reconTaskId,
        start: 1,
        limit: MaxIntegerNumber
      });
      if (comments && comments.items) {
        comments.items.forEach((i) => {
          i.contentData = convertDataToDisplay(i.contentData);
        });
      }
      yield put(tasksActions.setComments(reconTaskId, comments)); // this action also sets the communicationsFetchStatus to succeeded in the reducer
      yield put(tasksActions.setCommentsFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      // TODO: handle error
      yield put(tasksActions.setCommentsFetchStatus(reconTaskId, apiStatusConstants.FAILED));
      devLogger.log('error in getCommentsForTaskEntitySaga', error);
    }
  });
}

export function* addCommentFromTasksStoreSaga() {
  yield takeEvery(
    TASKS.ADD_COMMENT_FROM_TASKS_STORE,
    function* ({ reconTaskId, comment, images = [], successMessage = '', locationAction = '' }) {
      try {
        yield put(vehiclesActions.setShowLoadingSendComment(true));
        yield put(vehiclesActions.setLocationAction(locationAction));

        const tempComment = !!comment.contentData
          ? {
              ...comment,
              contentData: convertDataToSend({
                ...comment.contentData,
                blocks: trimLineBreakBlocks(comment.contentData.blocks)
              })
            }
          : { ...comment };

        const addedComment = yield postWithToken(`/api/Comments`, tempComment);
        if (images?.length > 0) {
          let imageArray = [];
          for (let file of images) {
            const addedCommentImage = yield postFileWithToken(`/api/CommentImages/comment/${addedComment.id}`, file);
            imageArray = [...imageArray, addedCommentImage];
          }
          addedComment.images = [...imageArray];
        }
        addedComment.contentData = convertDataToDisplay(addedComment.contentData);
        yield put(tasksActions.addComment(reconTaskId, addedComment));
        yield put(
          messagesActions.notify('success', successMessage, {
            duration: 2,
            location: communicationContexts.COMMUNICATION_BUTTON_NOTE
          })
        );
        yield put(vehiclesActions.setShowLoadingSendComment(false));
      } catch (error) {
        // TODO: handle error
        yield put(messagesActions.notify('error', 'An error occurred while adding comment', { duration: 3.5 }));
        yield put(vehiclesActions.setShowLoadingSendComment(false));
      }
    }
  );
}

export function* updateCommentFromTasksStoreSaga() {
  yield takeEvery(
    TASKS.UPDATE_COMMENT_FROM_TASKS_STORE,
    function* ({ reconTaskId, comment, locationAction = '', index = -1 }) {
      try {
        yield put(vehiclesActions.setShowLoadingSendComment(true));
        yield put(vehiclesActions.setLocationAction(locationAction));
        yield put(vehiclesActions.setIndexCommunication(index));

        const tempComment = !!comment.contentData
          ? {
              ...comment,
              contentData: convertDataToSend({
                ...comment.contentData,
                blocks: trimLineBreakBlocks(comment.contentData.blocks)
              })
            }
          : { ...comment };

        const updatedComment = yield putWithToken(`/api/Comments/id/${comment.id}`, tempComment);
        updatedComment.contentData = convertDataToDisplay(updatedComment.contentData);
        yield put(tasksActions.updateComment(reconTaskId, updatedComment));
        yield put(
          messagesActions.notify('success', 'Updated message successfully!', {
            duration: 2,
            location: communicationContexts.COMMUNICATION_BUTTON_NOTE
          })
        );
        yield put(vehiclesActions.setShowLoadingSendComment(false));
        yield put(vehiclesActions.setIndexCommunication(-1));
      } catch (error) {
        // TODO: handle error
        yield put(messagesActions.notify('error', 'An error occurred while updating message', { duration: 3.5 }));
        yield put(vehiclesActions.setShowLoadingSendComment(false));
      }
    }
  );
}

export function* deleteCommentFromTasksStoreSaga() {
  yield takeEvery(
    TASKS.DELETE_COMMENT_FROM_TASKS_STORE,
    function* ({ reconTaskId, commentId, successMessage = '', loadingMessage = '' }) {
      const messageId = uuidv4();
      yield put(
        messagesActions.notify('loading', loadingMessage, {
          duration: 0,
          key: messageId,
          location: communicationContexts.COMMUNICATION_BUTTON_NOTE
        })
      );
      try {
        yield put(vehiclesActions.setShowLoadingSendComment(true));
        yield deleteWithToken(`/api/Comments/id/${commentId}`);
        yield put(tasksActions.deleteComment(reconTaskId, commentId));
        yield put(vehiclesActions.setShowLoadingSendComment(false));
        yield put(
          messagesActions.notify('success', successMessage, {
            duration: 2,
            key: messageId,
            location: communicationContexts.COMMUNICATION_BUTTON_NOTE
          })
        );
      } catch (error) {
        // TODO: handle error
        yield put(messagesActions.notify('error', 'An error occurred while deleting message', { duration: 3.5 }));
        yield put(vehiclesActions.setShowLoadingSendComment(false));
      }
    }
  );
}

export const getCountLineItemByTaskIdSelector = (taskId) => {
  return createSelector(
    (state) => state.tasks.lineItemTasks,
    (lineItemTasks) => {
      let needsApproval = [];
      let approved = [];
      let declined = [];
      let needsApprovalEstimateRequest = [];
      const lineItemsTask = lineItemTasks.items ? lineItemTasks.items.find((item) => item.id === taskId) : {};

      if (lineItemsTask?.lineItems) {
        const { lineItems } = lineItemsTask;

        if (lineItems.items?.length > 0) {
          needsApprovalEstimateRequest = lineItems.items.filter((item) =>
            [lineItemStatusTypes.PENDING, lineItemStatusTypes.ESTIMATE_REQUESTED].includes(item.status)
          );
          needsApproval = lineItems.items.filter((item) => [lineItemStatusTypes.PENDING].includes(item.status));
          approved = lineItems.items.filter((item) => [lineItemStatusTypes.APPROVED].includes(item.status));
          declined = lineItems.items.filter((item) => [lineItemStatusTypes.DECLINED].includes(item.status));
        }
      }
      return {
        needsApproval: needsApproval.length,
        declined: declined.length,
        approved: approved.length,
        disableCompleteTask: needsApprovalEstimateRequest.length
      };
    }
  );
};

export const getLineItemByTaskIdSelector = (taskId) => {
  return createSelector(
    (state) => state.tasks?.lineItemTasks,
    (lineItemTasks) => {
      const lineItemsTask = lineItemTasks.items ? lineItemTasks.items.find((item) => item.id === taskId) : {};
      if (lineItemsTask) {
        const { lineItems } = lineItemsTask;
        return lineItems;
      } else {
        return {
          count: 0,
          items: []
        };
      }
    }
  );
};

export const getPendingApprovalTimeByTaskIdSelector = (taskId) => {
  return createSelector(
    (state) => state.tasks.taskTimeInApprovalList,
    (taskTimeInApprovalList) => {
      const currTask = taskTimeInApprovalList ? taskTimeInApprovalList.find((item) => item.entityId === taskId) : {};
      return currTask?.timeInApproval;
    }
  );
};

export function* getDependencyDataFromTasksStoreSaga() {
  yield takeEvery(TASKS.GET_DEPENDENCY_DATA, function* ({ dealerId, isVendorOrRoot }) {
    try {
      if (!dealerId) {
        return;
      }

      yield put(usersActions.getAssigneeData(dealerId));
      // after the internal group toggle removed, use data of API assignee instead of API users and remove usersActions.getData
      yield put(usersActions.getData(dealerId, false));

      // this need to be done before tasktype
      // if any of these action fail, pass out and get tasktype to avoid blocking
      yield race([take(USERS.SET_FETCH_STATUS), all([take([USERS.SET_ASSIGNEE_DATA]), take([USERS.SET_DATA])])]);

      yield put(taskTypesActions.getData(dealerId));

      if (!isVendorOrRoot) {
        yield put(taskCategoriesActions.getDataFromTaskType(dealerId));
      }
    } catch (error) {
      yield put(messagesActions.notify('error', 'An error occurred while get dependency data', { duration: 3.5 }));
    }
  });
}

//#endregion

//#region Helpers
export function* refreshUsers() {
  const dealer = yield select(currentDealerSelector);
  const dealerId = dealer?.data?.id;
  yield put(usersActions.getData(dealerId));
}

export function* updateReconTasksChangeInVehicle(vehicleId, vehiclesList = {}, tasksData = []) {
  var updatedVehicleList = { ...vehiclesList };
  if (updatedVehicleList?.items?.length > 0) {
    updatedVehicleList.items = vehiclesList.items.map((vehicle) => {
      // Update recon tasks lastest for vehicle selected
      if (vehicle.id === vehicleId && tasksData.items.length > 0) {
        vehicle.tasks.items = tasksData.items;
      }
      return vehicle;
    });
    yield put(vehiclesActions.setData(updatedVehicleList));
  }
}

export function* getDealershipForVendor() {
  yield takeLatest([TASKS.GET_DEALERSHIP_FOR_VENDOR], function* ({ vendorId }) {
    try {
      yield put(tasksActions.setDealershipsFetchStatus(apiStatusConstants.IS_FETCHING));
      const dealerships = yield getWithToken(`/api/Vendors/id/${vendorId}/dealer-task-types`);
      if (!!dealerships) {
        yield put(tasksActions.setDealerships(dealerships ?? []));
      }
      yield put(tasksActions.setDealershipsFetchStatus(apiStatusConstants.SUCCEEDED));
    } catch (error) {
      devLogger.log('error in getDealershipForVendor', error);
      yield put(tasksActions.setDealershipsFetchStatus(apiStatusConstants.FAILED));
    }
  });
}
//#endregion
