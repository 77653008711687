import { communicationContexts, detailContents, features } from 'app-constants';
import { COMPLETED, DECLINED, IN_PROGRESS } from 'app-constants/taskStatusTypes';
import { DrawerContainer, DropdownList } from 'components';
import { FullCommunications, withCommunications } from 'components/communications';
import { ReconPlan, TaskForm } from 'components/layout/inventory/reconPlan';
import { VehicleDetails } from 'components/layout/inventory/vehicleDetails';
import { CommonHeaderLabel } from 'components/styledComponents';
import {
  getInProgressStep,
  isInProgressTaskGroup,
  isTaskComplete,
  isTaskGroupCompleted,
  isTaskGroupIncomplete,
  isTaskIncomplete
} from 'components/TaskGroupsDnd/helpers/functions';
import { TASK_GROUP } from 'components/TaskGroupsDnd/helpers/types';
import { useTimeoutBoolean, useFeatures } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOCATION_MESSAGE_LINE_ITEM } from 'app-constants/lineItemConstants';
import { appraisalInfoActions } from 'store/appraisalInfoStore';
import { messagesActions, messageSelector } from 'store/messagesStore';
import { tasksActions } from 'store/tasksStore';
import { vdpActions } from 'store/vdpStore';
import { stripReferences } from 'utils/arrayUtils';
import uuidv4 from 'uuid/v4';
import { convertTaskGroupsFromSpecificToGeneric, prepareTaskGroupsAfterTaskDeletion } from './functions';
import { NEW } from '../../../../app-constants/reconPlanStatusTypes';
import { vdpSelector } from '../../../../store/vdpStore';

const FullCommunicationsFromVehiclesStore = withCommunications('vehiclesStore')(FullCommunications);

export const ContentSwitcher = ({
  reconPlanFeatures,
  initialFocus,
  closeDrawer,
  closeForm,
  isSaving,
  setIsSaving,
  showDiscardButton,
  setShowDiscardButton,
  scrollToId,
  setScrollToId,
  formProps,
  setFormProps,
  handleCanClose,
  handleCanOpenVDP,
  reset,
  setTemplatePlanCreatedFrom,
  templatePlanCreatedFrom,
  onTemplatePlanChosen,
  taskGroups,
  setTaskGroups,
  refTransientCommunication,
  flags
}) => {
  const { vehicle, contentType, originalTaskGroups, comments, vehiclesList } = useSelector(vdpSelector);
  const { id: vehicleId, tasks, reconPlan, reconStatus } = vehicle || {};
  const message = useSelector(messageSelector);
  const dispatch = useDispatch();
  const [planStarted, setPlanStarted] = useState(false);
  const drawerReady = useTimeoutBoolean(1500, false, true);
  const [hasEditTasks] = reconPlanFeatures;
  const [hasLineItemsEditRole] = useFeatures(features.LINE_ITEMS_EDIT);
  const showTaskForm = (hasEditTasks || !hasLineItemsEditRole) && !!formProps;
  const [showSeeMoreReply, setShowSeeMoreReply] = useState({});
  const [isSavingNote, setIsSavingNote] = useState(false);
  const planTemplates = useSelector((state) => state.planTemplates);
  const lineItemsOfTask = useSelector((state) => state.tasks?.lineItemTasks);

  //#region quick hide
  useEffect(() => {
    // Here I'm latching on to the messages to determine if the save has succeeded or not, which I'm using to determine whether to close the drawer or not.
    // Longer term, latching on to the messages may not be viable if messages start being used more asynchronously
    if (message) {
      const { messageType, options } = message;
      if (messageType === 'loading') {
        if ('location' in options && options.location === communicationContexts.COMMUNICATION_BUTTON_NOTE) {
          setIsSavingNote(true);
        } else {
          setIsSaving(true);
        }
      } else {
        setIsSaving(false);
        setIsSavingNote(false);
      }
      if (messageType === 'success') {
        dispatch(messagesActions.clear());
        if (
          ![
            communicationContexts.COMMUNICATION_BUTTON_NOTE,
            communicationContexts.DOCUMENT_SUCCESS,
            LOCATION_MESSAGE_LINE_ITEM
          ].includes(options?.location)
        ) {
          reset();
          if (
            [detailContents.RECON_PLAN, detailContents.CREATE_RECON_PLAN, detailContents.VEHICLE_DETAILS].includes(
              contentType
            )
          ) {
            dispatch(vdpActions.setOriginalTaskGroups([]));
          }
          if ([detailContents.RECON_PLAN, detailContents.CREATE_RECON_PLAN].includes(contentType)) {
            //no need to close vdp if the message content is from archiving or excluding a vehicle (vdp is not opened if that is the case)
            //need to find a way to check if vdp is opened for a more efficient fix
            if (message.content && !checkIfArchivedOrExcluded(message.content)) {
              closeDrawer();
            }
          }
        }
      }
    }
  }, [message, contentType]);

  const checkIfArchivedOrExcluded = (content) => {
    return (
      content === 'Vehicle unarchived!' ||
      content === 'Vehicle unexcluded!' ||
      content === 'Vehicle archived!' ||
      content === 'Vehicle excluded!'
    );
  };

  useEffect(() => {
    if (reconPlan?.reconTaskGroups) {
      /**
       * TODO: clean this up when flags are removed - should no longer depend on tasks or tasksWithTransientTasks
       * tasks comes from the vehicle data object retrieved from the API
       * a plan has started if tasks.count is greater than 0
       * you can't save a plan without starting the plan, so if there are tasks, then the plan has started
       **/
      setPlanStarted(reconStatus === IN_PROGRESS);
      // if contentType is set to CREATE_RECON_PLAN and there are tasks in tasks.items (which are the tasks saved and present in the vehicle data saved in the redux store) this is only possible if there are no tasks pending, only completed - contentType can only be CREATE_RECON_PLAN when no tasks present
      (contentType !== detailContents.CREATE_RECON_PLAN ||
        (contentType === detailContents.CREATE_RECON_PLAN && tasks.items.length > 0)) &&
        dispatch(vdpActions.setTasksWithTransientTasks(stripReferences(tasks.items))); //tasks.items is an object array with simple data structures (strings and numbers) - we can deep clone this using the stripReferences util function
    }

    if (reconPlan?.reconTaskGroups?.items) {
      // reconPlan is from the vehicle object which is from the API - only want to load from this if taskGroups isn't already populated and only if generalizedTaskGroups is for a plan that has already started
      const generalizedTaskGroups = convertTaskGroupsFromSpecificToGeneric(reconPlan.reconTaskGroups.items);
      setPlanStarted(generalizedTaskGroups.length > 0 ? true : false);
      //only want to load from saved vehicle plan, if there was one
      //otherwise, accept an empty task group or if a template plan was loaded, accept the tasks from the template plan
      setTaskGroups(generalizedTaskGroups.length > 0 ? [...generalizedTaskGroups] : taskGroups);
      dispatch(vdpActions.setOriginalTaskGroups([...generalizedTaskGroups]));
      setShowDiscardButton(false);
    } else {
      dispatch(vdpActions.setOriginalTaskGroups([]));
    }
  }, [tasks, reconPlan, contentType, vehicleId]);

  // #region retrieve appraisal info popover data
  // getting the data that will display in the appraisal info popover
  // the reason why this should be done here is because the popover displays data on both the task form and the recon plan
  // if we are fetching the data for every time that the appraisal popover is rendered, it is pretty choppy looking because the popover adjusts its size each time
  // even going to add a new task can be distracting
  useEffect(() => {
    dispatch(appraisalInfoActions.getData(vehicleId));
  }, [appraisalInfoActions.getData, vehicleId]); // while appraisalInfoActions.getData will never change, it is a dependency and thus eslint will throw a warning if it is not in the dependency array

  useEffect(() => {
    const hiddenStatusQuery = JSON.parse(localStorage.getItem('vehicleQuery') ?? '{}').hiddenStatus;
    dispatch(
      tasksActions.getTasksLineItemByVehicleId(
        vehicleId,
        { ...lineItemsOfTask },
        {
          ...vehiclesList
        },
        flags?.reconTimestampFilter,
        flags.reconHiddenFilter,
        hiddenStatusQuery
      )
    );
  }, [vehicleId]);
  // #endregion

  const handleSetShowSeeMoreReply = (id, isShow = true) => {
    id
      ? setShowSeeMoreReply({
          ...showSeeMoreReply,
          [id]: isShow,
          currentId: id
        })
      : setShowSeeMoreReply({
          ...showSeeMoreReply,
          currentId: null
        });
  };
  const getHeaderLabel = () => {
    if (formProps?.isMessageTask) return 'Messages';
    if (formProps?.isLineItemsTask) return 'Line Items';
    if (!formProps?.taskToUpdate) return 'Add Task';
    if (formProps?.onComplete) return 'Complete Task';
    if (formProps?.taskToUpdate?.status !== COMPLETED) return 'Update Task';
    if (formProps?.taskToUpdate?.status === COMPLETED) return 'Edit Completed Task';
  };

  const onTaskChangeWithTaskGroups = (type) => (task, currentTasks) => {
    switch (type) {
      case 'add': {
        // right now, there is not an ability to directly add a task to a task group, so these will be single tasks added
        const newGroupId = uuidv4();
        //determine if newly added task is in the in progress position - find if there are already existing in progress tasks
        const inProgressTaskIndex = currentTasks.findIndex((taskGroup) => {
          return taskGroup.tasks.some((task) => task.declined || [IN_PROGRESS, DECLINED].includes(task.status));
        });
        const earlierStepIsInProgressTask = inProgressTaskIndex > -1;

        //check if the lask task is pass through. If yes, the newly created task's progress is also "in progress"
        let lastTaskGroup = currentTasks && currentTasks[currentTasks.length - 1]?.tasks;
        let isLastTaskPassThrough =
          lastTaskGroup &&
          lastTaskGroup[lastTaskGroup.length - 1]?.passthrough &&
          lastTaskGroup[lastTaskGroup.length - 1]?.status === IN_PROGRESS;

        const computedTaskGroups = [
          ...currentTasks,
          {
            // although these are single tasks, we still need to create a new task group to add to the plan for this new task
            id: newGroupId,
            name: 'Task Group',
            modelType: TASK_GROUP,
            vehicleId: vehicleId,
            sequence: currentTasks.length + 1,
            tasks: [
              {
                ...task,
                status: earlierStepIsInProgressTask && !isLastTaskPassThrough ? undefined : IN_PROGRESS, //if task.status === 'IN_PROGRESS' couldn't be found in existing task groups, then make this task group in progress
                groupSequence: 1,
                taskGroupId: newGroupId
              }
            ]
          }
        ];

        setTaskGroups(computedTaskGroups);
        break;
      }
      case 'update':
      case 'complete': {
        // Sequence won't have changed - this was triggered from the task's overflow menu

        // we are modifying a task group's tasks, so we will be pulling that task group out to modify it
        let indexOfGroup = taskGroups.findIndex((tg) => tg.id === task.taskGroupId);
        // we will be overriding the current task data with the new task data, so get the index of the task in the task group so that we can replace it
        let indexOfTask = indexOfGroup === -1 ? -1 : taskGroups[indexOfGroup].tasks.findIndex((t) => t.id === task.id);
        if (indexOfTask > -1) {
          // create mutable task
          const modifiableTask = stripReferences(task);
          // if the type of action performed on the task was to complete the task, update its status so that it looks complete immediately
          if (type === 'complete') {
            modifiableTask.status = COMPLETED;
          }

          /*
           * Strip references so children components will actually detect a change.
           * Otherwise, the references doesn't change and useEffects won't pick up any changes,
           * and children components won't re-render!
           */
          const newGroups = stripReferences(taskGroups);
          const updatedGroup = taskGroups[indexOfGroup] && stripReferences(taskGroups[indexOfGroup]);
          updatedGroup.tasks.splice(indexOfTask, 1, modifiableTask);
          newGroups[indexOfGroup] = updatedGroup;

          // additionally, since complete action can only happen on the in progress task/task group, then we need to check to see if there are any updates needed for the in progress task
          if (type === 'complete') {
            /**
             * if the completed task is in a task group, then there's a potential that there are other tasks in the task group that still need to be updated
             * in this case, no updates are needed to the in progress task
             * however, if the completed task was the only task left in the group that wasn't completed, then need to switch to the next task group to be in progress
             **/
            const isTaskGroupComplete = isTaskGroupCompleted(newGroups[indexOfGroup]);

            if (isTaskGroupComplete) {
              // if the task group is complete, then check if it is the last task group in the plan. if it isn't, then update the next one to be in progress
              if (indexOfGroup + 1 !== newGroups.length) {
                // checking if the next index after the modified group isn't equal to the length of newGroups checks if there are any task groups after the one that was just modified
                newGroups[indexOfGroup + 1].tasks = newGroups[indexOfGroup + 1].tasks.map((task) => {
                  return {
                    ...task,
                    status: task.declined
                      ? DECLINED
                      : task.status !== COMPLETED && task.status !== DECLINED
                      ? IN_PROGRESS
                      : task.status
                  };
                });
              }
            }

            //finally, sort the tasks again, so that completed tasks come before incomplete tasks
            //sort at step level
            const completedTaskGroups = stripReferences(newGroups?.filter(isTaskGroupCompleted));
            const incompleteTaskGroups = stripReferences(newGroups?.filter(isTaskGroupIncomplete));
            const taskGroupsSortedByCompleteStatus = [...completedTaskGroups, ...incompleteTaskGroups];
            //sort at task group level
            const taskGroupsSortedWithGroupsSortedByCompleteStatus = taskGroupsSortedByCompleteStatus.map(
              (taskGroup) => {
                const completedTasks = taskGroup?.tasks?.filter(isTaskComplete);
                const incompleteTasks = taskGroup?.tasks?.filter(isTaskIncomplete);
                return {
                  ...taskGroup,
                  tasks: [...completedTasks, ...incompleteTasks]
                };
              }
            );
            setTaskGroups(taskGroupsSortedWithGroupsSortedByCompleteStatus);
          } else {
            setTaskGroups(newGroups);
          }
        }
        break;
      }
      case 'delete': {
        let indexOfGroup = taskGroups.findIndex((taskGroup) => taskGroup.id === task.taskGroupId);
        if (indexOfGroup !== -1) {
          const newGroups = stripReferences(taskGroups);
          const groupToUpdate = taskGroups[indexOfGroup] && stripReferences(taskGroups[indexOfGroup]);
          const updatedGroup = {
            ...groupToUpdate,
            tasks: groupToUpdate.tasks.filter((t) => t.id !== task.id)
          };
          if (updatedGroup.tasks.length === 0) {
            // if after removing this task from the group, there are no more tasks in the group, then delete the task group from the plan
            newGroups.splice(indexOfGroup, 1);
          } else {
            // if there are remaining tasks, then replace the existing group in taskGroups with the updated one
            newGroups[indexOfGroup] = updatedGroup;
          }

          // set status Task Deleted for Comments
          dispatch(vdpActions.setCommentsTaskIsDeleted(task.id));

          /**
           * was deleted task in progress?
           * not enough to check if the task had in progress status. a task can be in the in progress position, but have a status of DECLINED
           * need to look at where the last completed task was and what came after it
           * the deleted task was not a completed task because you cannot delete completed tasks
           * we have the index of this task, and we can compare where the in progress position would be against the index of this task
           **/

          const indexOfFirstIncompleteTaskGroupInPreviousTaskGroups = taskGroups.findIndex(isTaskGroupIncomplete); // will return first instance of where a task in a task group is incomplete
          const deletedTaskWasAtInProgressPosition =
            indexOfFirstIncompleteTaskGroupInPreviousTaskGroups === indexOfGroup;

          prepareTaskGroupsAfterTaskDeletion(
            setTaskGroups,
            newGroups,
            indexOfGroup,
            updatedGroup,
            deletedTaskWasAtInProgressPosition,
            flags
          );
        }
        break;
      }
      default:
        break;
    }
  };

  //#region applying template management
  const [showCreateReconPlanFromTemplateButton, toggleShowCreateReconPlanFromTemplateButton] = useState(true);

  useEffect(() => {
    // Only show the create using template button when there are no task groups
    /*
     * For US1004858: Disable the ability to create a plan/task on a new status vehicle in iRecon
     * Check Inventory Type is not a NEW vehicle
     */
    toggleShowCreateReconPlanFromTemplateButton(taskGroups.length === 0 && vehicle.inventoryType !== NEW);
  }, [taskGroups.length]);

  useEffect(() => {
    return () => {
      dispatch(tasksActions.resetTasksLineItemByVehicleId());
    };
  }, []);

  const TemplateDropdownMenu = () => (
    <DropdownList
      dropdownList={planTemplates}
      onClick={(template) => {
        onTemplatePlanChosen(template);
      }}
    />
  );
  //#endregion

  const determineContent = (contentType) => {
    const { RECON_PLAN, CREATE_RECON_PLAN, VEHICLE_DETAILS, COMMUNICATIONS } = detailContents;
    switch (contentType) {
      case RECON_PLAN:
      case CREATE_RECON_PLAN:
        return (
          <DrawerContainer
            headerLabel={<CommonHeaderLabel>{!showTaskForm ? 'Recon Plan' : getHeaderLabel()}</CommonHeaderLabel>}
            onClose={() => handleCanClose()}
            vehicle={vehicle}
            showPhoto={formProps ? false : true}
            showBackButton={!!formProps}
            handleBackButtonClick={() => closeForm()}
            isSaving={isSaving}
            contentStyle={{ marginTop: '24px' }}
            onHeaderClick={() => handleCanOpenVDP(true)}
            isLineItemsTask={formProps?.isLineItemsTask}
          >
            {!showTaskForm ? (
              <ReconPlan
                reconPlanFeatures={reconPlanFeatures}
                drawerReady={drawerReady}
                vehicle={vehicle}
                tasks={tasks}
                closeDrawer={closeDrawer}
                setFormProps={setFormProps}
                showDiscardButton={showDiscardButton}
                scrollToId={scrollToId}
                handleCanClose={handleCanClose}
                isSaving={isSaving}
                taskGroups={taskGroups}
                setTaskGroups={setTaskGroups}
                originalTaskGroups={originalTaskGroups}
                planStarted={planStarted}
                onTaskChange={onTaskChangeWithTaskGroups}
                templateDropdownMenu={TemplateDropdownMenu}
                planTemplates={planTemplates}
                showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
                contentType={contentType}
                setTemplatePlanCreatedFrom={setTemplatePlanCreatedFrom}
                templatePlanCreatedFrom={templatePlanCreatedFrom}
              />
            ) : (
              <TaskForm
                isShowContentSegmentTDP={true}
                reconPlanFeatures={reconPlanFeatures}
                vehicle={vehicle}
                planStarted={planStarted}
                scrollToId={scrollToId}
                showDiscardButton={showDiscardButton}
                setShowDiscardButton={setShowDiscardButton}
                setFormProps={setFormProps}
                taskGroups={taskGroups}
                onTaskChange={onTaskChangeWithTaskGroups}
                isSaving={isSaving}
                setTaskGroups={setTaskGroups}
                isMessageTask={formProps.isMessageTask}
                isLineItemsTask={formProps.isLineItemsTask}
                initialFocus={initialFocus}
                parentId={formProps.vehicleId}
                drawerReady={drawerReady}
                contentType={contentType}
                vin={vehicle.vin}
                taskToUpdate={formProps.taskToUpdate}
                closeForm={closeForm}
                setScrollToId={setScrollToId}
                onCreate={formProps.onCreate}
                onUpdate={formProps.onUpdate}
                onDelete={formProps.onDelete}
                onComplete={formProps.onComplete}
                onCompleteTaskMessage={formProps.onCompleteTaskMessage}
                templateDropdownMenu={TemplateDropdownMenu}
                planTemplates={planTemplates}
                showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
                isInProgressTaskGroup={isInProgressTaskGroup(
                  formProps.taskToUpdate,
                  getInProgressStep(taskGroups),
                  flags
                )}
              />
            )}
          </DrawerContainer>
        );
      case VEHICLE_DETAILS:
        return (
          <VehicleDetails
            vehicle={vehicle}
            reconPlanFeatures={reconPlanFeatures}
            closeDrawer={closeDrawer}
            formProps={formProps}
            setFormProps={setFormProps}
            closeForm={closeForm}
            showDiscardButton={showDiscardButton}
            setShowDiscardButton={setShowDiscardButton}
            scrollToId={scrollToId}
            setScrollToId={setScrollToId}
            isSaving={isSaving}
            taskGroups={taskGroups}
            setTaskGroups={setTaskGroups}
            originalTaskGroups={originalTaskGroups}
            planStarted={planStarted}
            onTaskChange={onTaskChangeWithTaskGroups}
            templateDropdownMenu={TemplateDropdownMenu}
            planTemplates={planTemplates}
            showCreateReconPlanFromTemplateButton={showCreateReconPlanFromTemplateButton}
            contentType={contentType}
            setTemplatePlanCreatedFrom={setTemplatePlanCreatedFrom}
            templatePlanCreatedFrom={templatePlanCreatedFrom}
            showSeeMoreReply={showSeeMoreReply}
            setShowSeeMoreReply={handleSetShowSeeMoreReply}
            isSavingNote={isSavingNote}
          />
        );
      case COMMUNICATIONS:
        return (
          <DrawerContainer
            headerLabel={<CommonHeaderLabel>Communications</CommonHeaderLabel>}
            onClose={() => closeDrawer()}
            vehicle={vehicle}
            onHeaderClick={() => handleCanOpenVDP(true)}
          >
            <FullCommunicationsFromVehiclesStore
              containerId={vehicleId}
              vehicleId={vehicleId}
              passedInCommunications={comments.items}
              passedInCommunicationsAreSnippetOnly={comments.limit === 2}
              initialFocus={initialFocus}
              location={communicationContexts.INVENTORY_PAGE_COMMUNICATION_DRAWER}
              refTransientCommunication={refTransientCommunication}
              closeDrawer={closeDrawer}
              showSeeMoreReply={showSeeMoreReply}
              setShowSeeMoreReply={handleSetShowSeeMoreReply}
              isSavingNote={isSavingNote}
            />
          </DrawerContainer>
        );
      default:
        return <></>;
    }
  };
  return determineContent(contentType);
};
