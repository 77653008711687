import { navigate } from '@reach/router';
import { Drawer } from 'antd';
import {
  detailContents,
  features,
  inventorySortLabels,
  WIDTH_RECON_PLAN_DRAWERS,
  lineItemStatusTypes,
  ewsEntityTypes
} from 'app-constants';
import { convertTaskGroupsFromSpecificToGeneric as convertTemplateTaskGroups } from 'components/layout/settings/planTemplates/PlanTemplateDrawer';
import { useDebouncedEffect, useFeatures, useLocalStorage } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { isEqual, orderBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LINE_ITEMS_CHANGE_STATUS } from 'app-constants/lineItemConstants';
import { lineItemsActions } from 'store/lineItemsStore';
import { planTemplatesActions } from 'store/planTemplatesStore';
import { tasksActions } from 'store/tasksStore';
import { usersActions } from 'store/usersStore';
import { vdpActions } from 'store/vdpStore';
import { vehiclesActions } from 'store/vehiclesStore';

import { ContentSwitcher } from './ContentSwitcher';
import MainPage from './MainPage';
import { useVehiclePresentationState } from './useVehiclePresentationState';
import { useVehicleMenu } from './useVehiclesMenu';
import { COMPLETED } from 'app-constants/taskStatusTypes';
import { stripReferences } from 'utils/arrayUtils';
import { updateStatusForLineItemTemplate } from 'utils/lineItemsTemplateUtils';
import { usePrevious } from '../../../../hooks';
import { appraisalInfoActions } from '../../../../store/appraisalInfoStore';
import { HQAndProfitTimeContext } from 'utils/contexts';
import { Error } from 'components';
import { NEW } from '../../../../app-constants/reconPlanStatusTypes';
import { vdpSelector } from '../../../../store/vdpStore';

const vehiclesPresentationPropsSelector = createSelector(
  (state) => state.taskTypes.data,
  (state) => state.users.data,
  (state) => state.vehicles.data,
  (state) => state.vehicles.pager,
  (state) => state.vehicles.isShowDiscardVHButton,
  (state) => state.dealers.current.data,
  (state) => state.documents,
  (taskTypesData, users, vehiclesData, pager, isShowDiscardVHButton, currentDealer, documents) => ({
    taskTypesData,
    users,
    vehiclesData,
    pager,
    currentDealer,
    isShowDiscardVHButton,
    dealerDispositions: currentDealer.vehicleDispositionFilter || [],
    documents
  })
);

export const VehiclesPresentation = withLDConsumer()((props) => {
  const dispatch = useDispatch();
  const {
    selectedVehicleId,
    scrollcontainer,
    query,
    setQuery,
    reconStatusReverseLookup,
    taskProgressReverseLookup,
    inventoryStatusReverseLookup,
    dispositionReverseLookup,
    soldStatusReverseLookup,
    profitTimeReverseLookup,
    hiddenStatusReverseLookup,
    assignedToTechnician,
    setAssignedToTechnician,
    assignedToGroup,
    setAssignedToGroup,
    userId,
    forceLoadQueryFromStore,
    assignedToVendor,
    flags
  } = props;
  const {
    taskTypesData,
    users,
    vehiclesData,
    pager,
    currentDealer,
    isShowDiscardVHButton,
    dealerDispositions,
    documents
  } = useSelector(vehiclesPresentationPropsSelector);

  const isNotification = useSelector((state) => state.vdp.isNotification);
  const { contentType, modelId, originalTaskGroups } = useSelector(vdpSelector);
  const assigneeData = useSelector((state) => state.users.assigneeData);
  const taskCategoriesData = useSelector((state) => state.taskCategories.categoriesFromTaskTypes.data);
  const discardChangeLineItem = useSelector((state) => state.lineItems.showDiscardLineItem);
  const isShowAddLineItem = useSelector((state) => state.lineItems.isShowAddLineItem);
  const [hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask] = useFeatures(
    features.TASKS_EDIT,
    features.TASKS_WORK_OWN,
    features.TASKS_WORK_ANY
  );
  const { sort, taskId, display } = query;
  const [vehiclePresentationState, vehiclePresentationDispatch] = useVehiclePresentationState();
  const {
    isFirstFetch,
    selectedCard,
    filters,
    isInitialVehiclesFetch,
    formProps,
    showDiscardButton,
    isSaving,
    openVdpOnDiscard,
    taskGroups
  } = vehiclePresentationState;

  const lineItemChangeStatus = useSelector((state) => state.vdp.lineItemChangeStatus);
  const refTransientCommunication = useRef({ communication: '', images: [] });
  const [vehiclesPerPage, setVehiclesPerPage] = useLocalStorage('vehiclePageSize', 10);
  const { isHQ, hasReconProfitTime } = useContext(HQAndProfitTimeContext);
  const isNewVehicle = vehiclesData && vehiclesData.count === 1 && vehiclesData.items[0].inventoryType === NEW;

  const onTemplatePlanChosen = (template) => {
    const copyOfTaskGroups = convertTemplateTaskGroups(template.templateTaskGroups);
    copyOfTaskGroups.forEach((taskGroup) => {
      taskGroup.tasks.forEach((task) => {
        task.isAppliedLineItemsTemplate = false; // tracking applied line item template or not
        task.isAddingTask = true;
        task.isTransient = true;
        if (Array.isArray(task.lineItemTemplates)) {
          task.lineItemTemplates = {
            count: task.lineItemTemplates.length,
            items: task.lineItemTemplates.map((lineItem) => updateStatusForLineItemTemplate(lineItem))
          };
          task.lineItems = {
            count: task.lineItemTemplates.count,
            items: [...task.lineItemTemplates.items]
          };
        } else {
          task.lineItemTemplates = {
            count: 0,
            items: []
          };
          task.lineItems = {
            count: 0,
            items: []
          };
        }

        // update field needsApproval of task
        let needApprovalItem = task.lineItemTemplates.items.some(
          (lineItems) => lineItems.status === lineItemStatusTypes.PENDING
        );
        task.needsApproval = needApprovalItem;
        dispatch(tasksActions.setTasksLineItemStore(task));
      });
    });
    vehiclePresentationDispatch({ type: 'taskGroups', payload: copyOfTaskGroups });
    vehiclePresentationDispatch({ type: 'templatePlanCreatedFrom', payload: JSON.stringify(copyOfTaskGroups) });
  };

  const useMenuQuery = {
    reconStatusReverseLookup,
    taskProgressReverseLookup,
    inventoryStatusReverseLookup,
    dispositionReverseLookup,
    soldStatusReverseLookup,
    profitTimeReverseLookup,
    hiddenStatusReverseLookup,
    dealerDispositions,
    taskTypesData,
    taskCategoriesData,
    users,
    selectedVehicleId,
    isNotification,
    assigneeData,
    assignedToTechnician,
    assignedToGroup,
    userId,
    forceLoadQueryFromStore,
    ...(query ? query : {})
  };
  const [newFilters, menuState, setMenuState] = useVehicleMenu(useMenuQuery, flags);

  const commonProps = {
    flags,
    vehiclePresentationDispatch,
    ...vehiclePresentationState
  };

  const mainPageProps = {
    ...commonProps,
    scrollcontainer,
    menuState,
    setMenuState,
    query,
    setQuery,
    sort,
    currentDealer: currentDealer,
    hasEditTasks,
    pager,
    setVehiclesPerPage,
    assignedToTechnician,
    setAssignedToTechnician,
    assignedToGroup,
    setAssignedToGroup,
    userId
  };

  useEffect(() => {
    // Fetch page when is first fetch
    // selectedVehicleId prevent error when click on notification from dashboard
    if (isFirstFetch && !selectedVehicleId) {
      fetchPage({ ...pager, start: 1 }, newFilters, sort);
    }
  }, [isFirstFetch]);

  //#region filter
  useDebouncedEffect(
    () => {
      if (!selectedVehicleId) {
        fetchPage({ ...pager, start: 1 }, newFilters, sort);
      }
    },
    300,
    [JSON.stringify(newFilters), JSON.stringify(sort), selectedVehicleId]
  );

  useEffect(() => {
    dispatch(planTemplatesActions.get(currentDealer?.id));
    // when currentDealer changes, de-select current vehicle card and reload list
    dispatch(vdpActions.setModel(''));
    dispatch(vdpActions.setContentType(''));

    // if we came here with a selected vehicle id, load just that, otherwise load a page of vehicles
    if (selectedVehicleId) {
      dispatch(vehiclesActions.getVehicleById(selectedVehicleId, true, true, true));
      devLogger.log('User redirected from Provision');
    }
  }, [currentDealer?.id, selectedVehicleId]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  useEffect(() => {
    if (vehiclesData || documents?.documentsData) {
      const pageNum = (pager.start - 1) / pager.limit + 1;
      const rangeMax = pageNum * pager.limit;
      const to = Math.min(rangeMax, vehiclesData?.count);
      vehiclePresentationDispatch({
        type: 'displayRange',
        payload: `${Math.min(pager.start, to)}-${to}`
      });
      vehiclePresentationDispatch({
        type: 'count',
        payload: vehiclesData?.count
      });
      if (selectedVehicleId) {
        const vehicleToSelect = vehiclesData.items.find((t) => t.id === selectedVehicleId);
        if (vehicleToSelect) {
          setSelectedVehicle(vehicleToSelect);
          dispatch(usersActions.getData(currentDealer?.id)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be reallly often when you see how often adding/updating a single task can be)
          dispatch(vdpActions.setModel(selectedVehicleId));
          dispatch(vdpActions.setContentType(detailContents.VEHICLE_DETAILS));
          dispatch(vdpActions.setDocuments(documents));
        }
      } else if (contentType) {
        // If the vehicle changes while the drawer is open, update the drawer vehiclesData
        if (modelId) {
          dispatch(vdpActions.setModel(modelId));
          dispatch(vdpActions.setDocuments(documents));
        }
      }

      if (isFirstFetch) {
        vehiclePresentationDispatch({
          type: 'isFirstFetch',
          payload: false
        });
      }
    }
  }, [vehiclesData, selectedVehicleId, documents]);

  //clear assignedToVendor in url
  useEffect(() => {
    if (!flags.reconVendorManagement || !assignedToVendor) {
      setQuery({ assignedToVendor: undefined }, 'pushIn');
    }
  }, [assignedToVendor]);

  useEffect(() => {
    if (currentDealer) {
      if (!isHQ) {
        setQuery({ taskCategories: undefined }, 'pushIn');
      }
      if (currentDealer.entityType === ewsEntityTypes.DEALER || !flags.ireconHqView) {
        setQuery({ entitiesFilter: undefined }, 'pushIn');
      }
      if (currentDealer.entityType === ewsEntityTypes.DEALER && !hasReconProfitTime) {
        setQuery({ profitTime: undefined }, 'pushIn');
      }
    }

    if (!flags.reconHiddenFilter) {
      setQuery({ hiddenStatus: undefined }, 'pushIn');
    }
  }, []);

  const previousSelectedVehicle = usePrevious(selectedVehicle);
  const previousTaskId = usePrevious(taskId);
  const previousDisplay = usePrevious(display);

  //Get taskId, display from query, if they exist, we will go to associated view
  //For now, we just handle a case display='lineItems' then go to line item page of the task
  useEffect(() => {
    if (selectedVehicle) {
      if (!selectedVehicle.archived && taskId && display) {
        const taskToSelect = selectedVehicle.tasks?.items?.find((t) => t.id === taskId);

        if (taskToSelect && (taskToSelect.status !== COMPLETED || display === 'messages')) {
          //only dispatch this if a new vehicle is opened
          if (
            selectedVehicle?.id !== previousSelectedVehicle?.id ||
            (taskId !== previousTaskId && display !== previousDisplay)
          ) {
            dispatch(vdpActions.setSelectedTask({ taskId: taskId, display: display }));
          }
        } else {
          dispatch(vdpActions.setSelectedTask(null));
        }
      } else {
        dispatch(vdpActions.setSelectedTask(null));
      }
    }

    if (selectedVehicle?.id !== previousSelectedVehicle?.id) {
      dispatch(tasksActions.resetTasksLineItemByVehicleId());
    }
  }, [selectedVehicle?.id, selectedVehicle?.tasks?.items, taskId, display]);

  // fetchPage loads the vehicle data
  // pager has pagination information in it
  // - limit: how many vehicles per page
  // - start: what page of data to fetch
  // filters is essentially query - it contains all the different parameters that are present in the url in an object form
  // sort - sorting occurs on the API side and the data that is returned from the API is sorted by the value for this parameter
  const fetchPage = (pager, filters, sort) => {
    if (isFirstFetch === null) {
      vehiclePresentationDispatch({
        type: 'isFirstFetch',
        payload: true
      });
    }
    dispatch(vehiclesActions.setPager(pager));
    dispatch(
      vehiclesActions.getData(
        currentDealer?.id,
        pager,
        filters,
        sort,
        ['Tasks', 'Comments'],
        isInitialVehiclesFetch ? 0 : 600,
        flags?.reconTimestampFilter
      )
    ); // Since the initialization of this page has to fetch taskTypes and users, which is taking a long time already, removing the minimum load time on the first vehicles fetch
    vehiclePresentationDispatch({
      type: 'filters',
      payload: filters
    });

    let inventorySortLablesTemp = inventorySortLabels;
    if (!hasReconProfitTime) {
      inventorySortLablesTemp = Object.keys(inventorySortLabels)
        .filter((key) => key !== 'PROFIT_TIME_DESC' && key !== 'PROFIT_TIME')
        .reduce((result, current) => {
          result[current] = inventorySortLabels[current];
          return result;
        }, {});
    }

    //If the "IN PROGRESS" filter is not applied, then "TIME IN TASK" filters should be hidden
    if (filters?.reconStatus && !filters.reconStatus.includes('IN_PROGRESS')) {
      const sortFiltered = Object.keys(inventorySortLablesTemp)
        .filter((key) => key !== 'TIME_IN_TASK_DESC' && key !== 'TIME_IN_TASK')
        .reduce((result, current) => {
          result[current] = inventorySortLablesTemp[current];
          return result;
        }, {});
      vehiclePresentationDispatch({
        type: 'sortOptions',
        payload: sortFiltered
      });
    } else {
      vehiclePresentationDispatch({
        type: 'sortOptions',
        payload: inventorySortLablesTemp
      });
    }
    vehiclePresentationDispatch({
      type: 'isInitialVehiclesFetch',
      payload: false
    });
  };

  //useEffect for updating pageSize (limit) stored in redux store
  useEffect(() => {
    if (pager.limit !== vehiclesPerPage) {
      dispatch(vehiclesActions.setPager({ ...pager, limit: vehiclesPerPage }));
    }
  }, [vehiclesPerPage, pager]);
  //#endregion

  const resetCommunication = () => {
    refTransientCommunication.current = { communication: '', image: [] };
  };
  const stripUnwantedEqualityProps = (fromTaskGroups) => {
    return stripReferences(fromTaskGroups).map((stg) => {
      delete stg['id'];
      return {
        ...stg,
        vehicleId: selectedVehicleId,
        dealerId: currentDealer?.id,
        tasks: stg.tasks.map((t) => {
          let task = { ...t };
          [
            'isTransient',
            'isReAssigned',
            'prevPassThroughState',
            'taskGroupId',
            'isAddingTask',
            'lineItems',
            'reassignments',
            'secondsInTask',
            'taskSequence'
          ].forEach((key) => delete task[key]);
          if (task.description?.length === 0) {
            //Do not compare description key if description is empty
            delete task.description;
          }
          return task;
        })
      };
    });
  };

  const handleCanClose = (isFromCommunications) => {
    if (!isSaving) {
      const strippedTaskGroups = stripUnwantedEqualityProps(taskGroups);
      const strippedOriginalTaskGroups = stripUnwantedEqualityProps(originalTaskGroups);
      if (!showDiscardButton && formProps?.isLineItemsTask && discardChangeLineItem && isShowAddLineItem) {
        // When it isDirty and on the first time the user clicks X, we show the discard button
        vehiclePresentationDispatch({ type: 'showDiscardButton', payload: true });
        return false;
      } else if (formProps && [detailContents.RECON_PLAN, detailContents.CREATE_RECON_PLAN].includes(contentType)) {
        closeForm();
      } else if (
        !showDiscardButton &&
        !isEqual(orderBy(strippedTaskGroups, 'sequence'), orderBy(strippedOriginalTaskGroups, 'sequence'))
      ) {
        // When it isDirty and on the first time the user clicks X, we show the discard button
        if (lineItemChangeStatus === LINE_ITEMS_CHANGE_STATUS.ONLY_CHANGE_LINE_ITEM) {
          vehiclePresentationDispatch({ type: 'showDiscardButton', payload: false });
          closeDrawer(isFromCommunications);
        } else {
          vehiclePresentationDispatch({ type: 'scrollToId', payload: null });
          vehiclePresentationDispatch({ type: 'showDiscardButton', payload: true });
        }
        return false;
      } else if (
        !isShowDiscardVHButton &&
        (refTransientCommunication.current.communication || refTransientCommunication.current.images?.length > 0)
      ) {
        // When comment what we want to add is not null or empty and on the first time the user clicks X, we show the discard button
        dispatch(vehiclesActions.setShowDiscardVHButton(true));
        return false;
      } else {
        // If the user clicks the top right X button again (2 clicks), it will force discard and close
        closeDrawer(isFromCommunications);
      }
    }
    return true;
  };

  const closeForm = () => {
    vehiclePresentationDispatch({ type: 'formProps', payload: null });
    vehiclePresentationDispatch({ type: 'showDiscardButton', payload: false });
    dispatch(lineItemsActions.setDiscardLineItem(false));
    dispatch(lineItemsActions.resetLineItem());
  };

  const closeDrawer = (isFromCommunications = false) => {
    if (!isFromCommunications) {
      dispatch(vdpActions.setModel(''));
      dispatch(vdpActions.setContentType(''));
      dispatch(
        vdpActions.setComments({
          count: 0,
          items: []
        })
      );
    }
    reset();
    vehiclePresentationDispatch({ type: 'taskGroups', payload: [] });
    dispatch(vdpActions.setActiveTab('1'));
    dispatch(vdpActions.setRemoveExpandItem([]));
    dispatch(vdpActions.setTasksWithTransientTasks([]));
    dispatch(lineItemsActions.resetLineItem());
    dispatch(lineItemsActions.setDiscardLineItem(false));
    dispatch(vdpActions.setLineItemChangeStatus(LINE_ITEMS_CHANGE_STATUS.INITIAL));
    // Dismiss appraisal popover before closing the drawer
    dispatch(appraisalInfoActions.setAppraisalPopoverState(false));
    if (openVdpOnDiscard) {
      openVDP();
    } else if (selectedVehicleId) {
      // If we had navigated to a vehicle by id, go back to the normal list
      navigate('/inventory');
    }
  };

  const reset = () => {
    vehiclePresentationDispatch({ type: 'showDiscardButton', payload: false });
    vehiclePresentationDispatch({ type: 'scrollToId', payload: null });
    dispatch(vdpActions.setTransientTasks([]));
    dispatch(vdpActions.setOriginalTaskGroups([]));
    vehiclePresentationDispatch({ type: 'templatePlanCreatedFrom', payload: null });
    vehiclePresentationDispatch({ type: 'formProps', payload: null });
    resetCommunication();
    dispatch(vehiclesActions.setShowDiscardVHButton(false));
    dispatch(vehiclesActions.setShowVehicleNote(false));
    dispatch(vdpActions.setNotification(false));
  };

  const handleCanOpenVDP = (isFromCommunications = false) => {
    vehiclePresentationDispatch({ type: 'openVdpOnDiscard', payload: true });
    if (handleCanClose(isFromCommunications)) {
      openVDP();
    }
  };

  const openVDP = () => {
    dispatch(vdpActions.setModel(modelId));
    dispatch(vdpActions.setContentType(detailContents.VEHICLE_DETAILS));
    vehiclePresentationDispatch({ type: 'openVdpOnDiscard', payload: false });
  };

  const getWidthDrawer = () => {
    if (contentType === detailContents.VEHICLE_DETAILS) {
      const maxWidth = 1232;
      const percentage = hasReconProfitTime ? 0.95 : 0.7;
      const calculatedWidth = Math.round(
        Math.max(document.documentElement.clientWidth, window.innerWidth) * percentage
      );
      return Math.min(calculatedWidth, maxWidth);
    }
    return WIDTH_RECON_PLAN_DRAWERS;
  };

  const memoizedFeatures = useMemo(
    () => [hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask],
    [hasEditTasks, hasWorkOwnTasks, hasWorkAnyTask]
  );
  const memoziedBodyStyle = useMemo(
    () => ({
      padding: '0'
    }),
    []
  );
  const memorizedOnTemplatePlanChosen = useCallback(onTemplatePlanChosen, []);
  const memoizedCloseDrawer = useCallback(closeDrawer, [modelId, openVdpOnDiscard, selectedVehicleId]);
  const memoizedCloseForm = useCallback(closeForm, []);
  const memoizedHandleCanClose = useCallback(handleCanClose, [
    isSaving,
    formProps,
    contentType,
    showDiscardButton,
    isShowDiscardVHButton,
    refTransientCommunication.current.communication,
    refTransientCommunication.current.images,
    taskGroups,
    originalTaskGroups
  ]);
  const memoizedHandleCanOpenVDP = useCallback(handleCanOpenVDP, [modelId]);
  const memoizedReset = useCallback(reset, []);
  const memoziedFetchPage = useCallback(fetchPage, [isFirstFetch, pager, filters, sort]);

  // #end region
  if (isNewVehicle) {
    return (
      <Error showRefreshButton={false} errorText={'Have something wrong'} infoText={'Cannot show a new inventory'} />
    );
  }
  return (
    <>
      <MainPage
        memorizedOnTemplatePlanChosen={memorizedOnTemplatePlanChosen}
        fetchPage={memoziedFetchPage}
        {...mainPageProps}
      />
      <Drawer
        closable={false}
        destroyOnClose={true}
        placement="right"
        width={getWidthDrawer()}
        visible={contentType}
        onClose={() => handleCanClose()}
        bodyStyle={memoziedBodyStyle}
      >
        {contentType && (
          <ContentSwitcher
            reconPlanFeatures={memoizedFeatures}
            closeDrawer={memoizedCloseDrawer}
            closeForm={memoizedCloseForm}
            handleCanClose={memoizedHandleCanClose}
            handleCanOpenVDP={memoizedHandleCanOpenVDP}
            reset={memoizedReset}
            onTemplatePlanChosen={memorizedOnTemplatePlanChosen}
            refTransientCommunication={refTransientCommunication}
            setTasksWithTransientTasks={(val) =>
              vehiclePresentationDispatch({ type: 'tasksWithTransientTasks', payload: val })
            }
            setOriginalTaskGroups={(val) => vehiclePresentationDispatch({ type: 'originalTaskGroups', payload: val })}
            setTaskGroups={(val) => vehiclePresentationDispatch({ type: 'taskGroups', payload: val })}
            setShowDiscardButton={(val) => vehiclePresentationDispatch({ type: 'showDiscardButton', payload: val })}
            setFormProps={(val) => vehiclePresentationDispatch({ type: 'formProps', payload: val })}
            setIsSaving={(val) => vehiclePresentationDispatch({ type: 'isSaving', payload: val })}
            setTemplatePlanCreatedFrom={(val) =>
              vehiclePresentationDispatch({ type: 'templatePlanCreatedFrom', payload: val })
            }
            {...selectedCard}
            {...commonProps}
          />
        )}
      </Drawer>
    </>
  );
});
