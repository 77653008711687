//#region Imports
// Component Libraries
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Typography } from 'antd';
import { detailContents, features, taskProgressLabels } from 'app-constants';
import { EXEMPT, IN_PROGRESS, NEW, RETAIL_READY } from 'app-constants/reconPlanStatusTypes';
import { COMPLETED as TASK_COMPLETED } from 'app-constants/taskStatusTypes';
import { DECLINED } from 'app-constants/taskStatusTypes.js';
// Local Components
import { ResponsiveStack, SplitButtonWithDropdown, Stack } from 'components';
import { TaskCompletedIcon } from 'components/layout/tasks/icons';
import TaskGroupIconAndPopover from 'components/layout/tasks/icons/TaskGroupIconAndPopover';
import { ReconTaskWarningTag } from 'components/tags';
import { useFeatures } from 'hooks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { getTaskProgress } from 'components/layout/tasks/common/taskUtils';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMediaPredicate } from 'react-media-hook';
// Store
import { useDispatch, useSelector } from 'react-redux';
import { usersActions } from 'store/usersStore';
import { vdpActions } from 'store/vdpStore';
import styled from 'styled-components';
// Util Libraries
import { displayDurationSummary, formatDateTime, toMomentDuration } from 'utils/dateTimeUtils';
import { VENDOR } from 'app-constants/groupTypes';
import { HQAndProfitTimeContext } from 'utils/contexts';
//#endregion

const { Text } = Typography;

// #region VehicleCardTask
/**
 * VehicleCardTask description.
 *
 * @param {boolean} isLoading
 * @param {string} dealerId
 * @param {object} vehicle
 * @param {boolean} isSelected
 * @param {function} setSelectedCard
 * @param {boolean} hasEditTasks
 * @param {object} planTemplates
 * @param {string} vehiclePlanStatus
 * @param {function} setVehiclePlanStatus
 * @param {function} setTemplatePlanCreatedFrom
 * @param {object} flags
 */
const VehicleCardTask = React.memo(({ isLoading, dealerId, vehicle, isSelected, onTemplatePlanChosen, flags }) => {
  const dispatch = useDispatch();
  const { tasks, reconPlan, reconStatus } = vehicle;
  const screenWidthIsMax1200px = useMediaPredicate('(max-width: 1200px)');
  const [hasEditTasks] = useFeatures(features.TASKS_EDIT);
  // Task group data
  const [inProgressTasks, setInProgressTasks] = useState([]); //should only contain in-progress tasks from the plan
  const [currentSequence, setCurrentSequence] = useState(0);
  const [currentGroupTaskTimes, setCurrentGroupTaskTimes] = useState('');
  const [currentGroupTaskAssignees, setCurrentGroupTaskAssignees] = useState('');

  const reconTaskGroups = reconPlan ? reconPlan.reconTaskGroups : {};
  const { isHQ } = useContext(HQAndProfitTimeContext);

  /**
   * New is the component that will show when a vehicle has no plan yet - there are no tasks in the vehicle's recon plan
   */
  const New = () => {
    const planTemplates = useSelector((state) => state.planTemplates);
    return (
      <Stack horizontal>
        {hasEditTasks && !isHQ ? (
          <SplitButtonWithDropdown
            type="danger"
            dropdownCTAIcon={faAngleDown}
            additionalStyles={{
              marginLeft: '16px',
              marginTop: '16px'
            }}
            dropdownList={planTemplates}
            onClick={() => {
              // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted
              // (which can be really often when you see how often adding/updating a single task can be)
              dispatch(usersActions.getData(dealerId));
              dispatch(vdpActions.setModel(vehicle.id));
              dispatch(vdpActions.setContentType(detailContents.CREATE_RECON_PLAN));
            }}
            listItemOnClick={(template) => {
              dispatch(usersActions.getData(dealerId)); // the user's list will now be fetched when the drawer is open, rather than when the taskForm is mounted (which can be really often when you see how often adding/updating a single task can be)
              onTemplatePlanChosen(template);
              dispatch(vdpActions.setModel(vehicle.id));
              dispatch(vdpActions.setContentType(detailContents.CREATE_RECON_PLAN));
            }}
            disabled={vehicle.removedOn || vehicle.excludedOn || isSelected}
            noListDataMessage="No Templates Available"
          >
            Create Recon Plan
          </SplitButtonWithDropdown>
        ) : null}
      </Stack>
    );
  };

  const StartedUS492701 = () => {
    const anyDeclinedTasks = inProgressTasks.some((task) => task.declined || task.status === DECLINED);
    const taskToDecline = inProgressTasks.find((task) => task.declined || task.status === DECLINED);
    const taskToOverdue = inProgressTasks.find((task) => getTaskProgress(task).status === taskProgressLabels.Overdue);
    return (
      <ResponsiveStack
        horizontal
        mediaQueryBoolean={screenWidthIsMax1200px}
        stylesWhenMediaQueryThresholdIsMet={{ width: '350px' }}
        stylesWhenMediaQueryThresholdIsNotMet={{ width: '400px' }}
      >
        <StyledIconContainer reconTaskCollapseFlag={flags.reconTaskCollapse}>
          <TaskGroupIconAndPopover
            remainingTasks={inProgressTasks}
            singleTask={inProgressTasks.length === 1}
            flags={flags}
          />
        </StyledIconContainer>
        <Stack>
          <Stack
            horizontal
            divStyle={{ alignItems: 'center', display: 'flex', width: screenWidthIsMax1200px ? '300px' : '350px' }}
          >
            <StyledTitle>
              {inProgressTasks.length > 1 ? `${inProgressTasks.length} Tasks` : inProgressTasks[0]?.reconTaskTypeName}
            </StyledTitle>
            <StyledSummary>
              (Step {currentSequence} of {reconTaskGroups?.count})
            </StyledSummary>
            {inProgressTasks.length === 1 ? (
              <ReconTaskWarningTag task={inProgressTasks[0]} isHideNeedsApprovalTag={true} />
            ) : anyDeclinedTasks ? (
              <ReconTaskWarningTag task={taskToDecline} />
            ) : (
              <ReconTaskWarningTag task={taskToOverdue} isHideNeedsApprovalTag={true} />
            )}
          </Stack>
          <Text
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              minWidth: 0,
              maxWidth: screenWidthIsMax1200px ? '300px' : '350px'
            }}
          >
            {currentGroupTaskAssignees}
          </Text>
          <Text
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              minWidth: 0,
              maxWidth: screenWidthIsMax1200px ? '300px' : '350px'
            }}
          >
            Task Time{currentGroupTaskTimes.indexOf(',') > -1 && 's'}: {currentGroupTaskTimes}
          </Text>
        </Stack>
      </ResponsiveStack>
    );
  };

  /**
   * Flr = Front Line Ready - vehicle's plan is complete - no incomplete tasks.
   */
  const Flr = () => (
    <ResponsiveStack
      horizontal
      mediaQueryBoolean={screenWidthIsMax1200px}
      stylesWhenMediaQueryThresholdIsMet={{ width: '350px' }}
      stylesWhenMediaQueryThresholdIsNotMet={{ width: '400px' }}
    >
      <StyledIconContainer reconTaskCollapseFlag={flags.reconTaskCollapse}>
        <StyledPlanCompleteIcon outerSize="20px" iconSize="xs" isShowCompletedIcon={true} />
      </StyledIconContainer>
      <Stack>
        <StyledText>
          Front Line Ready{' '}
          <span>
            ({reconTaskGroups?.count} of {reconTaskGroups?.count})
          </span>
        </StyledText>
        <Text>{formatDateTime(vehicle.becameFlrOn)}</Text>
        {vehicle.leftInventoryOn && (
          <StyledDatetime>{`Left Inventory on ${formatDateTime(vehicle.leftInventoryOn)}`}</StyledDatetime>
        )}
      </Stack>
    </ResponsiveStack>
  );

  const determineContentForTask = (reconStatus) => {
    if (reconStatus === NEW) return <New />;
    if (reconStatus === EXEMPT) return <New />;
    if (reconStatus === IN_PROGRESS) return <StartedUS492701 />;
    if (reconStatus === RETAIL_READY) return <Flr />;

    return null;
  };

  const setAssigneeNameByTasks = (inprogressTasks) => {
    const splitGroupCharactar = (i) => (i === inprogressTasks.length - 1 ? '' : ', ');

    const allNamesTheSame = inprogressTasks.every(
      (t) =>
        inprogressTasks[0].assignedToName === t.assignedToName &&
        inprogressTasks[0].assignedToGroupName === t.assignedToGroupName
    );
    // when all name is same or only 1 task
    if (allNamesTheSame) {
      const firstTask = inprogressTasks[0];
      const duplicatedAssigneeName = displayAssigneesName(firstTask, '');
      setCurrentGroupTaskAssignees(duplicatedAssigneeName);
    } else {
      // this array contains both text and element
      const displayAssignedToArray = inprogressTasks.map((t, i) => {
        return displayAssigneesName(t, splitGroupCharactar(i));
      });
      setCurrentGroupTaskAssignees(displayAssignedToArray);
    }
  };

  const displayAssigneesName = (task, splitGroupCharactar) => {
    if (flags.reconVendorManagement && task.assignedToGroupType === VENDOR) {
      return task.assignedToName
        ? `${task.assignedToGroupName} (${task.assignedToName})${splitGroupCharactar}`
        : task.assignedToGroupName + splitGroupCharactar;
    }
    if (task.assignedToGroupName) {
      return task.assignedToName ? (
        `${task.assignedToName} (${task.assignedToGroupName})${splitGroupCharactar}`
      ) : (
        <>
          <span className="unassigned-text-style">Unassigned</span>&nbsp;
          {`(${task.assignedToGroupName})${splitGroupCharactar}`}
        </>
      );
    } else {
      return task.assignedToName ? (
        task.assignedToName + splitGroupCharactar
      ) : (
        <>
          <span className="unassigned-text-style">Unassigned</span> {splitGroupCharactar}
        </>
      );
    }
  };

  useEffect(() => {
    if (reconPlan && Object.keys(reconPlan).length > 0 && reconTaskGroups?.count > 0) {
      // begin comment old code
      // const incompleteTaskGroups = reconTaskGroups?.items.filter(group => group.reconTasks.items.some(task => (task.declined && incompleteProgressStatuses.includes(task.status)) || incompleteProgressStatuses.includes(task.status)));
      // const indexOfFirstNonPassthroughIncompleteTaskGroup = incompleteTaskGroups.findIndex(group => group.reconTasks.items.length > 1 || !group.reconTasks.items[0].passthrough)
      // if (indexOfFirstNonPassthroughIncompleteTaskGroup > -1) {
      //   // We do this to get rid of any task groups that are deferred but have a declined task in it
      //   incompleteTaskGroups.slice(0, indexOfFirstNonPassthroughIncompleteTaskGroup);
      // }
      // const allInProgressTasks = incompleteTaskGroups.reduce((tasks, group) => tasks.concat(group.reconTasks.items?.filter(task => task.status !== TASK_COMPLETED)), []);
      // end comment old code

      let allInProgressTasksGroup = [];
      let isPrevTaskPassThrough = true;
      let currentSequenceTemp = -1;

      const incompleteTaskGroups = reconTaskGroups?.items.filter((group) =>
        group.reconTasks.items.some((task) => task.status !== TASK_COMPLETED)
      );
      for (let i = 0; i < incompleteTaskGroups.length; i++) {
        if (incompleteTaskGroups[i].reconTasks.items.length > 1) {
          allInProgressTasksGroup.push(incompleteTaskGroups[i]);
          currentSequenceTemp = incompleteTaskGroups.find((tasksGroup) => tasksGroup.id === incompleteTaskGroups[i].id)
            ? incompleteTaskGroups[i].sequence
            : -1;
          break;
        } else {
          if (isPrevTaskPassThrough) {
            allInProgressTasksGroup.push(incompleteTaskGroups[i]);
            isPrevTaskPassThrough = incompleteTaskGroups[i].reconTasks.items[0].passthrough;
            if (!isPrevTaskPassThrough) {
              currentSequenceTemp = incompleteTaskGroups.find(
                (tasksGroup) => tasksGroup.id === incompleteTaskGroups[i].id
              )
                ? incompleteTaskGroups[i].sequence
                : -1;
              break;
            }
          } else {
            currentSequenceTemp = incompleteTaskGroups.find(
              (tasksGroup) => tasksGroup.id === incompleteTaskGroups[i - 1].id
            )
              ? incompleteTaskGroups[i - 1].sequence
              : -1;
            break;
          }
        }
      }
      const allInProgressTasks = allInProgressTasksGroup.reduce(
        (tasksInProgress, group) =>
          tasksInProgress.concat(group.reconTasks.items?.filter((task) => task.status !== TASK_COMPLETED)),
        []
      );
      if (allInProgressTasks.length > 0) {
        setInProgressTasks(allInProgressTasks);
        setCurrentSequence(currentSequenceTemp > -1 ? currentSequenceTemp : reconTaskGroups?.count);

        // Set task time string for group
        // If all times are the same, only show a single instance; if at least one time is different than the
        // others, show all times
        const displayTimeArray = allInProgressTasks.map((task) =>
          displayDurationSummary(toMomentDuration({ seconds: task.secondsInTask }), false, true)
        );
        const allTimesTheSame = displayTimeArray.every((time) => displayTimeArray[0] === time);

        if (allTimesTheSame) {
          setCurrentGroupTaskTimes(displayTimeArray[0]);
        } else {
          setCurrentGroupTaskTimes(displayTimeArray.join(', '));
        }

        setAssigneeNameByTasks(allInProgressTasks);
      }
    }
  }, [tasks, reconTaskGroups]);

  if (isLoading) {
    return (
      <StyledCenter>
        <div style={{ width: screenWidthIsMax1200px ? '350px' : '400px', paddingLeft: '25px', paddingRight: '16px' }}>
          <Skeleton count={3} />
        </div>
      </StyledCenter>
    );
  } else {
    return <>{determineContentForTask(reconStatus)}</>;
  }
});

TaskGroupIconAndPopover.propTypes = {
  isLoading: PropTypes.bool,
  dealerId: PropTypes.string,
  vehicle: PropTypes.objectOf(PropTypes.any),
  isSelected: PropTypes.bool,
  hasEditTasks: PropTypes.bool,
  planTemplates: PropTypes.objectOf(PropTypes.any),
  vehiclePlanStatus: PropTypes.string,
  setVehiclePlanStatus: PropTypes.func,
  setTemplatePlanCreatedFrom: PropTypes.func,
  flags: PropTypes.objectOf(PropTypes.any)
};
TaskGroupIconAndPopover.defaultProps = {
  isLoading: false,
  dealerId: '',
  vehicle: {},
  isSelected: false,
  hasEditTasks: false,
  planTemplates: {},
  vehiclePlanStatus: '',
  setVehiclePlanStatus: () => {},
  setTemplatePlanCreatedFrom: () => {},
  flags: {}
};
// #endregion

//#region Styled Components
const StyledDatetime = styled(Text).attrs({
  type: 'secondary'
})``;
const StyledTitle = styled(Text).attrs({
  className: 'medium-font'
})`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  .ant-typography& {
    color: ${(props) => (props['data-color'] ? props['data-color'] : 'inherit')};
  }
`;
const StyledSummary = styled(Text)`
  color: ${({ theme }) => theme.colors.gray};
  margin-left: 10px;
  white-space: nowrap;
`;
const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
  height: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '28px'};
  align-items: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && 'center'};
  padding-left: ${({ reconTaskCollapseFlag }) => reconTaskCollapseFlag && '6px'};
`;
const StyledPlanCompleteIcon = styled(TaskCompletedIcon).attrs(({ theme }) => ({
  color: theme.colors.navy,
  colorSecondary: theme.colors.navy
}))``;

const StyledCenter = styled.div.attrs({
  className: 'center-content'
})`
  height: 100%;
`;
const StyledText = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.md};
  span {
    font-weight: ${({ theme }) => theme.fontWeights.normal};
    color: ${({ theme }) => theme.colors.gray};
    font-size: ${({ theme }) => theme.fontSizes.sm};
  }
`;

//#endregion

export default withLDConsumer()(VehicleCardTask);
