import withLDConsumer from 'launchdarkly-react-client-sdk/lib/withLDConsumer.js';
import React from 'react';
import styled from 'styled-components';
import { Typography, Tag, Popover } from 'antd';
import { formatDateTime } from 'utils/dateTimeUtils';
import { Stack } from 'components';
const { Text } = Typography;

export const reconTagTypes = {
  ARCHIVED: { value: 'ARCHIVED', level: 'info', hasHoverContent: true },
  NO_PLAN: { value: 'NO PLAN', level: 'info' },
  EXCLUDED: { value: 'EXCLUDED', level: 'info', hasHoverContent: true },
  COMPLETED: { value: 'COMPLETED', level: 'success' },
  PLAN_COMPLETE: { value: 'PLAN COMPLETE', level: 'success' },
  SOLD: { value: 'SOLD', level: 'warning', hasHoverContent: true },
  TRANSPORT: { value: 'TRANSPORT', level: 'warning' },
  OVERDUE: { value: 'OVERDUE', level: 'critical' },
  DECLINED: { value: 'DECLINED', level: 'critical' },
  RECALL: { value: 'RECALL', level: 'critical' },
  PENDING: { value: 'PENDING', level: 'info' },
  NEEDS_APPROVAL: { value: 'NEEDS APPROVAL', level: 'info' },
  PENDING_APPROVAL: { value: 'PENDING APPROVAL', level: 'info' }
};

/**
 * Display a tag component chosen from a standard set of recon tags.
 *
 * @param {reconTagType} type The type of ReconTag to display
 * @param {*} other This encompasses any other props that might be
 *  wanted on the underlying antd components
 */
const ReconTag = ({ type, flags, ...other }) => {
  if (!type) {
    devLogger.error('Unspecified tag type');
    return <></>;
  }

  const hoverContent = <HoverContent type={type} {...other} />;

  return (
    <Popover content={hoverContent} trigger={type.hasHoverContent ? 'hover' : 'none'}>
      <StyledTag recon-task-collapse-flag={flags.reconTaskCollapse ? 1 : 0} level={type.level} {...other}>
        <span>{type.value}</span>
      </StyledTag>
    </Popover>
  );
};

/**
 * Determine the tag hover content, if any
 * @param {reconTagType} type The type of ReconTag to display
 * @param {vehicle} vehicle The vehicle associated with the tag
 */
const HoverContent = ({ type, vehicle }) => {
  switch (type.value) {
    case reconTagTypes.ARCHIVED.value:
      return (
        <Stack>
          <Text>{formatDateTime(vehicle.removedOn)}</Text>
          <Text className={'extra-small-font'}>by {vehicle.removedByName || 'Automated Process'}</Text>
        </Stack>
      );
    case reconTagTypes.EXCLUDED.value:
      return (
        <Stack>
          <Text>{formatDateTime(vehicle.excludedOn)}</Text>
          <Text className={'extra-small-font'}>by {vehicle.excludedByName || 'Automated Process'}</Text>
        </Stack>
      );
    case reconTagTypes.SOLD.value:
      return (
        <Stack>
          <Text>{vehicle.vautoRemovedOn ? formatDateTime(vehicle.vautoRemovedOn) : 'Sold date not found'}</Text>
        </Stack>
      );
    default:
      return null;
  }
};

const StyledTag = styled(Tag)`
  .ant-tag& {
    margin-left: 8px;
    margin-right: 0px;
    padding: 1px 8px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${(props) => {
      switch (props['level']) {
        case 'success':
          return props.theme.colors.successPrimary;
        case 'warning':
          return props.theme.colors.warningPrimary;
        case 'critical':
          return props.theme.colors.criticalPrimary;
        case 'info':
        default:
          return props.theme.colors.infoPrimary;
      }
    }};
    border: 1px solid
      ${(props) => {
        switch (props['level']) {
          case 'success':
            return props.theme.colors.successSecondary;
          case 'warning':
            return props.theme.colors.warningSecondary;
          case 'critical':
            return props.theme.colors.criticalSecondary;
          case 'info':
          default:
            return props.theme.colors.infoSecondary;
        }
      }};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-align: center;
    border-radius: 10px;
    height: 20px;
    line-height: 16px;
    letter-spacing: 0;
  }
`;

export default withLDConsumer()(ReconTag);
