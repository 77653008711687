import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Link } from '@reach/router';
import { Table, Tooltip } from 'antd4';
import 'antd4/lib/table/style/index.css';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { apiStatusConstants, breakdownTypes, taskTypeRow, statisticTagTypes } from 'app-constants';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { SortContext } from '../Dashboard';
import { VENDOR } from 'app-constants/groupTypes';
import { compact } from 'lodash';
import { HQAndProfitTimeContext } from 'utils/contexts';
import TruncatedTooltipOneLine from '../../../common/TruncatedTooltipOneLine';
import uuidv4 from 'uuid/v4';
import { useCommonDropdownContext } from 'components/common/CommonDropdown';
import { generateDealershipsLink } from '../commonFunctions';

const MOMENT_FORMATTING = 'MM-DD-YYYY';

const SkeletonTable = withLDConsumer()(({ flags }) => {
  return (
    <SkeletonContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
      {Array.from(Array(11)).map((_, i) => (
        <SkeletonRowContainer key={uuidv4()}>
          <Skeleton width="100%" height="32px" />
        </SkeletonRowContainer>
      ))}
    </SkeletonContainer>
  );
});

//requirement: sort the data upon initial load to be in the order that is seen in the task types settings menu
//taskTypesSorted has all of the data in the order in which it is sorted in the task types settings menu
//here we take the metrics data and sort it according to the order in taskTypesSorted
const defaultSortForActiveMetricsTable = (metricsData, taskTypesSorted) => {
  const sortedMetricsData = taskTypesSorted.reduce((sortedData, taskType) => {
    const index = metricsData.findIndex(({ taskTypeId }) => taskTypeId === taskType.id);
    if (index > -1) {
      return [...sortedData, { ...metricsData[index] }];
    }
    return [...sortedData];
  }, []);

  return sortedMetricsData;
};

const selectorHOF = (metricsType) =>
  createSelector(
    (state) => state.metrics,
    (state) => state.taskTypes.data,
    (metrics, taskTypes) => ({
      historicalInfo: metrics?.[metricsType]?.historicalInfo,
      metricsData: metrics?.[metricsType]?.taskBreakdownData,
      fetchStatus: metrics[`${metricsType}FetchStatus`],
      taskTypes,
      summary: metrics?.[metricsType]?.summary
    })
  );

const TaskBreakdown = ({ metricsType = statisticTagTypes.ACTIVE_METRICS, filters = null, flags }) => {
  const { historicalInfo, metricsData, fetchStatus, taskTypes, summary } = useSelector(selectorHOF(metricsType));
  const [expandedTaskTypes, setExpandedTaskTypes] = useState({});
  const [expandedTaskTypesMemo, setExpandedTaskTypesMemo] = useState({});
  const { taskBreakdownSort, setTaskBreakdownSort } = useContext(SortContext);
  //columns = titles on top row of table
  //dataSource = breakdown of statistics by tasks type
  const [columns, ...dataSource] = metricsData || [];
  const dataSourceWithExpandableProp = dataSource?.map((record) => ({
    ...record,
    expanded: !!expandedTaskTypes[record.taskTypeId] || !!expandedTaskTypes[record.categoryId]
  }));

  const { isHQ } = useContext(HQAndProfitTimeContext);
  const { items } = useCommonDropdownContext('dealerships');

  // Prevents non-expanded rows from being highlighted when changing filters
  useEffect(() => {
    setExpandedTaskTypes({});
  }, [metricsData]);

  const onChangeHandler = (...params) => {
    const sortHeader = params[2];
    if (sortHeader && sortHeader.field) {
      taskBreakdownSort[metricsType] = { order: sortHeader.order, field: sortHeader.field };
      setTaskBreakdownSort(taskBreakdownSort);
    }
  };

  const createActiveMetricsURL = (text, record, taskProgress, includeTaskType = true, isTotal = false) => {
    const isChildRow = record.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW); //Internal User Record
    const isInternalGroupChildRow = isChildRow && record.assignedToGroupId != null && record.children?.length > 0; //Internal Group Record
    const isInternalGroupUserChildRow = record.rowKey.includes(taskTypeRow.INTERNAL_GROUP_CHILD_ROW); //Internal Group Users/Members Record

    const isCategoryRow = flags.ireconHqView && record.breakdownType === breakdownTypes.CATEGORY;
    const isDealerChildRow = flags.ireconHqView && record.breakdownType === breakdownTypes.DEALER;
    const isTaskTypeChildRow =
      flags.ireconHqView && record.breakdownType === breakdownTypes.TASK_TYPE && record.children?.length <= 0;

    let categoriesFilterParameter,
      entitiesFilterParameter,
      taskTypeParameter,
      additionalParameters,
      groupFilter,
      userFilter = '';
    if (isCategoryRow || isDealerChildRow || isTaskTypeChildRow) {
      // HQ/Category breakdown - don't add any assignee/group filters

      // Make category filter
      categoriesFilterParameter = `taskCategories=${encodeURIComponent(record.categoryName)}`;

      // Make dealership filter
      if (isDealerChildRow || isTaskTypeChildRow) {
        entitiesFilterParameter = `entitiesFilter=${encodeURIComponent(record.dealerName)}`;
      }

      // Make task type filter when task type is child row
      if (isTaskTypeChildRow) {
        taskTypeParameter = `taskType=${encodeURIComponent(text)}`;
      }
    } else {
      // Rooftop/Task Type breakdown

      // Make filter for Internal Group
      let isUnassigned = false;
      let members = [];
      if (isChildRow && isInternalGroupChildRow) {
        members = record.children.map((member) => member.assignedTo);
        isUnassigned = members.includes(undefined);
      } else if (isInternalGroupUserChildRow) {
        members = record.assignedTo ? [record.assignedTo] : [];
        isUnassigned = record.assignedTo === undefined;
      }
      groupFilter = JSON.stringify([
        {
          assignedToGroup: record.assignedToGroupId,
          assignedToMembers: compact(members),
          unassigned: isUnassigned
        }
      ]);

      // Make filter for Internal User
      if (isChildRow && !isInternalGroupChildRow) {
        userFilter = JSON.stringify([
          {
            assignedTo: record.assignedTo,
            assignedDirectly: true,
            assignedThruGroup: false
          }
        ]);
      }

      let childRowFilterUrl = '';
      if (isInternalGroupChildRow || isInternalGroupUserChildRow) {
        childRowFilterUrl = `&assignedToGroupFilter=${encodeURIComponent(groupFilter)}`;
      } else {
        childRowFilterUrl = `&assignedToTechnicianFilter=${encodeURIComponent(userFilter)}`;
      }

      additionalParameters =
        ((isChildRow || isInternalGroupUserChildRow) &&
          `planStatus=In Progress&planStatus=On Target&planStatus=At Risk${childRowFilterUrl}`) ||
        ``;

      if (flags?.reconVendorManagement && record?.assignedToGroupType === VENDOR && record.taskTypeName) {
        additionalParameters += `&assignedToVendor=${record.taskTypeName}`;
      }

      // Make task type filter
      if (isChildRow || isInternalGroupUserChildRow) {
        taskTypeParameter = `taskType=${encodeURIComponent(record.taskType)}`;
      } else if (typeof text === 'number') {
        taskTypeParameter = `taskType=${encodeURIComponent(record.taskTypeName)}`;
      } else {
        taskTypeParameter = `taskType=${encodeURIComponent(text)}`;
      }
    }

    // General filters
    const taskProgressParameter =
      taskProgress === 'all'
        ? 'planStatus=In Progress&planStatus=On Target&planStatus=At Risk'
        : `taskProgress=${taskProgress}`;

    const hiddenStatusParam = flags.reconHiddenFilter ? `hiddenStatus=${encodeURIComponent('Include Hidden')}` : '';

    let queryStrings = [
      'vehicleStatus=Active',
      'fromDashboard=1',
      includeTaskType ? taskTypeParameter : '',
      categoriesFilterParameter,
      entitiesFilterParameter,
      taskProgressParameter,
      additionalParameters,
      hiddenStatusParam
    ]
      .filter((x) => x)
      .join('&');

    if (flags?.reconDealerFilter && isHQ && (isCategoryRow || isTotal)) {
      queryStrings += generateDealershipsLink((items ?? []).filter((item) => item.checked).map((item) => item.name));
    }

    return `/inventory?${queryStrings}`;
  };

  const createHistoricalMetricsURL = (text, record, filters, column = '', includeTaskType = true, isTotal = false) => {
    // column specifices isOverdue, isPastGoal, isAvgTotalTime
    const isChildRow = record.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW);
    const isInternalGroupChildRow = isChildRow && record.assignedToGroupId != null && record.children?.length > 0; //Internal Group Record
    const isInternalGroupUserChildRow = record.rowKey.includes(taskTypeRow.INTERNAL_GROUP_CHILD_ROW); //Internal Group Users/Members Record

    const isCategoryRow = flags?.ireconHqView && record.breakdownType === breakdownTypes.CATEGORY;
    const isDealerChildRow = flags?.ireconHqView && record.breakdownType === breakdownTypes.DEALER;
    const isTaskTypeChildRow =
      flags?.ireconHqView && record.breakdownType === breakdownTypes.TASK_TYPE && record.children?.length <= 0;

    let categoriesFilterParameter,
      entitiesFilterParameter,
      taskTypeParameter,
      assignedToParameter,
      additionalParameters,
      groupFilter,
      userFilter = '';
    if (isCategoryRow || isDealerChildRow || isTaskTypeChildRow) {
      // HQ/Category breakdown - don't add any assignee/group filters

      // Make category filter
      categoriesFilterParameter = `taskCategories=${encodeURIComponent(record.categoryName)}`;

      // Make dealership filter
      if (isDealerChildRow || isTaskTypeChildRow) {
        entitiesFilterParameter = `entitiesFilter=${encodeURIComponent(record.dealerName)}`;
      }

      // Make task type filter when task type is child row
      if (isTaskTypeChildRow) {
        taskTypeParameter = `taskType=${encodeURIComponent(text)}`;
      }
    } else {
      // Rooftop/Task Type breakdown

      assignedToParameter =
        isChildRow && !record?.assignedToGroupType && !record?.assignedToGroupId
          ? `assignedTo=${encodeURIComponent(record.taskTypeName)}`
          : '';

      // Make task type filter
      if (isChildRow || isInternalGroupUserChildRow) {
        taskTypeParameter = `taskType=${encodeURIComponent(record.taskType)}`;
      } else if (typeof text === 'number' || column === 'Avg. Total Time' || column === 'Prev. Avg. Time') {
        taskTypeParameter = `taskType=${encodeURIComponent(record.taskTypeName)}`;
      } else {
        taskTypeParameter = `taskType=${encodeURIComponent(text)}`;
      }

      // Make filter for Internal Group and Internal Group Menbers
      let isUnassigned = false;
      let members = [];
      if (isChildRow && isInternalGroupChildRow) {
        members = record.children.map((member) => member.assignedTo);
        isUnassigned = members.includes(undefined);
      } else if (isInternalGroupUserChildRow) {
        members = record.assignedTo ? [record.assignedTo] : [];
        isUnassigned = record.assignedTo === undefined;
      }
      groupFilter = JSON.stringify([
        {
          assignedToGroup: record.assignedToGroupId,
          assignedToMembers: compact(members),
          unassigned: isUnassigned
        }
      ]);

      // Make filter for Internal User
      if (isChildRow && !isInternalGroupChildRow && record.assignedTo != null) {
        userFilter = JSON.stringify([
          {
            assignedTo: record.assignedTo,
            assignedDirectly: true,
            assignedThruGroup: false
          }
        ]);
      }

      if (isInternalGroupChildRow || isInternalGroupUserChildRow) {
        additionalParameters = `&assignedToGroupFilter=${encodeURIComponent(groupFilter)}`;
      } else {
        additionalParameters = `&assignedToTechnicianFilter=${encodeURIComponent(userFilter)}`;
      }
      if ('Reassigned' === column) {
        additionalParameters += `&reassigned=1`;
      }

      if (flags?.reconVendorManagement && record?.assignedToGroupType === VENDOR && record.taskTypeName) {
        additionalParameters += `&assignedToVendor=${record.taskTypeName}`;
      }
    }

    // Make general filters
    let hasTaskProgress = '';
    if (['Overdue', 'Past Goal', 'On Track'].includes(column)) {
      hasTaskProgress = `taskProgress=${column}`;
    }

    let completionDate = 'Custom Range';
    if ([7, 30, 90].indexOf(parseInt(filters.daysAgo)) !== -1 && filters.endTime === moment().format('MM-DD-YYYY')) {
      completionDate = `Last ${filters.daysAgo} days`;
    }

    let dateParameters = null;
    if (column === 'Prev. Avg. Time') {
      const previousStartDate = moment(historicalInfo.startDateChosen, MOMENT_FORMATTING)
        .subtract(historicalInfo.daysAgo, 'days')
        .format(MOMENT_FORMATTING);
      const previousEndDate = moment(historicalInfo.endDateChosen, MOMENT_FORMATTING)
        .subtract(historicalInfo.daysAgo, 'days')
        .format(MOMENT_FORMATTING);

      completionDate = 'Custom Range';
      dateParameters = `completionDate=${completionDate}&completedOnStart=${previousStartDate}&completedOnEnd=${previousEndDate}`;
    } else {
      dateParameters = `completionDate=${completionDate}&completedOnStart=${filters.startTime}&completedOnEnd=${filters.endTime}`;
    }

    let dispositionParam = '';
    if (filters.dispositions?.length > 0) {
      const dispo = filters.dispositions.charAt(0).toUpperCase() + filters.dispositions.slice(1).toLowerCase();
      dispositionParam = `disposition=${dispo}`;
    }

    const hiddenStatusParam = flags.reconHiddenFilter ? `hiddenStatus=${encodeURIComponent('Include Hidden')}` : '';

    let queryStrings = [
      'taskStatus=Completed',
      'fromDashboard=1',
      'showDateFilter=1',
      includeTaskType ? taskTypeParameter : '',
      dateParameters,
      assignedToParameter,
      categoriesFilterParameter,
      entitiesFilterParameter,
      hasTaskProgress,
      dispositionParam,
      additionalParameters,
      hiddenStatusParam
    ]
      .filter((x) => x)
      .join('&');

    if (flags?.reconDealerFilter && isHQ && (isCategoryRow || isTotal)) {
      queryStrings += generateDealershipsLink((items ?? []).filter((item) => item.checked).map((item) => item.name));
    }
    return `/tasks?${queryStrings}`;
  };

  const getExpandedState = (record) => {
    return (record && record.expanded) ||
      (record &&
        expandedTaskTypes[record.taskTypeId || record.categoryId]?.includes(
          record.assignedToGroupId || record.dealerId
        ) &&
        (record.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW) ||
          record.rowKey.includes(taskTypeRow.CATEGORY_CHILD_ROW)))
      ? 'true'
      : 'false';
  };

  const GetParentCaretIcon = (record) => {
    return record.durationDecimal > record.previousDurationDecimal ? (
      <IncreasedIcon icon={faCaretUp} />
    ) : (
      <DecreasedIcon icon={faCaretDown} />
    );
  };

  const GetChildCaretIcon = (record) => {
    return record.averageTotalTimeDuration.durationDecimalMagnitude >
      record.previousAverageTotalTimeDuration.durationDecimalMagnitude ? (
      <IncreasedIcon icon={faCaretUp} />
    ) : (
      <DecreasedIcon icon={faCaretDown} />
    );
  };

  const createClickableDataColumns = (columns, metricsType, filters, allRowsCollapsed) => {
    const isActiveMetrics = metricsType === statisticTagTypes.ACTIVE_METRICS;
    let copyOfColumns;
    if (columns?.[0]) {
      copyOfColumns = columns.map((column, index) => ({
        ...column,
        ellipsis: true,
        width: index === 0 ? 100 : index === 5 ? 70 : 50 // These are relative to one another, not in specific units, so column 0 will be twice as wide as column 1
      }));

      if (copyOfColumns && taskBreakdownSort[metricsType]) {
        const s = taskBreakdownSort[metricsType];
        copyOfColumns.forEach((c) => {
          if (c.dataIndex === s.field) {
            c.defaultSortOrder = s.order;
          }
        });
      }

      if (isActiveMetrics) {
        copyOfColumns?.[0] &&
          (copyOfColumns[0].render = (text, record) => (
            <TruncatedTooltipOneLine
              TypographyComponentProps={{
                style: {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textAlign: 'left',
                  width: '100%',
                  display: 'inline',
                  fontSize: ({ theme }) => theme.fontSizes.xs
                }
              }} //
              lineItemFieldClassName="line-item-field"
              TooltipComponent={Tooltip}
              ToolTipComponentProps={{
                placement: 'bottom',
                overlayStyle: { fontWeight: '400' },
                align: { offset: [0, -12] },
                overlayClassName: 'statistic-tooltip'
              }}
              inTable //Slightly different way of calculating widths, to enable tooltip properly
            >
              {isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW) ? (
                <StyledTaskBreakdownForTextHq>{text}</StyledTaskBreakdownForTextHq>
              ) : (
                <StyledLink to={createActiveMetricsURL(text, record, 'all')} expanded={getExpandedState(record)}>
                  {flags?.reconVendorManagement && record?.assignedToGroupType === VENDOR ? `(V) ${text}` : text}
                </StyledLink>
              )}
            </TruncatedTooltipOneLine>
          ));
        copyOfColumns?.[1] &&
          (copyOfColumns[1].render = (text, record) =>
            isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW) ? (
              <StyledTableCell valueType="number" expanded={getExpandedState(record)}>
                {text}
              </StyledTableCell>
            ) : (
              <StyledNumberLink to={createActiveMetricsURL(text, record, 'all')} expanded={getExpandedState(record)}>
                {text}
              </StyledNumberLink>
            ));
        copyOfColumns?.[2] &&
          (copyOfColumns[2].render = (text, record) =>
            text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
              <StyledNumberLink
                to={createActiveMetricsURL(text, record, 'On Track')}
                expanded={getExpandedState(record)}
              >
                {text}
              </StyledNumberLink>
            ) : (
              <StyledTableCell valueType="number" expanded={getExpandedState(record)}>
                {text}
              </StyledTableCell>
            ));
        copyOfColumns?.[3] &&
          (copyOfColumns[3].render = (text, record) =>
            text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
              <StyledWarningLink
                to={createActiveMetricsURL(text, record, 'Past Goal')}
                value={text}
                type="past goal"
                expanded={getExpandedState(record)}
                data-all-rows-collapsed={allRowsCollapsed}
              >
                {text}
              </StyledWarningLink>
            ) : (
              <StyledOverduePastGoalTasks
                to={createActiveMetricsURL(text, record, 'Past Goal')}
                value={text}
                type="past goal"
                expanded={getExpandedState(record)}
                data-all-rows-collapsed={allRowsCollapsed}
              >
                {text}
              </StyledOverduePastGoalTasks>
            ));
        copyOfColumns?.[4] &&
          (copyOfColumns[4].render = (text, record) =>
            text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
              <StyledWarningLink
                to={createActiveMetricsURL(text, record, 'Overdue')}
                value={text}
                type="overdue"
                expanded={getExpandedState(record)}
                data-all-rows-collapsed={allRowsCollapsed}
              >
                {text}
              </StyledWarningLink>
            ) : (
              <StyledOverduePastGoalTasks
                to={createActiveMetricsURL(text, record, 'Overdue')}
                value={text}
                type="overdue"
                expanded={getExpandedState(record)}
                data-all-rows-collapsed={allRowsCollapsed}
              >
                {text}
              </StyledOverduePastGoalTasks>
            ));
        copyOfColumns?.[5] &&
          (copyOfColumns[5].render = (text, record) => (
            <StyledTableCell expanded={getExpandedState(record)} valueType="time">
              {text === '--' ? String.fromCharCode(8212) : text}
            </StyledTableCell>
          ));
      } else {
        copyOfColumns?.[0] &&
          (copyOfColumns[0].render = (text, record) => (
            <TruncatedTooltipOneLine
              TypographyComponentProps={{
                style: {
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  textAlign: 'left',
                  width: '100%',
                  display: 'inline',
                  fontSize: ({ theme }) => theme.fontSizes.xs
                }
              }}
              lineItemFieldClassName="line-item-field"
              TooltipComponent={Tooltip}
              ToolTipComponentProps={{
                placement: 'bottom',
                align: { offset: [0, -12] },
                overlayStyle: { fontWeight: '400' },
                overlayClassName: 'statistic-tooltip'
              }}
              inTable //Slightly different way of calculating widths, to enable tooltip properly
            >
              {isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW) ? (
                <StyledTaskBreakdownForTextHq>{text}</StyledTaskBreakdownForTextHq>
              ) : (
                <StyledLink to={createHistoricalMetricsURL(text, record, filters)} expanded={getExpandedState(record)}>
                  {flags?.reconVendorManagement && record?.assignedToGroupType === VENDOR ? `(V) ${text}` : text}
                </StyledLink>
              )}
            </TruncatedTooltipOneLine>
          ));
        copyOfColumns?.[1] &&
          (copyOfColumns[1].render = (text, record) =>
            text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
              <StyledNumberLink
                to={createHistoricalMetricsURL(text, record, filters)}
                expanded={getExpandedState(record)}
              >
                {text}
              </StyledNumberLink>
            ) : (
              <StyledTableCell className="completed-0" expanded={getExpandedState(record)}>
                {text}
              </StyledTableCell>
            ));
        copyOfColumns = copyColumns(filters, allRowsCollapsed, copyOfColumns);
      }
    }
    return copyOfColumns;
  };

  const copyColumns = (filters, allRowsCollapsed, copyOfColumns) => {
    let i = 2;

    copyOfColumns?.[i] &&
      (copyOfColumns[i].render = (text, record) =>
        text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
          <StyledLink
            to={createHistoricalMetricsURL(text, record, filters, 'Reassigned')}
            value={text}
            expanded={getExpandedState(record)}
          >
            {text}
          </StyledLink>
        ) : (
          <StyledTableCell expanded={getExpandedState(record)} valueType="number">
            {text}
          </StyledTableCell>
        ));
    ++i;

    copyOfColumns?.[i] &&
      (copyOfColumns[i].render = (text, record) =>
        text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
          <StyledNumberLink
            to={createHistoricalMetricsURL(text, record, filters, 'On Track')}
            expanded={getExpandedState(record)}
          >
            {text}
          </StyledNumberLink>
        ) : (
          <StyledTableCell valueType="number" expanded={getExpandedState(record)}>
            {text}
          </StyledTableCell>
        ));
    copyOfColumns?.[++i] &&
      (copyOfColumns[i].render = (text, record) =>
        text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
          <StyledWarningLink
            to={createHistoricalMetricsURL(text, record, filters, 'Past Goal')}
            value={text}
            type="past goal"
            expanded={getExpandedState(record)}
            data-all-rows-collapsed={allRowsCollapsed}
          >
            {text}
          </StyledWarningLink>
        ) : (
          <StyledOverduePastGoalTasks
            value={text}
            type="past goal"
            expanded={getExpandedState(record)}
            data-all-rows-collapsed={allRowsCollapsed}
          >
            {text}
          </StyledOverduePastGoalTasks>
        ));
    copyOfColumns?.[++i] &&
      (copyOfColumns[i].render = (text, record) =>
        text > 0 && !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
          <StyledWarningLink
            to={createHistoricalMetricsURL(text, record, filters, 'Overdue')}
            value={text}
            type="overdue"
            expanded={getExpandedState(record)}
            data-all-rows-collapsed={allRowsCollapsed}
          >
            {text}
          </StyledWarningLink>
        ) : (
          <StyledOverduePastGoalTasks
            value={text}
            type="overdue"
            expanded={getExpandedState(record)}
            data-all-rows-collapsed={allRowsCollapsed}
          >
            {text}
          </StyledOverduePastGoalTasks>
        ));
    copyOfColumns?.[++i] && (copyOfColumns[i].render = (text, record) => displayDataAvgTotaltime(text, record));
    copyOfColumns?.[++i] && (copyOfColumns[i].render = (text, record) => displayDataPrevAvgTime(text, record));
    return copyOfColumns;
  };

  const displayDataPrevAvgTime = (text, record) => {
    if (text < 0) {
      return (
        <StyledTableCell expanded={getExpandedState(record)} valueType="time">
          {text}
        </StyledTableCell>
      );
    }
    return !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
      <StyledLink
        to={createHistoricalMetricsURL(text, record, filters, 'Prev. Avg. Time')}
        style={{ display: 'inline-block' }}
        expanded={getExpandedState(record)}
      >
        {text === '--' ? String.fromCharCode(8212) : text}
      </StyledLink>
    ) : (
      <StyledTableCell expanded={getExpandedState(record)} valueType="time">
        <StyledTaskBreakdownForTextHq>{text === '--' ? String.fromCharCode(8212) : text}</StyledTaskBreakdownForTextHq>
      </StyledTableCell>
    );
  };

  const displayDataAvgTotaltime = (text, record) => {
    if (text < 0) {
      return (
        <StyledTableCell expanded={getExpandedState(record)} valueType="time">
          {text}
        </StyledTableCell>
      );
    }
    return !(isHQ && record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)) ? (
      <StyledLink
        to={createHistoricalMetricsURL(text, record, filters, 'Avg. Total Time')}
        style={{ display: 'inline-block' }}
        expanded={getExpandedState(record)}
      >
        {record.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW) ||
        record.rowKey.includes(taskTypeRow.INTERNAL_GROUP_CHILD_ROW) ||
        record.rowKey.includes(taskTypeRow.CATEGORY_CHILD_ROW) ||
        record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)
          ? GetChildCaretIcon(record)
          : GetParentCaretIcon(record)}
        {text === '--' ? String.fromCharCode(8212) : text}
      </StyledLink>
    ) : (
      <StyledTableCell expanded={getExpandedState(record)} valueType="time">
        <StyledTaskBreakdownForTextHq>
          {record.rowKey.includes(taskTypeRow.TASK_TYPE_CHILD_ROW) ||
          record.rowKey.includes(taskTypeRow.INTERNAL_GROUP_CHILD_ROW) ||
          record.rowKey.includes(taskTypeRow.CATEGORY_CHILD_ROW) ||
          record.rowKey.includes(taskTypeRow.DEALER_CHILD_ROW)
            ? GetChildCaretIcon(record)
            : GetParentCaretIcon(record)}
          {text === '--' ? String.fromCharCode(8212) : text}
        </StyledTaskBreakdownForTextHq>
      </StyledTableCell>
    );
  };

  //Create all the columns for the Total Row
  const createClickableSummaryColumns = (metricsType, summary) => {
    const isActiveMetrics = metricsType === statisticTagTypes.ACTIVE_METRICS;
    let record = {
      rowKey: [taskTypeRow.TASK_TYPE_SUMMARY_ROW]
    };
    let summaryColumns = [];

    if (isActiveMetrics) {
      let totalInProgress = summary.totalInProgress;
      let totalOnTrack = summary.totalOnTrack;
      let totalPastGoal = summary.totalPastGoal;
      let totalOverdue = summary.totalOverdue;
      let totalAverageTime = summary.totalAverageTime;

      summaryColumns.push(
        <StyledSummaryCell paddingleft="15">
          <StyledLink to={createActiveMetricsURL(totalInProgress, record, 'all', false, true)}>Total</StyledLink>
        </StyledSummaryCell>
      );

      summaryColumns.push(
        <StyledLink to={createActiveMetricsURL(totalInProgress, record, 'all', false, true)}>
          {totalInProgress}
        </StyledLink>
      );

      summaryColumns.push(
        totalOnTrack > 0 ? (
          <StyledLink to={createActiveMetricsURL(totalOnTrack, record, 'On Track', false, true)}>
            {totalOnTrack}
          </StyledLink>
        ) : (
          <StyledSummaryCell>{totalOnTrack}</StyledSummaryCell>
        )
      );

      summaryColumns.push(
        totalPastGoal > 0 ? (
          <StyledLink
            to={createActiveMetricsURL(totalPastGoal, record, 'Past Goal', false, true)}
            value={totalPastGoal}
            type="past goal"
          >
            {totalPastGoal}
          </StyledLink>
        ) : (
          <StyledSummaryCell>{totalPastGoal}</StyledSummaryCell>
        )
      );

      summaryColumns.push(
        totalOverdue > 0 ? (
          <StyledLink
            to={createActiveMetricsURL(totalOverdue, record, 'Overdue', false, true)}
            value={totalOverdue}
            type="overdue"
          >
            {totalOverdue}
          </StyledLink>
        ) : (
          <StyledSummaryCell>{totalOverdue}</StyledSummaryCell>
        )
      );
      summaryColumns.push(<StyledSummaryCell>{totalAverageTime}</StyledSummaryCell>);
    } else {
      let totalComplete = summary.totalComplete;
      let totalReassignment = summary.totalReassignment;
      let totalOnTrack = summary.totalOnTrack;
      let totalPastGoal = summary.totalPastGoal;
      let totalOutOfThreshold = summary.totalOutOfThreshold;
      let totalAverageTime = summary.totalAverageTime;
      let totalPreviousAverageTotalTime = summary.totalPreviousAverageTotalTime;

      summaryColumns.push(
        <StyledSummaryCell paddingleft="15">
          <StyledLink to={createHistoricalMetricsURL(totalComplete, record, filters, '', false, true)}>
            Total
          </StyledLink>
        </StyledSummaryCell>
      );

      summaryColumns.push(
        <StyledLink to={createHistoricalMetricsURL(totalComplete, record, filters, '', false, true)}>
          {totalComplete}
        </StyledLink>
      );

      summaryColumns.push(
        totalReassignment > 0 ? (
          <StyledLink
            to={createHistoricalMetricsURL(totalReassignment, record, filters, 'Reassigned', false, true)}
            value={totalReassignment}
          >
            {totalReassignment}
          </StyledLink>
        ) : (
          <StyledSummaryCell> {totalReassignment}</StyledSummaryCell>
        )
      );

      summaryColumns.push(
        totalOnTrack > 0 ? (
          <StyledLink to={createHistoricalMetricsURL(totalOnTrack, record, filters, 'On Track', false, true)}>
            {totalOnTrack}
          </StyledLink>
        ) : (
          <StyledSummaryCell>{totalOnTrack}</StyledSummaryCell>
        )
      );

      summaryColumns.push(
        totalPastGoal > 0 ? (
          <StyledLink to={createHistoricalMetricsURL(totalPastGoal, record, filters, 'Past Goal', false, true)}>
            {totalPastGoal}
          </StyledLink>
        ) : (
          <StyledSummaryCell>{totalPastGoal}</StyledSummaryCell>
        )
      );

      summaryColumns.push(
        totalOutOfThreshold > 0 ? (
          <StyledLink to={createHistoricalMetricsURL(totalOutOfThreshold, record, filters, 'Overdue', false, true)}>
            {totalOutOfThreshold}
          </StyledLink>
        ) : (
          <StyledSummaryCell>{totalOutOfThreshold}</StyledSummaryCell>
        )
      );

      summaryColumns.push(
        <StyledLink to={createHistoricalMetricsURL(totalAverageTime, record, filters, 'Avg. Total Time', false, true)}>
          {totalAverageTime === '--' ? String.fromCharCode(8212) : totalAverageTime}
        </StyledLink>
      );

      summaryColumns.push(
        <StyledLink
          to={createHistoricalMetricsURL(
            totalPreviousAverageTotalTime,
            record,
            filters,
            'Avg. Total Time',
            false,
            true
          )}
        >
          {totalPreviousAverageTotalTime === '--' ? String.fromCharCode(8212) : totalPreviousAverageTotalTime}
        </StyledLink>
      );
    }

    return summaryColumns;
  };

  const presentationColumns = createClickableDataColumns(
    columns,
    metricsType,
    filters,
    Object.keys(expandedTaskTypes).length === 0
  );
  const presentationData = isHQ
    ? dataSourceWithExpandableProp
    : defaultSortForActiveMetricsTable(dataSourceWithExpandableProp, taskTypes);

  if (fetchStatus === apiStatusConstants.IS_FETCHING) {
    //present skeleton loading table data
    return (
      <StyledTaskBreakdownContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
        <DataHeader reconDealerFilterFlag={flags?.reconDealerFilter}>Task Breakdown</DataHeader>
        <SkeletonTable />
      </StyledTaskBreakdownContainer>
    );
  }

  const handleOnExpand = (expanded, record) => {
    const isChild = !!record.assignedToGroupId || !!record.dealerId;
    const recordChildId = record.assignedToGroupId || record.dealerId;
    const recordTopLevelId = record.taskTypeId || record.categoryId;
    if (expanded) {
      setExpandedTaskTypes((previousTypes) => {
        let recordChildren = previousTypes[recordTopLevelId] || [];
        if (isChild) {
          recordChildren.push(recordChildId);
        } else {
          recordChildren = expandedTaskTypesMemo[recordTopLevelId] || [];
          delete expandedTaskTypesMemo[recordTopLevelId];
          setExpandedTaskTypesMemo({ ...expandedTaskTypesMemo });
        }
        return { ...previousTypes, [recordTopLevelId]: recordChildren };
      });
    } else {
      setExpandedTaskTypes((previousTypes) => {
        const recordChildren = previousTypes[recordTopLevelId] || [];
        if (isChild) {
          previousTypes[recordTopLevelId] = [...recordChildren.filter((childRecord) => childRecord !== recordChildId)];
        } else {
          setExpandedTaskTypesMemo({
            ...expandedTaskTypesMemo,
            [recordTopLevelId]: previousTypes[recordTopLevelId]
          });
          delete previousTypes[recordTopLevelId];
        }
        return { ...previousTypes };
      });
    }
  };

  if (dataSource?.length > 0) {
    return (
      <StyledTaskBreakdownContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
        <DataHeader reconDealerFilterFlag={flags?.reconDealerFilter}>Task Breakdown</DataHeader>
        <StyledTable
          columns={presentationColumns}
          dataSource={presentationData}
          rowKey="rowKey"
          pagination={false}
          showSorterTooltip={false}
          bordered={false}
          align="right"
          indentSize={2000}
          onExpand={handleOnExpand}
          rowClassName={(record) => (record.lastUserRow && getExpandedState(record) === 'false' ? 'lastUserRow' : '')}
          onChange={onChangeHandler}
          expandIcon={({ record, onExpand, expanded }) => {
            if (record.children?.length > 0 && record.children?.assignedToGroupType !== VENDOR) {
              return (
                <ExpandIcon
                  size="lg"
                  style={{
                    cursor: 'pointer',
                    marginRight: '4px',
                    transform: `rotateZ(${expanded ? '0deg' : '-90deg'})`
                  }}
                  icon={faAngleDown}
                  onClick={(eventData) => onExpand(record, eventData)}
                />
              );
            }
            return null;
          }}
          summary={(pageData) => {
            if (!summary || !flags.ireconHqView) return <></>;

            const summaryColumns = createClickableSummaryColumns(metricsType, summary);
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {summaryColumns.map((p, index) => (
                    <Table.Summary.Cell index={index}>{p}</Table.Summary.Cell>
                  ))}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </StyledTaskBreakdownContainer>
    );
  } else {
    devLogger.error('no data in dataSource array for TaskBreakdown component');
    return (
      <StyledTaskBreakdownContainer reconDealerFilterFlag={flags?.reconDealerFilter}>
        <DataHeader reconDealerFilterFlag={flags?.reconDealerFilter}>Task Breakdown</DataHeader>
        <em>No {metricsType === statisticTagTypes.ACTIVE_METRICS ? 'current' : 'historical'} task data to display</em>
      </StyledTaskBreakdownContainer>
    );
  }
};

const StyledTable = styled(Table).attrs({
  className: 'smallBorder'
})`
  .ant-table-thead {
    & > tr > th {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.tableHeaderFontColor};
    }
  }

  .ant-table-tbody > tr > td {
    padding: 4px 16px;
  }

  .ant-table-tbody {
    tr {
      line-height: 1;
    }
    tr td {
      position: relative;
    }
    tr > td:nth-child(1) {
      max-width: 400px;
    }
    > tr.ant-table-row-level-0.ant-table-row {
      border-top: ${({ theme }) => theme.borders.tableRowBorder};
    }
    > tr.ant-table-row-level-0.ant-table-row,
    > tr.ant-table-row-level-1.ant-table-row,
    > tr.ant-table-row-level-2.ant-table-row {
      &,
      &:hover > td {
        background: ${({ theme }) => theme.colors.white};
      }
      a {
        &,
        &:active,
        &:focus,
        &:hover {
          color: ${({ theme }) => theme.colors.navy};
        }
      }
    }
    > tr.ant-table-row-level-1.ant-table-row,
    > tr.ant-table-row-level-1.ant-table-row td,
    > tr.ant-table-row-level-2.ant-table-row td {
      border-bottom: none;
    }
    > tr.ant-table-row-level-1.ant-table-row td:nth-child(1) {
      padding-left: 40px;
      > a {
        padding-left: 5px;
      }
    }
    > tr.ant-table-row-level-2.ant-table-row td:nth-child(1) {
      padding-left: 66px;
    }
    tr.ant-table-row-level-2.ant-table-row td {
      pointer-events: auto;
    }
    > tr.ant-table-row-level-1.lastUserRow.ant-table-row,
    > tr.ant-table-row-level-1.lastUserRow.ant-table-row td {
      border-bottom: ${({ theme }) => theme.borders.tableRowBorder};
    }
    > tr.ant-table-row-level-2.lastUserRow.ant-table-row,
    > tr.ant-table-row-level-2.lastUserRow.ant-table-row td {
      border-bottom: ${({ theme }) => theme.borders.tableRowBorder};
    }
    .ant-table-row-indent.indent-level-1 {
      padding-left: 5px !important;
    }
    .ant-table-row-indent.indent-level-2 {
      height: auto;
    }
  }

  .ant-table-summary > tr > td {
    padding: 13px 16px !important;
    overflow-wrap: normal !important;
    font-weight: bold !important;
  }

  .ant-table-summary > tr > td a,
  .ant-table-summary > tr > td a:hover {
    font-weight: bold !important;
    color: #002950 !important;
  }
`;
//to overwrite the row hover background color effect the !important flag was necessary
//the level of specificity was also necessary to be down to the td because the styles in the ant design css were down to the td level as well

//ant design css also makes it necessary to bring in the level of specificity present for the header font weight

//additionally, adding a summary row of data to a table is only available in v4.x.x
//since we are using v3.26 currently, going to v4 has some significant changes that we may not want to introduce at this time
//https://github.com/ant-design/ant-design/compare/3.26.11...4.0.2

const ExpandIcon = styled(FontAwesomeIcon)`
  z-index: 100;
  margin-top: ${({ isHistoricalData }) => isHistoricalData !== true && '-2px'};
`;

const expandedFontWeight = ({ theme, expanded }) => expanded === 'true' && theme.fontWeights.medium;

const StyledLink = styled(Link)`
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: ${expandedFontWeight};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    text-decoration: underline;
    background-color: ${({ theme }) => theme.backgroundColors.gray};
  }
`;
const styleBackgroundColorForWarningLink = ({
  theme,
  value,
  type,
  expanded,
  'data-all-rows-collapsed': allRowsCollapsed
}) => {
  if (value > 0) {
    if (type === 'past goal') {
      return expanded === 'false' && !allRowsCollapsed ? theme.colors.lightOrangeFaded : theme.colors.lightOrange;
    }
    if (type === 'overdue') {
      return expanded === 'false' && !allRowsCollapsed ? theme.colors.lightRedFaded : theme.colors.lightRed;
    }
  }
};
const setStyles = {
  number: css`
    display: inline-block;
    padding: 14px 0;
    white-space: nowrap;
    text-align: center;
    width: 40px;
    height: 40px;
  `,
  time: css`
    display: block;
    padding: 4px 0;
  `
};
const StyledNumberLink = styled(StyledLink)`
  ${setStyles.number}
`;
const StyledWarningLink = styled(StyledLink)`
  ${setStyles.number}
  background-color: ${styleBackgroundColorForWarningLink};
  &:hover {
    background-color: ${styleBackgroundColorForWarningLink};
  }
`;
const StyledTableCell = styled.div`
  ${({ valueType }) => setStyles[valueType]}
  display: block;
  padding-left: ${({ isHistoricalData, className }) =>
    isHistoricalData === true || (className === 'completed-0' && '16px')};
  background-color: transparent;
  font-weight: ${expandedFontWeight};
`;

const StyledSummaryCell = styled.div`
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  width: 40px;
  padding-left: ${({ paddingleft }) => paddingleft + 'px'};
`;

const SkeletonContainer = styled.div`
  ${({ reconDealerFilterFlag }) => (reconDealerFilterFlag ? '' : 'padding: 24px;')}
  background-color: ${({ theme }) => theme.colors.white};
`;
const SkeletonRowContainer = styled.div`
  margin-bottom: 8px;
`;
const DataHeader = styled.div`
  color: ${({ reconDealerFilterFlag, theme }) => (reconDealerFilterFlag ? theme.colors.darkGray : theme.colors.navy)};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ reconDealerFilterFlag, theme }) => (reconDealerFilterFlag ? theme.fontSizes.md : theme.fontSizes.lg)};
  margin-bottom: 12px;
  ${({ reconDealerFilterFlag, theme }) => (reconDealerFilterFlag ? `line-height: ${theme.lineHeights.lg};` : '')}
`;
const StyledOverduePastGoalTasks = styled.div`
  background-color: ${styleBackgroundColorForWarningLink};
  border-radius: 8px;
  font-weight: ${expandedFontWeight};
  ${setStyles.number}
`;

const IncreasedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.red};
  margin-right: 15px;
  height: 16px;
  width: 16px;
`;

const DecreasedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.lime};
  margin-right: 15px;
  height: 16px;
  width: 16px;
`;

const StyledTaskBreakdownContainer = styled.div`
  ${({ reconDealerFilterFlag, theme }) =>
    reconDealerFilterFlag
      ? `background: white;
      padding: 16px;
      border-radius: 4px;
      border: ${theme.borders.mediumSolidGray};`
      : ''}
`;

const StyledTaskBreakdownForTextHq = styled.div`
  padding-left: 16px;
`;

export default withLDConsumer()(TaskBreakdown);
